import debounce from "lodash/debounce";
import {regExp} from "./defaults";
import {APICheck} from "../api";

export const checkEmail = async ({value, name, errorMsg, errors, setErrors, setCheckRequesting}) => {
    let success = false;

    if (regExp.email.test(value)) {
        try {
            setCheckRequesting(true);
            await APICheck({email: value});
            success = true;
        } catch (e) {
            setErrors({
                ...errors,
                [name]: errorMsg
            });
        } finally {
            setCheckRequesting(false);
        }
    }

    return success;
};

export const checkEmailDebounced = debounce(checkEmail, 250);