import {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from 'ckeditor5-custom-build';

import config from "../../helpers/ckeditorConfig";

const RichTextEditor = ({initialData ,currentPageStyle, onChange}) => {
    const handleChange = useCallback((eventInfo, editor) => {
        onChange(editor.getData());
    }, [onChange]);
    const {t, i18n} = useTranslation();

    return (
        <div className={`rich-text-editor ${currentPageStyle ? `rich-text-editor--${currentPageStyle}` : ''}`}>
            <CKEditor
                editor={ClassicEditor}
                config={{
                    ...config,
                    language: i18n.language,
                    placeholder: t('placeholder.richText')
                }}
                data={initialData || ''}
                onReady={editor => {
                    const popover = editor.ui.view.body._bodyCollectionContainer;
                    if (!editor.getData() && initialData)
                        editor.setData(initialData);
                    popover.remove();
                    editor.ui.view.element.appendChild(popover);
                }}
                onChange={handleChange}
                onError={error => console.log(error)}
            />
        </div>
    );
};

export default RichTextEditor;