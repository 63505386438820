import DefaultLayout from "../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import {FieldWrapper, TextareaFieldWrapper} from "../FieldWrappers";
import {useMemo, useState} from "react";
import {Form, Spinner, Button} from "react-bootstrap";
import {useForm} from "../../hooks";
import {regExp} from "../../helpers/defaults";
import {useDispatch} from "react-redux";
import {alertError, alertSuccess} from "../../redux/alert/actions";
import {APISendFeedback} from "../../api";

const Feedback = () => {
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const sendFeedback = ({message, email,name }) => {
        APISendFeedback({message, email, name})
            .then(() => {
                dispatch(alertSuccess(t('alert.sentMessage')));
                form.handleReset();
            })
            .catch(() => alertError(t('error.default')))
            .finally(() => setLoading(false));
    }

    const form = useForm({
        values: useMemo(() => {
            return {
                email: '',
                name: '',
                message: ''
            }
        }, []),
        validation: {
            email: {
                required: true,
                regExp: regExp.email,
                error: t('validation.default'),
            },
            name: {
                callback: () => !(!form.state.name),
                error: t('validation.default'),
            },
            message: {
                required: true,
                error: t('validation.default'),
            }
        },
        onSubmit: () => {
            setLoading(true)
            sendFeedback({
                message: form.state.message,
                email: form.state.email,
                name: form.state.name
            })
        },
    });


    return (
        <DefaultLayout title={t('knowledge.feedback.title')}>
            <Form noValidate className="default__form" onSubmit={form.handleSubmit}>
                <FieldWrapper
                    type="text"
                    name="name"
                    value={form.state.name}
                    label={t('label.name')}
                    placeholder={t('placeholder.name')}
                    onChange={form.handleChange}
                    validation={form.errors.name}
                    onBlur={form.handleBlur}
                />
                <FieldWrapper
                    type="email"
                    name="email"
                    value={form.state.email}
                    label={t('label.email')}
                    placeholder={t('placeholder.email')}
                    onChange={form.handleChange}
                    validation={form.errors.email}
                    onKeyUp={form.handleKeyUp}
                    onBlur={form.handleBlur}
                />
                <TextareaFieldWrapper
                    type="text"
                    name="message"
                    value={form.state.message}
                    label={t('knowledge.feedback.message')}
                    placeholder={t('placeholder.message')}
                    onChange={form.handleChange}
                    validation={form.errors.message}
                    onBlur={form.handleBlur}
                />
                <Button
                    type="submit"
                    className="primary-btn default__form__submit"
                    disabled={loading}
                >
                    {loading ?
                        <Spinner animation={"border"} size={"sm"}/>
                        :
                        t('knowledge.feedback.send')}

                </Button>
            </Form>
        </DefaultLayout>
    )
};

export default Feedback;