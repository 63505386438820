import {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {Nav, Spinner} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {logout} from "../../redux/user/actions";
import {editorSelector, requestTrackerSelector, userSelector} from "../../redux/selectors";

import {useOutsideClick, useSimpleOutsideClick} from "../../hooks";

import {getPagePublicationState} from "../../helpers/pagePublicationStateUtils";
import {prepareImageURL} from "../../helpers/imageUtils";
import {appRoutes} from "../../helpers/defaults";

import NavLink from "../../components/NavLink";
import SidebarDropdown from "../../components/SidebarDropdown";
import Loader from "../../components/Loader";
import {MenuPopup, MenuPopupItem, MenuPopupList} from "../../components/MenuPopup";
import {WizardPopup} from "../../components/Popups";
import PageStatus from "../../components/PageStatus";

import logo from "../../assets/logo.svg";
import iconHome from "./media/icon-home.svg";
import iconPages from "./media/icon-pages.svg";
import iconNewPage from "./media/icon-newPage.svg";
import iconStats from "./media/icon-stats.svg";
import iconNews from "./media/icon-news.svg";
import iconHelp from "./media/icon-help.svg"
import {sendAnalytics} from "../../Analytics";

const AppLayout = ({children, editorLayout}) => {
    const dispatch = useDispatch();
    const user = useSelector(userSelector);
    const requestTracker = useSelector(requestTrackerSelector);
    const editor = useSelector(editorSelector);
    const [isSidebarOpened, setSidebarOpened] = useState(false);
    const [isMenuOpened, setMenuOpened] = useState(false);
    const sidebarRef = useRef(null);
    const sidebarContainerRef = useRef(null);
    const dropdownContainerRef = useRef(null);
    const {t} = useTranslation();

    const handleLogout = () => {
        dispatch(logout());
    };

    const handleSidebarClose = () => {
        setSidebarOpened(false);
    };

    useOutsideClick(sidebarRef, sidebarContainerRef, handleSidebarClose);
    useSimpleOutsideClick(dropdownContainerRef, () => {
        setMenuOpened(false);
    });

    if (!user)
        return <Loader/>

    return (
        <>
            <header className="header">
                <div className="header__logo">
                    <button
                        className={isSidebarOpened ? 'header__sidebar-button header__sidebar-button--active' : 'header__sidebar-button'}
                        onClick={() => setSidebarOpened(!isSidebarOpened)}>
                        <div className="header__sidebar-button__line"/>
                    </button>
                    <Link to="/" onClick={handleSidebarClose}><img src={logo} alt="PFM Link"/></Link>
                </div>
                <div className="header__profile-wrapper" ref={dropdownContainerRef}>
                    <div className="header__profile">
                        <div className="header__profile__clickable" onClick={() => {
                            setMenuOpened(!isMenuOpened);
                        }}>
                            <span className="header__profile__name">{user.name}</span>
                            <div className="header__profile__image-wrapper">
                                <img src={prepareImageURL(user.avatar)} alt={user.name} className="header__profile__image"/>
                            </div>
                        </div>
                        <MenuPopup isOpened={isMenuOpened}>
                            <MenuPopupList>
                                <MenuPopupItem>
                                    {() => (
                                        <Link
                                            to={appRoutes.profile}
                                            className="menu-popup__list__item__button menu-popup__list__item__button--center menu-popup__list__item__button--as-link"
                                        >
                                            <span className="menu-popup__list__item__text">{t('button.profile')}</span>
                                        </Link>
                                    )}
                                </MenuPopupItem>
                                <MenuPopupItem>
                                    {() => (
                                        <button
                                            type="button"
                                            className="menu-popup__list__item__button menu-popup__list__item__button--center"
                                            disabled={requestTracker.logout}
                                            onClick={handleLogout}
                                        >
                                            <span className="menu-popup__list__item__text">
                                                {requestTracker.logout ?
                                                    <Spinner animation="border" size="sm"/>
                                                    :
                                                    t('button.logOut')
                                                }
                                            </span>
                                        </button>
                                    )}
                                </MenuPopupItem>
                            </MenuPopupList>
                        </MenuPopup>
                    </div>
                </div>
            </header>
            <div className={isSidebarOpened ? "sidebar sidebar--opened" : "sidebar"} ref={sidebarContainerRef}>
                <Nav className="sidebar__nav" ref={sidebarRef}>
                    <NavLink
                        exact
                        to="/" className="sidebar__link"
                        activeClassName="sidebar__link--active"
                        onClick={handleSidebarClose}
                    >
                        <div className="sidebar__link__icon"><img src={iconHome} alt={t('sidebar.home')}/></div>
                        <span>{t('sidebar.home')}</span>
                    </NavLink>
                    <SidebarDropdown className="sidebar__link" title={t('sidebar.pages')} icon={
                        <div className="sidebar__link__icon"><img src={iconPages} alt={t('sidebar.pages')}/></div>
                    }>
                        {requestTracker.editor.loadPages ?
                            <div style={{margin: '0 auto'}}>
                                <Spinner animation="border"/>
                            </div>
                            :
                            <>
                                {!!editor.pages.length &&
                                [...editor.pages]
                                    .sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at))
                                    .map((page, index) => {
                                        if (user.settings.wizard_page && user.settings.wizard_page === page.id )
                                            return null;
                                        return (
                                            <NavLink key={index} exact to={`${appRoutes.editor}/${page.id}`}
                                                     className="sidebar__link sidebar__link--small"
                                                     activeClassName="sidebar__link--active"
                                                     onClick={handleSidebarClose}
                                            >
                                                <div className="sidebar__link__icon">
                                                    <PageStatus status={getPagePublicationState(page)}/>
                                                </div>
                                                <span>{page.title}</span>
                                            </NavLink>
                                        );
                                    })
                                }
                                <NavLink
                                    exact
                                    to={appRoutes.createPage}
                                    className="sidebar__link sidebar__link--small"
                                    activeClassName="sidebar__link--active"
                                    onClick={() => {
                                        handleSidebarClose();
                                        sendAnalytics('create_new_page');
                                    }}
                                >
                                    <div className="sidebar__link__icon">
                                        <img src={iconNewPage} alt={t('sidebar.createNew')}/>
                                    </div>
                                    <span>{t('sidebar.createNew')}</span>
                                </NavLink>
                            </>
                        }
                    </SidebarDropdown>
                    <NavLink
                        to={appRoutes.stats}
                        className="sidebar__link"
                        activeClassName="sidebar__link--active"
                        onClick={handleSidebarClose}
                    >
                        <div className="sidebar__link__icon"><img src={iconStats} alt={t('sidebar.statistics')}/></div>
                        <span>{t('sidebar.statistics')}</span>
                    </NavLink>
                    <NavLink
                        to={appRoutes.news}
                        className="sidebar__link"
                        activeClassName="sidebar__link--active"
                        onClick={()=> {
                            handleSidebarClose();
                        }}
                    >
                        <div className="sidebar__link__icon"><img src={iconNews} alt={t('sidebar.news')}/></div>
                        <span>{t('sidebar.news')}</span>
                    </NavLink>
                    <NavLink
                        to={appRoutes.knowledge}
                        className="sidebar__link"
                        activeClassName="sidebar__link--active"
                        onClick={handleSidebarClose}
                    >
                        <div className='sidebar__link__icon'><img src={iconHelp} alt={t('title.knowledge')}/></div>
                        <span>{t('title.knowledge')}</span>
                    </NavLink>
                </Nav>
            </div>
            <div className={`main-wrapper${editorLayout ? ' main-wrapper--editor' : ''}`}>
                {children}
            </div>
            <WizardPopup/>
        </>
    );
};

export default AppLayout;