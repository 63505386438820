const CloseButton = ({className, onClick, danger}) => {
    return (
        <button type="button" className={`${danger ? "close-btn close-btn--danger" : "close-btn"} ${className}`} onClick={onClick}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 12.99L17.005 17.995L17.995 17.005L12.9899 12L17.995 6.99498L17.005 6.00504L12 11.0101L6.99495 6.00504L6.005 6.99498L11.01 12L6.005 17.005L6.99495 17.995L12 12.99Z"/>
            </svg>
        </button>
    );
};

export default CloseButton;