import {OverlayTrigger, Tooltip as BootstrapTooltip} from "react-bootstrap";

const Tooltip = ({children, id, title, placement, ...rest}) => {
    return (
        <OverlayTrigger
            placement={placement}
            overlay={<BootstrapTooltip className="pfm-tooltip" id={id}>{title}</BootstrapTooltip>}
            {...rest}
        >
            {children}
        </OverlayTrigger>
    );
};

export default Tooltip;