import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import {Provider} from "react-redux";
import {BrowserRouter as Router} from "react-router-dom";

import "./i18n";
import "moment/locale/ru";
import 'ckeditor5-custom-build/build/translations/en';

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./redux";
import "./sass/index.sass";

import setVH from "./helpers/setVH";
import {MODE, NODE_ENV} from "./helpers/const";
import {BASE_URL} from "./helpers/services";

import ErrorComponent from "./pages/ErrorComponent";
import Loader from "./components/Loader";
import {initGoogleAnalytics, initYandexAnalytics} from "./Analytics";


setUtils();
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

if(NODE_ENV !== MODE.DEV && BASE_URL !== 'https://my.pfmlink.tech'){
    initGoogleAnalytics();
    initYandexAnalytics();
};

ReactDOM.render(
    <Suspense fallback={<Loader/>}>
        <ErrorBoundary FallbackComponent={ErrorComponent}>
            <Provider store={store}>
                <Router>
                    <App/>
                </Router>
            </Provider>
        </ErrorBoundary>
    </Suspense>,
    document.getElementById('root')
);

reportWebVitals();

function setUtils() {
    setVH();
    window.getStore = NODE_ENV === MODE.DEV || BASE_URL === 'https://my.pfmlink.tech' ? () => store.getState() : undefined;
    Bugsnag.start({
        apiKey: 'eb2c9539b38858e51fa3b1a5bff3828d',
        plugins: [new BugsnagPluginReact()]
    });
}