import {Container, Row, Col} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";

import AppLayout from "../../layouts/AppLayout";
import {useSelector} from "react-redux";
import {perfluenceSelector} from "../../redux/selectors";
import { PERFLUENCE_PROFILE_URL } from "../../helpers/services";

const Perfluence = () => {
    const perfluence = useSelector(perfluenceSelector);
    const history = useHistory();
    const {t} = useTranslation();

    return (
        <AppLayout>
            <div className="page-wrapper">
                <div className="perfluence">
                    <Container className="custom-container">
                        <Row className="custom-row">
                            <Col className="custom-col">
                                <button type="button" className="perfluence__back" onClick={() => {
                                    history.goBack()
                                }}>
                                    &lt;- <span>{t('button.backConstructor')}</span>
                                </button>
                                <h2 className="pfm-subtitle">{t('title.perfluence')}</h2>
                                <Trans i18nKey="pages.perfluence.text">
                                    <p className="pfm-paragraph">
                                        Perfluence - это агентство, с помощью которого можно заработать размещая рекламу крутых брендов в своем блоге.
                                    </p>
                                    <p className="pfm-paragraph">
                                        Вы наверняка видели в своей ленте блогеров, которые рассказывают про Сбербанк или Пятерочку и предлагают скидки при указании промокодов при заказе. Они не сами договариваются с брендами, а делают это с помощью Perfluence.
                                    </p>
                                    <p className="pfm-paragraph">
                                        Для интеграции потребуется создать учетную запись.
                                    </p>
                                </Trans>
                                <p className="pfm-paragraph">
                                    Затем напишите в <a href={`${PERFLUENCE_PROFILE_URL}/tickets/create?category=pfm_link`}>Службу заботы</a> для создания этого блока.
                                </p>
                                <a
                                    href={perfluence.link || '#'}
                                    className={`primary-btn btn btn-primary${perfluence.link ? '' : ' disabled'}`}
                                    onClick={(e) => {
                                        if (!perfluence.link) {
                                            e.preventDefault();
                                        } 
                                    }}
                                >
                                    {t('button.linkPerfluence')}
                                </a>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </AppLayout>
    );
};

export default Perfluence;