import {useMemo} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Trans, useTranslation} from "react-i18next";
import {Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";

import {requestTrackerSelector} from "../../redux/selectors";
import {importPage} from "../../redux/editor/actions";

import {regExp} from "../../helpers/defaults";

import {useForm} from "../../hooks";

import AppLayout from "../../layouts/AppLayout";

import {FieldWrapper} from "../../components/FieldWrappers";
import {sendAnalytics} from "../../Analytics";

const ImportPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const requestTracker = useSelector(requestTrackerSelector);
    const {t} = useTranslation();

    const form = useForm({
        values: useMemo(() => ({
            link: ''
        }), []),
        validation: useMemo(() => ({
            link: {
                regExp: regExp.taplinkUrl,
                error: t('validation.taplink')
            }
        }), []),
        onSubmit: () => {
            dispatch(importPage(form.state.link, history));
        }
    });

    return (
        <AppLayout>
            <div className="page-wrapper">
                <div className="import">
                    <Container className="custom-container">
                        <Row className="custom-row">
                            <Col className="custom-col">
                                <h2 className="pfm-subtitle">{t('title.import')}</h2>
                                <p className="pfm-paragraph">
                                    <Trans i18nKey="pages.import.text">
                                        Введите адрес страницы для импорта.<br/>Система скопирует содержимое и создаст страницу в нашем сервисе.<br/>Может занять некоторое время.
                                    </Trans>
                                </p>
                                <Form noValidate onSubmit={form.handleSubmit}>
                                    <FieldWrapper
                                        type="url"
                                        name="link"
                                        value={form.state.link}
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                        label={t('label.pageURL')}
                                        placeholder="http://taplink.cc/"
                                        validation={form.errors.link}
                                    />
                                    <div>
                                        <Button
                                            type="submit"
                                            className="primary-btn"
                                            disabled={requestTracker.editor.createPage}
                                            onClick={()=> sendAnalytics('import')}
                                        >
                                            {requestTracker.editor.createPage ?
                                                <Spinner animation={"border"} size={"sm"}/>
                                                :
                                                t('button.import')
                                            }
                                        </Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </AppLayout>
    );
};

export default ImportPage;