import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Col, Container, Row} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {newsSelector, requestTrackerSelector} from "../../redux/selectors";
import {paginateNews} from "../../redux/news/actions";

import AppLayout from "../../layouts/AppLayout";

import NotFound from "../NotFound";

import PageLoader from "../../components/PageLoader";
import NewsList from "../../components/NewsList";
import NewsPageItem from "../../components/NewsPageItem";

const News = () => {
    const dispatch = useDispatch();
    const news = useSelector(newsSelector);
    const requestTracker = useSelector(requestTrackerSelector);
    const {newsId} = useParams();
    const [notFound, setNotFound] = useState(false);
    const {t} = useTranslation();

    if ((newsId !== undefined && isNaN(newsId)) || notFound)
        return <NotFound/>;

    if (newsId)
        return (
            <AppLayout>
                <div className="page-wrapper">
                    <div className="news">
                        <Container className="custom-container">
                            <Row className="custom-row">
                                <Col className="custom-col" sm={12} lg={6}>
                                    <NewsPageItem id={newsId} setNotFound={setNotFound} t={t}/>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </AppLayout>
        );

    return (
        <AppLayout>
            <div className="page-wrapper">
                {requestTracker.loadNews ?
                    <PageLoader/>
                    :
                    <div className="news">
                        <Container className="custom-container">
                            <Row className="custom-row">
                                <Col className="custom-col" sm={12} lg={6}>
                                    <h2 className="pfm-subtitle">{t('title.news')}</h2>
                                    <NewsList
                                        news={news}
                                        buttonText={t('button.showMore')}
                                        onButtonClick={() => {
                                            dispatch(paginateNews(news.currentPage + 1));
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                }
            </div>
        </AppLayout>
    );
};

export default News;