import {onRequestAction} from "../requestTracker/actions";
import {PAGES_CREATE, REQUESTED_PAGES_CREATE, REQUESTED_TEMPLATES, TEMPLATES_LOAD} from "../types";
import {APICreateByTemplate, APITemplates} from "../../api";
import {alertError} from "../alert/actions";
import errorHandler from "../../helpers/errorHandler";
import {setPageProperty} from "../editor/actions";
import {appRoutes} from "../../helpers/defaults";

export const loadTemplates = () => {
    return async dispatch => {
        try {
            dispatch(onRequestAction(REQUESTED_TEMPLATES, true));
            let response = await APITemplates();
            dispatch({type: TEMPLATES_LOAD, payload: response.data});
        } catch (error) {
            dispatch(alertError(errorHandler(error)));
        } finally {
            dispatch(onRequestAction(REQUESTED_TEMPLATES, false));
        }
    };
};

export const createByTemplate = ({templateId, title, history}) => {
    return async dispatch => {
        try {
            dispatch(onRequestAction(REQUESTED_PAGES_CREATE, true));
            let response = await APICreateByTemplate(templateId);
            dispatch({type: PAGES_CREATE, payload: response.data});
            await dispatch(setPageProperty({
                id: response.data.id,
                name: 'title',
                value: title
            }));
            history.push(`${appRoutes.editor}/${response.data.id}`);
        } catch (error) {
            dispatch(alertError(errorHandler(error)));
        } finally {
            dispatch(onRequestAction(REQUESTED_PAGES_CREATE, false));
        }
    }
};