import {useEffect, useRef, useState} from "react";
import {CodeJar} from "codejar";
import Prism from "prismjs";

import "prismjs/themes/prism.css";
import Validation from "../Validation";
import {FormGroup} from "react-bootstrap";

const highlight = (editor) => {
    editor.innerHTML = Prism.highlight(editor.textContent, Prism.languages.html, 'html');
};

const CodeEditor = ({data, validation, onChange}) => {
    const editorRef = useRef(null);
    const [editor, setEditor] = useState(null);

    useEffect(() => {
        if (editorRef && editorRef.current && !editor) {
            const jar = CodeJar(editorRef.current, highlight);
            jar.updateCode(data || '');
            jar.onUpdate(code => {
                onChange(code);
            });
            setEditor(jar);
        }

        return () => {
            if (editor)
                editor.destroy();
        }
    }, [editorRef]);

    return (
        <FormGroup>
            <div className="code-editor language-html" ref={editorRef}/>
            {validation &&
            <Validation>
                {validation}
            </Validation>
            }
        </FormGroup>
    );
};

export default CodeEditor;