import {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {createPage} from "../../redux/editor/actions";
import {requestTrackerSelector, userSelector} from "../../redux/selectors";

import {appRoutes, templateTypes} from "../../helpers/defaults";

import AppLayout from "../../layouts/AppLayout";

import TemplateType from "../../components/TemplateType";
import {sendAnalytics} from "../../Analytics";

const CreatePage = () => {
    const dispatch = useDispatch();
    const requestTracker = useSelector(requestTrackerSelector);
    const user = useSelector(userSelector);
    const history = useHistory();
    const {t} = useTranslation();

    const onClickCallbacks = useMemo(() => {
        return [
            () => {
                dispatch(createPage(user ? {title: user.name} : null, history));
                sendAnalytics('clear_sheet');
            },
            () => {
                history.push(appRoutes.templates);
                sendAnalytics('sample');
            },
            () => {
                history.push(appRoutes.wizard);
                sendAnalytics('wizard');
            },
            () => {
                history.push(appRoutes.import);
                sendAnalytics('taplink');
            }
        ];
    }, [dispatch, history, user]);

    return (
        <AppLayout>
            <div className="page-wrapper">
                <div className="create-page">
                    <Container className="custom-container">
                        <Row className="custom-row">
                            <Col className="custom-col">
                                <h2 className="pfm-subtitle">{t('title.createPage')}</h2>
                            </Col>
                        </Row>
                        <Row className="custom-row">
                            {
                                templateTypes.map((item, index) => (
                                    <Col className="custom-col create-page__col" sm={12} md={6} key={index}>
                                        <TemplateType
                                            t={t}
                                            name={item.name}
                                            icon={item.icon}
                                            disabled={item.disabled || requestTracker.editor.createPage}
                                            onClick={onClickCallbacks[index] || null}
                                        />
                                    </Col>
                                ))
                            }
                        </Row>
                    </Container>
                </div>
            </div>
        </AppLayout>
    );
};

export default CreatePage;