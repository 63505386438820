import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Spinner} from "react-bootstrap";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {useTranslation, Trans} from "react-i18next";

import {clearEditMode, modifiedBlock, redo, reorderBlocks, setPageProperty, undo} from "../../redux/editor/actions";
import {editorDesignSelector, requestTrackerSelector} from "../../redux/selectors";

import {useOutsideClick, useSimpleOutsideClick, useWindowSize} from "../../hooks";

import {reorder} from "../../helpers/editorUtils";
import {blockTypes, designTypes, deviceTypes,designTypesArray} from "../../helpers/defaults";
import {getPublicationStateIcon} from "../../helpers/pagePublicationStateUtils";
import {LANDING_URL} from "../../helpers/services";

import {EditorComponent} from "../EditorComponents";
import {MenuPopup, MenuPopupItem, MenuPopupList, MenuPopupTitle} from "../MenuPopup";

import iconMore from "../../assets/icon-more.svg";
import iconRename from "./media/icon-rename.svg";
import iconMobile from "./media/icon-mobile.svg";
import iconTablet from "./media/icon-tablet.svg";
import iconDesktop from "./media/icon-desktop.svg";
import iconUndo from "./media/icon-undo.svg";
import iconRedo from "./media/icon-redo.svg";

const EditorWorkspace = ({user, tab, page, device, setDevice, block, isBlockModified, setSubmitTrigger, publicationState, setPromptPopupState}) => {
    const dispatch = useDispatch();
    const designState = useSelector(editorDesignSelector).settingsUsers
    const requestTracker = useSelector(requestTrackerSelector);
    const [isDragging, setDragging] = useState(false);
    const [isMenuOpened, setMenuOpened] = useState(false);
    const [title, setTitle] = useState({
        value: '',
        isOpened: false
    });
    const {t} = useTranslation();

    const componentRef = useRef(null);
    const containerRef = useRef(null);
    const menuPopupRef = useRef(null);
    const menuPopupBtnRef = useRef(null);
    const titleRef = useRef(null);
    const pageRef = useRef(null);

    const [windowWidth] = useWindowSize();

    const onDragStart = () => {
        setDragging(true);
    };

    const onDragEnd = (result) => {
        const {destination, source} = result;

        setDragging(false);

        if (!destination) return;

        if (destination.droppableId === source.droppableId && destination.index === source.index) return;

        const newBlocks = reorder(page.editable_blocks, source.index, destination.index);
        dispatch(reorderBlocks({
            pageId: page.id,
            data: newBlocks
        }));
    };

    const handleMenuItemClick = () => {
        setMenuOpened(false);
    };

    const handleSetPageTitle = () => {
        if (title.value.trim() && page.title !== title.value) {
            dispatch(setPageProperty({
                id: page.id,
                name: 'title',
                value: title.value
            }));
        }
    };

    useEffect(() => {
        if (page)
            setTitle(prevState => (
                {
                    ...prevState,
                    value: page.title
                }
            ));
    }, [page]);

    useEffect(() => {
        if (title.isOpened)
            titleRef.current.focus();
    }, [title]);

    useEffect(() => {
        if(device === deviceTypes.desktop && windowWidth <= 1199)
            setDevice(deviceTypes.tablet);
        else if (device === deviceTypes.tablet && windowWidth <= 575)
            setDevice(deviceTypes.mobile);
    }, [windowWidth, setDevice, device]);

    useEffect(() => {
        if (pageRef && pageRef.current) {
            dispatch(modifiedBlock(pageRef.current))
            // console.log('page in EditorWorkspace',page)
        }
    }, [pageRef.current]);

    // inlineStyle
    const [styleConfig,setStyleConfig] = useState({})
    useEffect(()=>{
       if(page && page.style_config){
          let style = page.style_config.find((item,i) => {
               if(page.editable_style === item.editableTheme){
                   return {...item.configTheme}
               }
           })

           if(style){
               setStyleConfig(style.configTheme)
           } else {
               setStyleConfig({})
           }
       }

    },[page])

    useOutsideClick(componentRef, containerRef, () => {
        if (!isBlockModified && block && !Object.keys(designState.configTheme).length) {
            dispatch(clearEditMode());
            return;
        }
        setSubmitTrigger(true);
    });



    useSimpleOutsideClick(menuPopupRef, (event) => {
        if (menuPopupBtnRef.current && !menuPopupBtnRef.current.contains(event.target))
            setMenuOpened(false);
    });

    return (
        <div
            className={`editor__workspace editor__workspace--${device} editor__workspace--tab-${tab.index}`}
            ref={containerRef}
            id="editor-workspace"
        >
            <div className="editor__workspace__panel">
                <div className="editor__workspace__panel__title">
                    <div className="editor__workspace__panel__title__history-buttons">
                        <button
                            type="button"
                            className="editor__workspace__panel__settings__btn"
                            onClick={() => {
                                dispatch(undo(page.id));
                            }}
                            disabled={
                                (page && !page.sessionHistory.past.length) ||
                                requestTracker.editor.savePage
                            }
                        >
                            <img src={iconUndo} alt="Undo"/>
                        </button>
                        <button
                            type="button"
                            className="editor__workspace__panel__settings__btn"
                            onClick={() => {
                                dispatch(redo(page.id));
                            }}
                            disabled={
                                (page && !page.sessionHistory.future.length) ||
                                requestTracker.editor.savePage
                            }
                        >
                            <img src={iconRedo} alt="Redo"/>
                        </button>
                        <div className="editor__workspace__panel__title__history-buttons__divider"/>
                    </div>
                    <div className="editor__workspace__panel__title__status">
                        <img src={getPublicationStateIcon(publicationState)} alt={t('common.status')}/>
                    </div>
                    <div className="editor__workspace__panel__title__text">
                        {title.isOpened ?
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                setTitle({
                                    ...title,
                                    isOpened: false
                                });
                                handleSetPageTitle();
                            }}>
                                <input
                                    className="editor__workspace__panel__title__text__input"
                                    type="text"
                                    value={title.value}
                                    onChange={(e) => {
                                        setTitle({
                                            ...title,
                                            value: e.target.value
                                        })
                                    }}
                                    onBlur={() => {
                                        setTitle({
                                            ...title,
                                            isOpened: false
                                        })
                                        handleSetPageTitle();
                                    }}
                                    ref={titleRef}
                                />
                            </form>
                            :
                            <span>{page && page.title}</span>
                        }
                    </div>
                </div>
                <div className="editor__workspace__panel__settings">
                    <button
                        type="button"
                        className="editor__workspace__panel__settings__btn"
                        onClick={() => {
                            setDevice(deviceTypes.mobile);
                        }}
                        disabled={device === deviceTypes.mobile}
                        style={windowWidth <= 575 ? {display: 'none'} : null}
                    >
                        <img src={iconMobile} alt={t('common.mobile')}/>
                    </button>
                    <button
                        type="button"
                        className="editor__workspace__panel__settings__btn"
                        onClick={() => {
                            setDevice(deviceTypes.tablet);
                        }}
                        disabled={device === deviceTypes.tablet || windowWidth <= 575}
                        style={windowWidth <= 575 ? {display: 'none'} : null}
                    >
                        <img src={iconTablet} alt={t('common.tablet')}/>
                    </button>
                    <button
                        type="button"
                        className="editor__workspace__panel__settings__btn"
                        onClick={() => {
                            setDevice(deviceTypes.desktop);
                        }}
                        disabled={device === deviceTypes.desktop || windowWidth <= 1199}
                        style={windowWidth <= 1199 ? {display: 'none'} : null}
                    >
                        <img src={iconDesktop} alt={t('common.desktop')}/>
                    </button>
                    <button
                        type="button"
                        className="editor__workspace__panel__settings__btn"
                        onClick={() => {
                            setMenuOpened(!isMenuOpened);
                        }}
                        ref={menuPopupBtnRef}
                    >
                        <img src={iconMore} alt={t('common.settings')}/>
                    </button>
                    <MenuPopup isOpened={isMenuOpened} ref={menuPopupRef}>
                        <MenuPopupTitle>{t('common.page')}</MenuPopupTitle>
                        <MenuPopupList>
                            <MenuPopupItem>
                                {() => (
                                    <button
                                        type="button"
                                        className="menu-popup__list__item__button"
                                        onClick={() => {
                                            handleMenuItemClick();
                                            setTitle({
                                                ...title,
                                                isOpened: true
                                            })
                                        }}
                                    >
                                        <span className="menu-popup__list__item__icon">
                                            <img src={iconRename} alt={t('button.rename')}/>
                                        </span>
                                        <span className="menu-popup__list__item__text">{t('button.rename')}</span>
                                    </button>
                                )}
                            </MenuPopupItem>
                        </MenuPopupList>
                    </MenuPopup>
                </div>
            </div>
            <div className="editor__workspace__page-wrapper">
                {/*<div className={`pfm-page${page && page.editable_style ? ` pfm-page--${page.editable_style}` : ''}`} ref={pageRef}>*/}
                {/*    <div className="pfm-page__content" >*/}
                {/*        {!page ?*/}
                {/*            <div className="pfm-page__loading">*/}
                {/*                <Spinner animation="border"/>*/}
                {/*            </div>*/}
                {/*            :*/}
                {/*            !page.editable_blocks ||*/}
                {/*            !page.editable_blocks.filter(item =>*/}
                {/*                !(user && !user.settings.perfluence && item.type === blockTypes.perfluence)).length ?*/}
                {/*                <div className="editor__helper">*/}
                {/*                    {t('editor.workspace.placeholder')}*/}
                {/*                </div>*/}
                {/*                :*/}
                {/*                <>*/}
                {/*                    <DragDropContext*/}
                {/*                        onDragStart={onDragStart}*/}
                {/*                        onDragEnd={onDragEnd}*/}
                {/*                    >*/}
                {/*                        <Droppable droppableId={`droppable-${page.id}`}>*/}
                {/*                            {(provided, snapshot) => (*/}
                {/*                                <div*/}
                {/*                                    className="pfm-page__main"*/}
                {/*                                    ref={provided.innerRef}*/}
                {/*                                    {...provided.droppableProps}*/}
                {/*                                    style={{*/}
                {/*                                        ...provided.droppableProps.style,*/}
                {/*                                        backgroundColor: snapshot.isDraggingOver ?*/}
                {/*                                            page && page.editable_style === designTypes.graphite.name ? '#555' : '#eee' :*/}
                {/*                                            page && page.editable_style === designTypes.graphite.name ? 'transparent' : '#fff',*/}
                {/*                                        transition: '0.2s background-color ease-in-out'*/}
                {/*                                    }}*/}
                {/*                                >*/}
                {/*                                    {*/}
                {/*                                        page.editable_blocks.map((item, index) => (*/}
                {/*                                            <EditorComponent*/}
                {/*                                                key={index}*/}
                {/*                                                type={(block && block.index === index) ? block.type : item.type}*/}
                {/*                                                currentTabIndex={tab.index}*/}
                {/*                                                index={index}*/}
                {/*                                                data={(block && block.index === index) ? block.data : item.data}*/}
                {/*                                                pageId={page.id}*/}
                {/*                                                isDragging={isDragging}*/}
                {/*                                                clicked={block && block.index === index}*/}
                {/*                                                style={page.editable_style}*/}
                {/*                                                ref={componentRef}*/}
                {/*                                                id={`editor-component-${index}`}*/}
                {/*                                                setPromptPopupState={setPromptPopupState}*/}
                {/*                                                hasIntegration={user && user.settings.perfluence}*/}
                {/*                                            />*/}
                {/*                                        ))*/}
                {/*                                    }*/}
                {/*                                    {provided.placeholder}*/}
                {/*                                </div>*/}
                {/*                            )}*/}
                {/*                        </Droppable>*/}
                {/*                    </DragDropContext>*/}
                {/*                </>*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*    <footer className="pfm-page__footer">*/}
                {/*        <Trans i18nKey="editor.workspace.poweredBy">*/}
                {/*            Работает на <a href={LANDING_URL} onClick={e => {e.preventDefault()}}>PFM Link</a>*/}
                {/*        </Trans>*/}
                {/*    </footer>*/}
                {/*</div>*/}
                {!page ?
                    <div className={`pfm-page${page && page.editable_style ? ` pfm-page--${page.editable_style}` : ''}`}>
                        <div className="pfm-page__loading">
                            <Spinner animation="border"/>
                        </div>
                    </div>
                    :
                    designTypesArray.map((design, i) => {
                       return  (page && design.name === page.editable_style &&
                           <div className={`pfm-page${page && page.editable_style ? ` pfm-page--${page.editable_style}` : ''}`} ref={pageRef} key={i}
                                style={styleConfig}
                           >
                               <div className="pfm-page__content">
                                   {!page ?
                                       <div className="pfm-page__loading">
                                           <Spinner animation="border"/>
                                       </div>
                                       :
                                       !page.editable_blocks ||
                                       !page.editable_blocks.filter(item =>
                                           !(user && !user.settings.perfluence && item.type === blockTypes.perfluence)).length ?
                                           <div className="editor__helper">
                                               {t('editor.workspace.placeholder')}
                                           </div>
                                           :
                                           <>
                                               <DragDropContext
                                                   onDragStart={onDragStart}
                                                   onDragEnd={onDragEnd}
                                               >
                                                   <Droppable droppableId={`droppable-${page.id}`}>
                                                       {(provided, snapshot) => (
                                                           <div
                                                               className="pfm-page__main"
                                                               ref={provided.innerRef}
                                                               {...provided.droppableProps}
                                                               style={{
                                                                   ...provided.droppableProps.style,
                                                                   background: snapshot.isDraggingOver && 'var(--page-bg-color)',
                                                                   backgroundColor: snapshot.isDraggingOver ?
                                                                       page && page.editable_style === designTypes.graphite.name ? '#555' : '#eee' :
                                                                       'transparent',
                                                                   // transition: '0.2s background-color ease-in-out',
                                                                   // backgroundColor: snapshot.isDraggingOver ? '#fff' : 'transparent',

                                                               }}
                                                           >
                                                               {
                                                                   page.editable_blocks.map((item, index) => (
                                                                       <EditorComponent
                                                                           key={index}
                                                                           type={(block && block.index === index) ? block.type : item.type}
                                                                           currentTabIndex={tab.index}
                                                                           index={index}
                                                                           data={(block && block.index === index) ? block.data : item.data}
                                                                           pageId={page.id}
                                                                           isDragging={isDragging}
                                                                           clicked={block && block.index === index}
                                                                           style={page.editable_style}
                                                                           ref={componentRef}
                                                                           id={`editor-component-${index}`}
                                                                           setPromptPopupState={setPromptPopupState}
                                                                           hasIntegration={user && user.settings.perfluence}
                                                                       />
                                                                   ))
                                                               }
                                                               {provided.placeholder}
                                                           </div>

                                                       )}
                                                   </Droppable>
                                               </DragDropContext>
                                           </>
                                   }
                               </div>
                               <footer className="pfm-page__footer">
                                   <Trans i18nKey="editor.workspace.poweredBy">
                                       Работает на PFM Link
                                   </Trans>
                               </footer>
                           </div>
                       )
                })}
            </div>

        </div>
    );
};

export default EditorWorkspace;
