import {APIGetPerfluenceLink} from "../../api";
import {PERFLUENCE_SET_LINK} from "../types";
import {alertError} from "../alert/actions";
import errorHandler from "../../helpers/errorHandler";
import i18n from "../../i18n";

export const getPerfluenceLink = () => {
    return async dispatch => {
        try {
            const response = await APIGetPerfluenceLink();
            dispatch({type: PERFLUENCE_SET_LINK, payload: response.data.link});
        } catch (error) {
            dispatch(alertError(errorHandler(error, {
                "500": () => i18n.t('error.perfluence')
            })));
        }
    };
};