import {onRequestAction} from "../requestTracker/actions";
import {NEWS_LOAD, NEWS_PAGINATE, REQUESTED_NEWS_LOAD, REQUESTED_NEWS_PAGINATE} from "../types";
import {APIGetNews} from "../../api";
import {alertError} from "../alert/actions";
import errorHandler from "../../helpers/errorHandler";

export const loadNews = () => {
    return async dispatch => {
        try {
            dispatch(onRequestAction(REQUESTED_NEWS_LOAD, true));
            const response = await APIGetNews();
            dispatch({
                type: NEWS_LOAD,
                payload: {
                    list: response.data.data,
                    total: response.data.total,
                    currentPage: response.data.current_page
                }
            });
        } catch (error) {
            dispatch(alertError(errorHandler(error)));
        } finally {
            dispatch(onRequestAction(REQUESTED_NEWS_LOAD, false));
        }
    };
};

export const paginateNews = (page) => {
    return async dispatch => {
        try {
            dispatch(onRequestAction(REQUESTED_NEWS_PAGINATE, true));
            const response = await APIGetNews(page);
            dispatch({type: NEWS_PAGINATE, payload: response.data.data});
        } catch (error) {
            dispatch(alertError(errorHandler(error)));
        } finally {
            dispatch(onRequestAction(REQUESTED_NEWS_PAGINATE, false));
        }
    };
};