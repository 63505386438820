import {forwardRef, useEffect, useMemo, useRef, useState} from "react";
import {Button, Form, FormCheck, FormControl, FormGroup, FormLabel} from "react-bootstrap";
import SwiperCore, {Mousewheel, Navigation, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import Datetime from "react-datetime";
import Select from "react-select";
import moment from "moment";
import {Trans, useTranslation} from "react-i18next";

import {getBlockConfigs, defaultImagePaths} from "../../helpers/defaults";
import {designTypesArray} from "../../helpers/defaults";
import {prepareImageURL} from "../../helpers/imageUtils";

import Validation from "../Validation";
import FileList from "../FileList";
import {CommercialComponent, PageComponent} from "../EditorComponents";
import CloseButton from "../CloseButton";

import "react-datetime/css/react-datetime.css";
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

import iconShown from "../../assets/icon-shown.svg";
import iconHidden from "../../assets/icon-hidden.svg";
import sliderArrow from "../../assets/slider-arrow.svg";
import iconTrash from "../../assets/icon-trash.svg";
import {ReactComponent as SelectDropdownIcon} from "../../assets/icon-dropdown.svg";
import {changedFonts, getFonts, getNumVariable} from "../EditorDesignForm/helpers";
import {useDispatch} from "react-redux";
import {userSettings} from "../../redux/editorDesign/actions";

SwiperCore.use([Navigation, Pagination, Mousewheel]);

export const FieldFontSizeWrapper = ({page, variable,type, name, label, placeholder, desc,onChange, onBlur, onKeyUp, hidden,limiter}) => {
    const dispatch = useDispatch();
    const [size, setSize] = useState(limiter.min);
    const {t} = useTranslation();

    const validation = size < limiter.min ? `${t('validation.minNum')} ${limiter.min}`
        : size > limiter.max && `${t('validation.maxNum')} ${limiter.max}`;

    useEffect(() => {
        if(page)
            setSize(getNumVariable(page,variable))
    },[page,variable]);

    if (hidden)
        return null;

    return (
        <FormGroup controlId={name}>
            {label &&
            <FormLabel className="label">{label}</FormLabel>
            }
            <FormControl
                className="field"
                type={type}
                name={name}
                value={size}
                placeholder={placeholder}
                isInvalid={validation}
                onChange={(e) => {
                    setSize(e.target.value)
                    onChange(page, variable, e.target.value, limiter)
                    dispatch(userSettings(variable, `${e.target.value}px`))
                }}
                onBlur={onBlur}
                onKeyUp={onKeyUp}
            />
            {validation &&
            <Validation>
                {validation}
            </Validation>
            }
            {desc &&
            <span className="field__desc">{desc}</span>
            }
        </FormGroup>
    );
};

export const SelectFontWrapper = ({page,variable ,name, label, options, className, hidden}) => {
    const {t} = useTranslation();
    const [font, setFont] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        if(page)
            setFont(getFonts(page,variable) || getFonts(page,'--font-family'))
    },[page,variable])

    const currentValue = useMemo(() => {
        if (options) {
            const option = options.find(item => item.value === font);
            return {
                value: option ? option.value : '',
                label: option ? (option.text || option.label || t(option.tKey)) : ''
            }
        }
        return {font, label: ''};
    }, [font, options]);

    const preparedOptions = useMemo(() => {
        if (options)
            return options.map(item => {
                return {
                    ...item,
                    label: item.text || item.label || t(item.tKey)
                };
            });

        return [];
    }, [options]);

    if (hidden)
        return null;

    return (
        <FormGroup controlId={name} className={className}>
            <FormLabel className="label">{label}</FormLabel>
            <div className="field-wrapper">
                <Select
                    value={currentValue}
                    options={preparedOptions}
                    onChange={(e) => {
                        setFont(e.value)
                        changedFonts(page, variable, e.text)
                        dispatch(userSettings(variable, e.value))
                    }}
                    placeholder={t('placeholder.select')}
                    noOptionsMessage={() => t('placeholder.selectNoOptions')}
                    className="select"
                    classNamePrefix="select"
                    isSearchable={false}
                    components={{
                        DropdownIndicator: CustomDropdownIndicator
                    }}
                />
            </div>
        </FormGroup>
    );
};

export const FieldWrapper = forwardRef(({type, name, value, label, placeholder, desc, onChange, onBlur, onKeyUp, validation, hidden}, ref) => {
    if (hidden)
        return null;

    return (
        <FormGroup controlId={name}>
            {label &&
            <FormLabel className="label">{label}</FormLabel>
            }
            <FormControl
                className="field"
                type={type}
                name={name}
                value={value}
                placeholder={placeholder}
                isInvalid={validation}
                onChange={onChange}
                onBlur={onBlur}
                onKeyUp={onKeyUp}
                ref={ref}
            />
            {validation &&
            <Validation>
                {validation}
            </Validation>
            }
            {desc &&
            <span className="field__desc">{desc}</span>
            }
        </FormGroup>
    );
});

const CustomDropdownIndicator = ({innerProps}) => {
    return (
        <div {...innerProps} className="select__indicator select__dropdown-indicator">
            <SelectDropdownIcon/>
        </div>
    );
};

export const SelectFieldWrapper = ({name, label, value, onChange, options, className, onRemove, hidden}) => {
    const {t} = useTranslation();
    const currentValue = useMemo(() => {
        if (options) {
            const option = options.find(item => item.value === value);
            return {
                value: option ? option.value : '',
                label: option ? (option.text || option.label || t(option.tKey)) : ''
            }
        }
        return {value, label: ''};
    }, [value, options]);

    const preparedOptions = useMemo(() => {
        if (options)
            return options.map(item => {
                return {
                    ...item,
                    label: item.text || item.label || t(item.tKey)
                };
            });

        return [];
    }, [options]);

    if (hidden)
        return null;

    return (
        <FormGroup controlId={name} className={className}>
            <FormLabel className="label">{label}</FormLabel>
            <div className="field-wrapper">
                <Select
                    value={currentValue}
                    options={preparedOptions}
                    onChange={onChange}
                    placeholder={t('placeholder.select')}
                    noOptionsMessage={() => t('placeholder.selectNoOptions')}
                    className="select"
                    classNamePrefix="select"
                    isSearchable={false}
                    components={{
                        DropdownIndicator: CustomDropdownIndicator
                    }}
                />
                {onRemove &&
                <Button
                    type="button"
                    variant="light"
                    className="light-btn"
                    size="sm"
                    onClick={onRemove}
                >
                    <img src={iconTrash} alt={t('button.delete')}/>
                </Button>
                }
            </div>
        </FormGroup>
    );
};

export const TextareaFieldWrapper = forwardRef(({name, value, label, onChange, onBlur, validation, placeholder}, ref) => {
    return (
        <FormGroup controlId={name}>
            <FormLabel className="label">{label}</FormLabel>
            <FormControl
                className="field"
                as="textarea"
                name={name}
                value={value}
                placeholder={placeholder}
                isInvalid={validation}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
            />
            {validation &&
            <Validation>
                {validation}
            </Validation>
            }
        </FormGroup>
    );
});

export const FileFieldWrapper = forwardRef(({name, label, children, onChange, style, desc, accept, file, preview, onFileDelete}, ref) => {
    const inputRef = useRef(null);
    const fileStr = useMemo(() => prepareImageURL(file), [file]);

    return (
        <Form.Group controlId={name} style={style || null}>
            {label &&
            <Form.Label className="label label--file">{label}</Form.Label>
            }
            {children}
            <Form.Control
                type="file"
                name={name}
                className="field field--file"
                value=""
                onChange={onChange}
                ref={ref || inputRef}
                accept={accept}
            />
            {desc && (!file || defaultImagePaths.set.has(file)) &&
            <span className="field__desc">{desc}</span>
            }
            {preview && file && !defaultImagePaths.set.has(file) &&
            <div className={preview === 'avatar' ? 'field__preview field__preview--avatar' : 'field__preview'}>
                <img
                    className="field__preview__image"
                    src={fileStr}
                    alt={file instanceof File ? file.name : file}
                />
                <CloseButton
                    className="field__preview__delete"
                    danger={true}
                    onClick={onFileDelete}
                />
            </div>
            }
        </Form.Group>
    );
});

export const MultipleFileFieldWrapper = forwardRef(({name, label, children, onChange, desc, files, onFileDelete, onFileSwap, accept, loading}, ref) => {
    const inputRef = useRef(null);

    return (
        <Form.Group controlId={name}>
            {label &&
            <Form.Label className="label label--file">{label}</Form.Label>
            }
            <FileList
                files={files}
                onFileSwap={onFileSwap}
                onDelete={onFileDelete}
            >
                {fileListProps => (
                    <Form.Label
                        className="load-button load-button--multiple"
                        style={{
                            transform: `translate(${fileListProps.transformX}px, ${fileListProps.transformY}px)`
                        }}
                    >
                        <div>
                            <span>+</span>
                        </div>
                    </Form.Label>
                )}
            </FileList>
            <Form.Control
                type="file"
                name={name}
                className="field field--file"
                value=""
                onChange={onChange}
                ref={ref || inputRef}
                multiple={true}
                accept={accept}
            />
            {desc &&
            <span className="field__desc">{desc}</span>
            }
        </Form.Group>
    );
});

export const PasswordFieldWrapper = forwardRef(({name, value, label, onChange, onBlur, validation, className, style}, ref) => {
    const [isShown, setShown] = useState(false);
    const {t} = useTranslation();

    return (
        <FormGroup controlId={name} className={className} style={style}>
            <FormLabel className="label">{label}</FormLabel>
            <div className="form__password-group">
                <FormControl
                    className="field"
                    type={isShown ? 'text' : 'password'}
                    name={name}
                    value={value}
                    isInvalid={validation}
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={ref}
                />
                <button type="button" className="form__toggle-password" style={{
                    display: value ? 'flex' : 'none'
                }} onClick={() => {
                    setShown(!isShown)
                }}>
                    <img src={isShown ? iconShown : iconHidden} alt={t('common.showHidePassword')}/>
                </button>
            </div>
            {validation &&
            <Validation>
                {validation}
            </Validation>
            }
        </FormGroup>
    );
});

export const SwitcherTabsWrapper = ({name, label, options, onChange, value}) => {
    return (
        <FormGroup controlId={name}>
            <FormLabel className="label">{label}</FormLabel>
            <div className="radio-tabs">
                {options &&
                options.map((item) => (
                    <button
                        className={`radio-tabs__button${value === item.value ? ' radio-tabs__button--chosen' : ''}`}
                        value={item.value}
                        onClick={() => {onChange(item.value)}}
                        disabled={value === item.value}
                        style={{flexBasis: `${100 / options.length}%`}}
                        type="button"
                        key={item.value}
                    >
                        {item.text}
                    </button>
                ))
                }
            </div>
        </FormGroup>
    );
};

export const RadioFieldsWrapper = ({name, label, options, onChange, value}) => {

    return (
        <FormGroup controlId={name}>
            <FormLabel className="label">{label}</FormLabel>
            <div className="divider-radios">
                {options &&
                options.map((item, index) => (
                    <div className="divider-radios__group" key={index}>
                        <FormCheck.Input
                            className={`divider-radios__input${value === item.value? ' divider-radios__input--chosen' : ''}`}
                            name={name}
                            type="radio"
                            value={item.value}
                            id={`radio-${name}-${index}`}
                            onChange={onChange}
                        />
                        <FormCheck.Label htmlFor={`radio-${name}-${index}`} className="divider-radios__label">
                            {item.text || item.label}
                        </FormCheck.Label>
                    </div>
                ))
                }
            </div>
        </FormGroup>
    );
};

export const DatetimePickerFieldWrapper = ({name, label, value, onChange}) => {
    const {i18n} = useTranslation();

    return (
        <FormGroup controlId={name}>
            <FormLabel className="label">{label}</FormLabel>
            <Datetime
                input={false}
                locale={i18n.language}
                value={value ? moment(value) : moment()}
                onChange={value => {onChange(value)}}
                isValidDate={current => current.isAfter(moment().subtract(1, 'day'))}
            />
        </FormGroup>
    );
};

const dateIntervalConfig = {
    timeFormat: false,
    inputProps: {
        readOnly: true,
        className: 'form-control field'
    },
    className: "datetime-picker",
    isValidDate: (current) => {
        return current.isBefore(moment());
    }
};

export const DateIntervalFieldWrapper = ({name, valueFrom, valueTo, onChangeFrom, onChangeTo}) => {
    const {t, i18n} = useTranslation();

    return (
        <FormGroup controlId={name} className="form-group--stats">
            <FormLabel className="label">{t('common.from')}</FormLabel>
            <Datetime

                value={valueFrom ? moment(valueFrom) : moment()}
                onChange={value => {onChangeFrom(value)}}
                {...dateIntervalConfig}
                locale={i18n.language}
            />
            <span className="form-group__hyphen-divider">-</span>
            <FormLabel className="label">{t('common.to')}</FormLabel>
            <Datetime
                value={valueTo ? moment(valueTo) : moment()}
                onChange={value => {onChangeTo(value)}}
                {...dateIntervalConfig}
                locale={i18n.language}
                className="datetime-picker datetime-picker--right"
            />
        </FormGroup>
    );
};

export const SwitcherFieldWrapper = forwardRef(({name, label, section, value, onChange}, ref) => {
    return (
        <FormGroup controlId={name} className={`form-group--switcher${section ? ' form-group--section-switcher' : ''}`}>
            <FormLabel className={`label${section ? ' label--section' : ''}`}>{label}</FormLabel>
            <FormCheck
                type="switch"
                id={name}
                checked={value ? value : false}
                onChange={() => {onChange(!value)}}
            />
        </FormGroup>
    );
});

export const CheckboxFieldWrapper = ({name, label, value, onChange}) => {
    return (
        <FormGroup controlId={name}>
            <FormCheck
                type="checkbox"
                className="field--checkbox"
                id={name}
                label={label}
                checked={value ? value : false}
                onChange={() => {onChange(!value)}}
            />
        </FormGroup>
    );
};

export const BlockSwitcher = ({value, blocks, pageStyle, options: optionsProp, onChange}) => {

    const options = useMemo(() => {
        return optionsProp.filter(option => option.value !== 'perfluence');
    }, [optionsProp]);

    const initial = useMemo(() => {
        return blocks.reduce((a, item) => {
            a.push({
                ...item,
                data: {
                    ...item.data
                }
            });
            return a;
        }, []);
    }, []);
    const {t} = useTranslation();
    const blockConfigs = useMemo(() => getBlockConfigs(), []);

    const currentIndex = useMemo(() => options.indexOf(options.find(item => item.value === value)), [options, value])

    return (
        <div className="block-switcher">
            <div className="block-switcher__options-count">
                {`${currentIndex + 1}/${options.length}`}
            </div>
            <Swiper
                slidesPerView="auto"
                spaceBetween={16}
                centeredSlides={true}
                observer={true}
                observeParents={true}
                onSwiper={swiper => {
                    swiper.slideTo(currentIndex, 500, false);
                }}
                onSlideChange={swiper => {
                    onChange(swiper.activeIndex, initial);
                }}
                navigation={{
                    nextEl: '.slider__arrow--next',
                    prevEl: '.slider__arrow--prev',
                    disabledClass: 'slider__arrow--disabled'
                }}
                mousewheel={{
                    forceToAxis: true
                }}
            >
                <div className="slider__arrow slider__arrow--prev">
                    <img src={sliderArrow} alt={t('common.prev')}/>
                </div>
                <div className="slider__arrow slider__arrow--next">
                    <img src={sliderArrow} alt={t('common.next')}/>
                </div>
                {
                    options.map((item, index) => (
                        <SwiperSlide key={index}>
                            <div
                                className={`block-switcher__option${pageStyle ? ` block-switcher__option--${pageStyle}` : ''}`}>
                                <CommercialComponent blocks={blockConfigs.commercial.data[item.value].blocks}/>
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    );
};

const PageSwitcherLayout = ({onChange, disabled, children}) => {
    const {t} = useTranslation();

    return (
        <div className="page-switcher">
            <Swiper
                className="page-switcher__slider"
                slidesPerView={1}
                observer={true}
                observeParents={true}
                navigation={{
                    nextEl: '.slider__arrow--next',
                    prevEl: '.slider__arrow--prev',
                    disabledClass: 'slider__arrow--disabled'
                }}
                pagination={{
                    clickable: true,
                    bulletClass: "slider__pagination__bullet",
                    bulletActiveClass: "slider__pagination__bullet--active"
                }}
                onSlideChange={swiper => {
                    onChange(swiper.activeIndex);
                }}
                autoHeight={true}
                disabled={disabled}
                mousewheel={{
                    forceToAxis: true
                }}
            >
                <div className="slider__arrow slider__arrow--prev">
                    <img src={sliderArrow} alt={t('common.prev')}/>
                </div>
                <div className="slider__arrow slider__arrow--next">
                    <img src={sliderArrow} alt={t('common.next')}/>
                </div>
                {children}
            </Swiper>
        </div>
    );
};

export const PageSwitcher = ({page, onChange}) => {
    return (
        <PageSwitcherLayout onChange={onChange}>
            {
                designTypesArray.map(item => (
                    <SwiperSlide key={item.name}>
                        <div className="page-switcher__wrapper">
                            <div className="page-switcher__page-wrapper">
                                <div className={`pfm-page pfm-page--${item.name}`}>
                                    <div className="pfm-page__content">
                                        <div className="pfm-page__main">
                                            {page &&
                                            page.blocks.map((block, index) => (
                                                <PageComponent
                                                    key={item.name + index}
                                                    type={block.type}
                                                    data={block.data}
                                                    clicked={true}
                                                    style={item.name}
                                                />
                                            ))
                                            }
                                        </div>
                                    </div>
                                    <footer className="pfm-page__footer">
                                        <Trans i18nKey="editor.workspace.poweredBy">
                                            Работает на PFM Link
                                        </Trans>
                                    </footer>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))
            }
        </PageSwitcherLayout>
    )
};

export const PageTemplatesSwitcher = ({templates, disabled, onChange}) => {
    return (
        <PageSwitcherLayout onChange={onChange} disabled={disabled}>
            {templates && templates.length &&
            templates.map(item => (
                <SwiperSlide key={item.id}>
                    <div className="page-switcher__wrapper">
                        <div className="page-switcher__page-wrapper">
                            <div className={`pfm-page pfm-page--${item.style}`}>
                                <div className="pfm-page__content">
                                    <div className="pfm-page__main">
                                        {
                                            item.blocks.map((block, index) => (
                                                <PageComponent
                                                    key={index}
                                                    type={block.type}
                                                    data={block.data}
                                                    clicked={true}
                                                    style={item}
                                                />
                                            ))
                                        }
                                    </div>
                                </div>
                                <footer className="pfm-page__footer">
                                    <Trans i18nKey="editor.workspace.poweredBy">
                                        Работает на PFM Link
                                    </Trans>
                                </footer>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            ))
            }
        </PageSwitcherLayout>
    );
};
