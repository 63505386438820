import {clearCheckPagePath, onRequestAction} from "../requestTracker/actions";
import {
    APIBlockTypes,
    APICheckPagePath,
    APICreatePage,
    APIDeletePage,
    APIImportPage,
    APILoadPages,
    APIPublishPage,
    APISavePage,
    APIUpload
} from "../../api";
import {
    BLOCK_TYPES,
    BLOCKS_EDIT,
    BLOCKS_SET,
    EDIT_MODE__CLEAR,
    EDITOR_UPDATE,
    PAGES_CLEAR_HISTORY,
    PAGES_CREATE,
    PAGES_DELETE,
    PAGES_LOAD,
    PAGES_PUSH_HISTORY,
    PAGES_REDO,
    PAGES_SET_PROP,
    PAGES_SET_PROPS,
    PAGES_UNDO,
    PAGES_UPDATE,
    RECEIVED_PAGE_CHECK,
    REQUESTED_PAGE_CHECK,
    REQUESTED_PAGE_PUBLISH,
    REQUESTED_PAGES_CREATE,
    REQUESTED_PAGES_DELETE,
    REQUESTED_PAGES_LOAD,
    REQUESTED_PAGES_SAVE,
    REQUESTED_UPLOAD
} from "../types";
import {alertError, alertSuccess, alertWarning} from "../alert/actions";
import i18n, {defaultErrorMessages} from "../../i18n";
import errorHandler, {getErrors} from "../../helpers/errorHandler";
import {showWizardPopup} from "../wizard/actions";
import {appRoutes, defaultImagePaths, getDefaultImageByPropName} from "../../helpers/defaults";
import {AVATAR_SIZES} from "../../helpers/const";

export const modifiedBlock = (link) => {
    return dispatch => {
        dispatch({type: "MOD_STYLE", payload: link})
    }
}

export const loadBlockTypes = () => {
    return async dispatch => {
        try {
            let response = await APIBlockTypes();
            dispatch({type: BLOCK_TYPES, payload: response.data})
        } catch (error) {
            dispatch(alertError(errorHandler(error)));
        }
    };
};

export const loadPages = () => {
    return async dispatch => {
        try {
            dispatch(onRequestAction(REQUESTED_PAGES_LOAD, true));
            let response = await APILoadPages();
            dispatch({type: PAGES_LOAD, payload: response.data})
            if (!response.data.length)
                dispatch(showWizardPopup());
        } catch (error) {
            dispatch(alertError(errorHandler(error)));
        } finally {
            dispatch(onRequestAction(REQUESTED_PAGES_LOAD, false));
        }
    }
};

export const createPage = (settings, history) => {
    return async dispatch => {
        try {
            dispatch(onRequestAction(REQUESTED_PAGES_CREATE, true));
            let response = await APICreatePage(settings);
            dispatch({type: PAGES_CREATE, payload: response.data});
            history.push(`${appRoutes.editor}/${response.data.id}`);
        } catch (error) {
            dispatch(alertError(errorHandler(error, {
                "422": () => i18n.t('error.pageParams')
            })));
        } finally {
            dispatch(onRequestAction(REQUESTED_PAGES_CREATE, false));
        }
    };
};

export const savePage = ({id, settings}, successMessage) => {
    return async dispatch => {
        try {
            dispatch(onRequestAction(REQUESTED_PAGES_SAVE, true));
            const response = await APISavePage(id, settings);
            dispatch({type: PAGES_UPDATE, payload: response.data});
            if (successMessage)
                dispatch(alertSuccess(successMessage));
        } catch (error) {
            dispatch(alertError(errorHandler(error, {
                "422": (error) => [defaultErrorMessages["422"], ...getErrors(error)].join('<br/>')
            })));
        } finally {
            dispatch(onRequestAction(REQUESTED_PAGES_SAVE, false));
        }
    };
};

export const undoPageChanges = (id) => {
    return async (dispatch, getState) => {

        const page = getState().editor.pages.find(item => item.id === id);
        await dispatch(savePage({
            id,
            settings: {
                blocks: page.blocks,
                style: page.style
            }
        }, () => i18n.t('alert.canceled')));

        dispatch({
            type: PAGES_CLEAR_HISTORY,
            payload: id
        });
    };
};

export const deletePage = (id) => {
    return async dispatch => {
        try {
            dispatch(onRequestAction(REQUESTED_PAGES_DELETE, true));
            await APIDeletePage(id);
            dispatch({type: PAGES_DELETE, payload: id});
        } catch (error) {
            dispatch(alertError(errorHandler(error)));
        } finally {
            dispatch(onRequestAction(REQUESTED_PAGES_DELETE, false));
        }
    };
};

export const copyPage = (id, history) => {
    return async (dispatch, getState) => {
        try {
            dispatch(onRequestAction(REQUESTED_PAGES_CREATE, true));
            const page = getState().editor.pages.find(item => item.id === id);
            const response = await APICreatePage({
                title: `${page.title}*`,
                style: page.editable_style,
                blocks: page.editable_blocks
            });
            dispatch({type: PAGES_CREATE, payload: response.data});
            history.push(`${appRoutes.editor}/${response.data.id}`);
        } catch (error) {
            dispatch(alertError(errorHandler(error)));
        } finally {
            dispatch(onRequestAction(REQUESTED_PAGES_CREATE, false));
        }
    };
};

export const savePageStyle = ({id, style}) => {
    return async dispatch => {
        try {
            dispatch(onRequestAction(REQUESTED_PAGES_SAVE, true));
            await APISavePage(id, {
                style
            });
        } catch (error) {
            dispatch(alertError(errorHandler(error)));
        } finally {
            dispatch(onRequestAction(REQUESTED_PAGES_SAVE, false));
        }
    };
};

export const publishPage = (id) => {
    return async dispatch => {
        try {
            dispatch({
                type: PAGES_CLEAR_HISTORY,
                payload: id
            });
            dispatch(onRequestAction(REQUESTED_PAGE_PUBLISH, true));
            let response = await APIPublishPage(id);
            dispatch({type: PAGES_UPDATE, payload: response.data});
            dispatch(alertSuccess(() => i18n.t('alert.published')));
            dispatch(clearCheckPagePath());
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    dispatch(alertWarning(() => i18n.t('alert.same')));
                } else {
                    dispatch(alertError(errorHandler(error)));
                }
            }
        } finally {
            dispatch(onRequestAction(REQUESTED_PAGE_PUBLISH, false));
        }
    };
};

export const checkPagePath = ({id, path}) => {
    return async dispatch => {
        try {
            dispatch(onRequestAction(REQUESTED_PAGE_CHECK, true));
            await APICheckPagePath(id, path);
            dispatch(onRequestAction(RECEIVED_PAGE_CHECK, false));
        } catch (error) {
            dispatch(onRequestAction(RECEIVED_PAGE_CHECK, true));
        } finally {
            dispatch(onRequestAction(REQUESTED_PAGE_CHECK, false));
        }
    };
};

export const uploadFile = (file, fallbackValue = defaultImagePaths.placeholderPath, sizes) => {
    return async dispatch => {
        try {
            dispatch(onRequestAction(REQUESTED_UPLOAD, true));
            let response = await APIUpload(file, sizes);
            return response.data.file;
        } catch (error) {
            dispatch(alertError(errorHandler(error, {
                "413": () => i18n.t('error.tooLarge')
            })));
            return fallbackValue;
        } finally {
            dispatch(onRequestAction(REQUESTED_UPLOAD, false));
        }
    }
};

export const setPageProperty = ({id, name, value}) => {
    return async dispatch => {
        dispatch({type: PAGES_SET_PROP, payload: {id, name, value}});
        await dispatch(savePage({
            id: id,
            settings: {
                [name === 'editable_style' ? 'style' : name]: value
            }
        }));
    };
};

export const setPageProperties = ({id, settings}) => {
    return async dispatch => {
        dispatch({type: PAGES_SET_PROPS, payload: {id, settings}});
        await dispatch(savePage({
            id: id,
            settings
        }));
    };
};

export const reorderBlocks = ({pageId, data}) => {
    return async (dispatch, getState) => {
        const state = getState().editor;
        const newState = {
            ...state,
            pages: [
                ...state.pages.filter(item => item.id !== pageId),
                {
                    ...state.pages.find(item => item.id === pageId),
                    editable_blocks: data
                }
            ]
        };

        dispatch({type: EDITOR_UPDATE, payload: newState});
        dispatch({type: PAGES_PUSH_HISTORY, payload: pageId});
        dispatch(savePage({
            id: pageId,
            settings: {
                blocks: data
            }
        }));
    };
};

export const addBlock = ({pageId, data}) => {
    return async (dispatch, getState) => {
        const state = getState().editor;
        const page = state.pages.find(item => item.id === pageId);
        const newBlocks = [...page.editable_blocks, data];
        const newState = {
            ...state,
            pages: [
                ...state.pages.filter(item => item.id !== pageId),
                {
                    ...page,
                    editable_blocks: newBlocks
                }
            ]
        };

        dispatch({type: EDITOR_UPDATE, payload: newState});
        dispatch({type: PAGES_PUSH_HISTORY, payload: pageId});
        dispatch(savePage({
            id: pageId,
            settings: {
                blocks: newBlocks
            }
        }));
    };
};

export const addHeaderBlock = ({pageId, data}) => {
    return async (dispatch, getState) => {
        const state = getState().editor;
        const page = state.pages.find(item => item.id === pageId);
        const newBlocks = [...page.editable_blocks];
        newBlocks.unshift(data);
        const newState = {
            ...state,
            pages: [
                ...state.pages.filter(item => item.id !== pageId),
                {
                    ...page,
                    editable_blocks: newBlocks
                }
            ]
        };

        dispatch({type: EDITOR_UPDATE, payload: newState});
        dispatch({type: PAGES_PUSH_HISTORY, payload: pageId});
        dispatch(savePage({
            id: pageId,
            settings: {
                blocks: newBlocks
            }
        }));
    };
}

export const updateStoreBeforeDelete = ({pageId, index}) => {
    return (dispatch, getState) => {
        const state = getState().editor;
        const page = state.pages.find(item => item.id === pageId);
        const newBlocks = page.editable_blocks.filter((item, itemIndex) => itemIndex !== index);
        const newState = {
            ...state,
            pages: [
                ...state.pages.filter(item => item.id !== pageId),
                {
                    ...page,
                    editable_blocks: newBlocks
                }
            ]
        };
        dispatch({type: EDITOR_UPDATE, payload: newState});
        return newBlocks;
    };
}

export const deleteBlock = ({pageId, index}) => {
    return async dispatch => {
        const newBlocks = dispatch(updateStoreBeforeDelete({pageId, index}));
        dispatch({type: PAGES_PUSH_HISTORY, payload: pageId});
        dispatch(savePage({
            id: pageId,
            settings: {
                blocks: newBlocks
            }
        }));
    };
};

export const updateStoreBeforeBlockSave = ({pageId, index, data}) => {
    return (dispatch, getState) => {
        const state = getState().editor;
        const page = state.pages.find(item => item.id === pageId);
        const newBlocks = page ? [...page.editable_blocks] : [];
        newBlocks[index] = {
            ...newBlocks[index],
            data
        };
        const newState = {
            ...state,
            pages: [
                ...state.pages.filter(item => item.id !== pageId),
                {
                    ...page,
                    editable_blocks: newBlocks
                }
            ]
        };

        dispatch({type: EDITOR_UPDATE, payload: newState});

        return newBlocks;
    }
}

export const saveBlock = ({pageId, index, data}) => {
    return async dispatch => {
        let tmpData;
        if (Array.isArray(data)) {
            tmpData = data;
        } else {
            tmpData = {};
            for (let key in data) {
                if (Array.isArray(data[key])) {
                    tmpData[key] = [];
                    for (let item of data[key]) {
                        let data;
                        if (item instanceof File)
                            data = await dispatch(uploadFile(item), getDefaultImageByPropName(key));
                        else
                            data = item;
                        tmpData[key].push(data);
                    }
                }
                else if (data[key] instanceof File)
                    tmpData[key] = await dispatch(uploadFile(
                        data[key],
                        getDefaultImageByPropName(key),
                        key === 'photo' ? AVATAR_SIZES : null
                    ));
                else
                    tmpData[key] = data[key];
            }
        }

        const newBlocks = dispatch(updateStoreBeforeBlockSave({pageId, index, data: tmpData}));

        dispatch({type: PAGES_PUSH_HISTORY, payload: pageId});
        dispatch(savePage({
            id: pageId,
            settings: {
                blocks: newBlocks
            }
        }));
    };
};

export const saveAdBlock = ({pageId, index, data}) => {
    return async dispatch => {
        const tmpBlocks = [];

        for (let item of data.blocks) {
            let data = {
                type: item.type,
                data: {}
            };
            for (let key in item.data) {
                if (item.data[key] instanceof File)
                    data.data[key] = await dispatch(uploadFile(item.data[key]), getDefaultImageByPropName(key));
                else
                    data.data[key] = item.data[key];
            }
            tmpBlocks.push(data);
        }
        const tmpData = {
            ...data,
            blocks: tmpBlocks
        }

        const newBlocks = dispatch(updateStoreBeforeBlockSave({pageId, index, data: tmpData}));

        dispatch({type: PAGES_PUSH_HISTORY, payload: pageId});
        dispatch(savePage({
            id: pageId,
            settings: {
                blocks: newBlocks
            }
        }));
    }
};

export const setBlock = ({index, type, data}) => ({
    type: BLOCKS_SET,
    payload: {index, type, data}
});

export const editBlock = (data) => ({
    type: BLOCKS_EDIT,
    payload: data
});

export const clearEditMode = () => ({
    type: EDIT_MODE__CLEAR
});

export const undo = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: PAGES_UNDO,
            payload: id
        });

        const page = getState().editor.pages.find(item => item.id === id);
        dispatch(savePage({
            id,
            settings: {
                style: page.editable_style,
                blocks: page.editable_blocks
            }
        }));
    };
};

export const redo = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: PAGES_REDO,
            payload: id
        });

        const page = getState().editor.pages.find(item => item.id === id);
        dispatch(savePage({
            id,
            settings: {
                style: page.editable_style,
                blocks: page.editable_blocks
            }
        }));
    }
};

export const pushPageHistory = (id) => ({
    type: PAGES_PUSH_HISTORY,
    payload: id
});

export const importPage = (link, history) => {
    return async dispatch => {
        try {
            dispatch(onRequestAction(REQUESTED_PAGES_CREATE, true));
            const response = await APIImportPage(link);
            dispatch({type: PAGES_CREATE, payload: response.data});
            history.push(`${appRoutes.editor}/${response.data.id}`);
        } catch (error) {
            dispatch(alertError(errorHandler(error)));
        } finally {
            dispatch(onRequestAction(REQUESTED_PAGES_CREATE, false));
        }
    };
};