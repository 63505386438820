import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import moment from "moment";

import {MODE, NODE_ENV} from "../helpers/const";
import {BASE_URL} from "../helpers/services";

export let defaultErrorMessages = {
    default: '',
    request: ''
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        supportedLngs: ['en', 'ru'],
        load: 'languageOnly',
        debug: NODE_ENV === MODE.DEV || BASE_URL === 'https://my.pfmlink.tech'
    })
    .then(() => {
        moment.locale(i18n.language);
        defaultErrorMessages = {
            "400": i18n.t('error.400'),
            "401": i18n.t('error.401'),
            "403": i18n.t('error.403'),
            "404": i18n.t('error.404'),
            "422": i18n.t('error.422'),
            "429": i18n.t('error.429'),
            "500": i18n.t('error.500'),
            default: i18n.t('error.default'),
            request: i18n.t('error.request')
        }
    });

export default i18n;