import {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {addBlock, addHeaderBlock, pushPageHistory, setPageProperty} from "../../redux/editor/actions";
import {requestTrackerSelector} from "../../redux/selectors";

import {designTypesArray, getBlockConfigs, blockTypes, appRoutes} from "../../helpers/defaults";
import {scrollToEditorComponent} from "../../helpers/scrollUtils";

const EditorTools = ({user, currentTabIndex, page, block}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const requestTracker = useSelector(requestTrackerSelector);
    const {t} = useTranslation();
    const blockConfigsArray = useMemo(() => Object.values(getBlockConfigs()), []);

    const addEditorComponent = async ({type, data}) => {
        if (page) {
            await dispatch(addBlock({
                pageId: page.id,
                data: {type, data}
            }));
            scrollToEditorComponent(page.editable_blocks.length);
        }
    };

    const addHeader = async (data) => {
        await dispatch(addHeaderBlock({
            pageId: page.id,
            data: {type: blockTypes.header, data}
        }));
        scrollToEditorComponent(0);
    };

    return (
        <div className={`tools-panel__tools${currentTabIndex === 0 ? ' tools-panel__tools--bg-white' : ''}`}>
            {currentTabIndex === 0 &&
            blockConfigsArray.map(item => {
                const hasBlock = (type) => page && page.editable_blocks && page.editable_blocks.some(block => block.type === type);

                const isItemDisabled =
                    block ||
                    item.disabled ||
                    requestTracker.editor.savePage ||
                    requestTracker.editor.publishPage ||
                    (item.type === blockTypes.header && hasBlock(blockTypes.header)) ||
                    (item.type === blockTypes.perfluence && hasBlock(blockTypes.perfluence) && user && user.settings.perfluence);

                return (
                    <button
                        key={item.type}
                        className={"block-type " + (item.hidden ? 'd-none' : '')}
                        onClick={async () => {
                            if (item.disabled)
                                return;

                            if (item.type === blockTypes.header) {
                                await addHeader({
                                    ...item.data,
                                    name: user.name
                                });
                                return;
                            }

                            if (item.type === blockTypes.commercial) {
                                await addEditorComponent({
                                    type: item.type,
                                    data: item.data['itb']
                                });
                                return;
                            }

                            if (item.type === blockTypes.perfluence && user && !user.settings.perfluence) {
                                history.push(appRoutes.perfluence);
                                return;
                            }

                            await addEditorComponent({
                                type: item.type,
                                data: item.data
                            });
                        }}
                        disabled={isItemDisabled}
                    >
                        <img src={item.toolIcon} alt={t(`button.blocks.${item.type}`)} className="block-type__icon"/>
                        <span className="block-type__name">{t(`button.blocks.${item.type}`)}</span>
                    </button>
                );
            })
            }
            {currentTabIndex === 1 &&
            designTypesArray.map(design => (
                <button
                    key={design.name}
                    className={`design-type${page && page.editable_style === design.name ? ' design-type--selected' : ''}`}
                    disabled={
                        (page && page.editable_style === design.name) ||
                        requestTracker.editor.savePage ||
                        requestTracker.editor.publishPage
                    }
                    onClick={() => {
                        if (page) {
                            dispatch(setPageProperty({
                                id: page.id,
                                name: 'editable_style',
                                value: design.name
                            }));
                            dispatch(pushPageHistory(page.id));
                        }
                    }}
                >
                    <div className="design-type__icon-wrapper">
                        <img src={design.icon} alt={t(`button.design.${design.name}`)} className="design-type__icon"/>
                    </div>
                    <span className="design-type__name">{t(`button.design.${design.name}`)}</span>
                </button>
            ))
            }
        </div>
    );
};

export default EditorTools;