

export const initGoogleAnalytics = () => {
    const head = document.querySelector('head');

    let script = document.createElement('script');
    let scriptSrc = document.createElement('script');

    scriptSrc.setAttribute('src','https://www.googletagmanager.com/gtag/js?id=G-LC1V3J9GG5');
    head.appendChild(scriptSrc);

    script.innerHTML = "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-LC1V3J9GG5');";
    head.appendChild(script);
};

export const initYandexAnalytics = () => {
    const head = document.querySelector('head');

    let script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.innerHTML = "(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n" +
        "        m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n" +
        "      (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n" +
        "\n" +
        "      ym(82216351, \"init\", {\n" +
        "        clickmap:true,\n" +
        "        trackLinks:true,\n" +
        "        accurateTrackBounce:true,\n" +
        "        webvisor:true\n" +
        "      });"
    head.appendChild(script);

    let noscript = document.createElement('noscript');
    let div = document.createElement('div');
    let img = document.createElement('img');
    img.setAttribute("style", "position:absolute; left:-9999px;");
    img.setAttribute("alt", "");
    img.setAttribute("src", "https://mc.yandex.ru/watch/82216351");
    div.appendChild(img);
    noscript.appendChild(div);
    head.appendChild(noscript);

};

const categoriesGA = {
    'registration': 'reg',
    'pop_up_skip': 'pop_up',
    'pop_up_try': 'pop_up',
    'create_new_page': 'create_new_page',
    'published': 'published',
    'clear_sheet': '4option',
    'sample': '4option',
    'wizard': '4option',
    'taplink': '4option',
    'choose_a_template': '4option',
    'constructor': '4option',
    'import': '4option',
    'bind_existing': 'bind_existing'
};

export const sendAnalytics = (event) => {
    if (window.gtag && categoriesGA.hasOwnProperty(event)) {
        window.gtag('event', event, {
            'event_category': categoriesGA[event]
        });
    }
    if(window.ym) {
        window.ym(82216351,'reachGoal', event)
    }
};

