import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

import {clearEditMode} from "../../redux/editor/actions";

import {publicationState as publicationDefaultState} from "../../helpers/pagePublicationStateUtils";

import PageStatus from "../PageStatus";

const EditorTabs = ({tabsSettings, currentTabIndex, setTab, publicationState}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    return (
        <div className="tools-panel__tabs">
            {
                tabsSettings.map((item, index) => (
                    <button
                        className="tools-panel__tab-button"
                        disabled={currentTabIndex === item.index}
                        onClick={() => {
                            setTab(item.index);
                            if (index > 0)
                                dispatch(clearEditMode());
                        }}
                        key={index}
                    >
                        {t(`editor.tabs.${item.name}`)}
                        {index === 2 &&
                        publicationState !== publicationDefaultState.published.name &&
                        <PageStatus status={publicationState}/>
                        }
                    </button>
                ))
            }
        </div>
    )
};

export default EditorTabs;