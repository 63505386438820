import {useEffect, useState} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {requestTrackerSelector, templatesSelector} from "../../redux/selectors";
import {createByTemplate, loadTemplates} from "../../redux/templates/actions";

import {withTemplatePopup} from "../../hoc/withPopup";

import AppLayout from "../../layouts/AppLayout";

import PageLoader from "../../components/PageLoader";
import {PageTemplatesSwitcher} from "../../components/FieldWrappers";
import {sendAnalytics} from "../../Analytics";

const Templates = ({templatePopupStateSetter}) => {
    const dispatch = useDispatch();
    const requestTracker = useSelector(requestTrackerSelector);
    const templates = useSelector(templatesSelector);
    const history = useHistory();
    const [templateIndex, setTemplateIndex] = useState(0);
    const {t} = useTranslation();

    const handleTemplateClick = async (title) => {
        const templateId = templates[templateIndex].id;
        dispatch(createByTemplate({templateId, title, history}));
    };

    useEffect(() => {
        if (!templates.length)
            dispatch(loadTemplates());
    }, [dispatch]);

    return (
        <AppLayout>
            <div className="page-wrapper">
                {requestTracker.templates ?
                    <PageLoader/>
                    :
                    <div className="templates">
                        <Container className="custom-container">
                            <Row className="custom-row">
                                <Col className="custom-col custom-col--with-max-width">
                                    <h2 className="pfm-subtitle">{t('title.chooseTemplate')}</h2>
                                </Col>
                            </Row>
                            <Row className="custom-row">
                                <Col className="custom-col custom-col--with-max-width">
                                    {templates.length ?
                                        <>
                                            <PageTemplatesSwitcher
                                                templates={templates}
                                                disabled={!requestTracker.editor.createPage}
                                                onChange={index => {
                                                    setTemplateIndex(index);
                                                }}
                                            />
                                        </>
                                        :
                                        <span>{t('pages.templates.noTemplates')}</span>
                                    }
                                </Col>
                            </Row>
                            {!!templates.length &&
                            <Row className="custom-row">
                                <Col className="custom-col custom-col--with-max-width">
                                    <div className="templates__buttons-wrapper">
                                        <Button
                                            className="primary-btn templates__button"
                                            onClick={() => {
                                                templatePopupStateSetter({
                                                    initial: templates[templateIndex].title,
                                                    callback: handleTemplateClick,
                                                    opened: true
                                                });
                                                sendAnalytics('choose_a_template');
                                            }}
                                            disabled={requestTracker.editor.createPage}
                                        >
                                            {t('button.chooseTemplate')}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            }
                        </Container>
                    </div>
                }
            </div>
        </AppLayout>
    );
};

export default withTemplatePopup(Templates);