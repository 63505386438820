import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {appRoutes} from "../../helpers/defaults";
import {LANDING_URL} from "../../helpers/services";

import logo from "../../assets/logo.svg";

const NotFound = () => {
    const {t} = useTranslation();

    return (
        <main className="default">
            <header className="default__header">
                <a href={LANDING_URL} className="default__landing-link">
                    <img src={logo} alt="PFM Link" className="default__logo"/>
                </a>
            </header>
            <Container>
                <Row>
                    <Col>
                        <div className="default__body">
                            <div className="default__title">
                                <h1>{t('title.notFound')}</h1>
                            </div>
                            <div className="default__homepage-link">
                                <Link className="pfm-anchor" to={appRoutes.home}>{t('button.backHome')}</Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </main>
    );
};

export default NotFound;