import {Fragment} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Form, Spinner} from "react-bootstrap";
import parser from "html-react-parser";
import {useTranslation} from "react-i18next";

import {requestTrackerSelector} from "../../redux/selectors";

import {STATIC_URL} from "../../helpers/services";
import {defaultImagePaths, designTypesArray, fieldTypes, acceptableFileTypes} from "../../helpers/defaults";

import {sendAnalytics} from "../../Analytics";

import {
    CheckboxFieldWrapper,
    FieldWrapper,
    FileFieldWrapper,
    MultipleFileFieldWrapper,
    PageSwitcher,
    SwitcherTabsWrapper,
    TextareaFieldWrapper
} from "../FieldWrappers";
import {MAX_FILE_SIZE} from "../../helpers/const";
import {alertError} from "../../redux/alert/actions";
import {prepareImageURL} from "../../helpers/imageUtils";

export const WizardHeader = ({title, img, intro, currentStep, stepsAmount}) => {
    const {t} = useTranslation();

    return (
        <div className="wizard__header">
            <div
                className="wizard__header__cover"
                style={{
                    backgroundImage: `url(${STATIC_URL + img})`
                }}
            >
                <h1 className="wizard__title">{title}</h1>
                <span className="wizard__steps">{`${t('pages.wizard.step')}${currentStep}/${stepsAmount}`}</span>
            </div>
            <div className="wizard__header__intro">
                {parser(intro)}
            </div>
        </div>
    );
};

const specFields = new Set([
    fieldTypes.textarea,
    fieldTypes.file,
    fieldTypes.switcher,
    fieldTypes.checkbox,
    fieldTypes.group,
    fieldTypes['file-multiple'],
    fieldTypes.page
]);

export const WizardForm = ({fields, currentStep, setCurrentStep, form}) => {
    const dispatch = useDispatch();
    const requestTracker = useSelector(requestTrackerSelector);
    const {t} = useTranslation();

    return (
        <Form
            noValidate
            className="wizard__form"
            onSubmit={form.handleSubmit}
            onReset={form.handleReset}
        >
            {fields &&
            fields.map((field, index) => (
                <Fragment key={index}>
                    {field.type === fieldTypes.switcher &&
                    <SwitcherTabsWrapper
                        name={field.name}
                        label={field.label}
                        value={form.state[field.name]}
                        onChange={(value) => {
                            form.changeByFieldName(field.name, value);
                        }}
                        options={field.options}
                    />
                    }
                    {field.type === fieldTypes.textarea &&
                    <TextareaFieldWrapper
                        name={field.name}
                        label={field.label}
                        value={form.state[field.name]}
                        placeholder={field.placeholder}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                    />
                    }
                    {field.type === fieldTypes.file &&
                    <FileFieldWrapper
                        name={field.name}
                        label={field.label}
                        desc={field.desc}
                        accept="image/jpeg,image/png,image/gif"
                        onChange={(e) => {
                            if (e.target.files[0].size >= MAX_FILE_SIZE) {
                                dispatch(alertError(t('alert.maxSize')));
                                return;
                            }
                            if(!acceptableFileTypes.has(e.target.files[0].type)) {
                                dispatch(alertError(t('alert.invalidFormat')));
                                return;
                            }

                            form.handleChange(e)
                        }}
                    >
                        <div className="load">
                            <div className="load__preview">
                                <img
                                    src={prepareImageURL(form.state[field.name])}
                                    alt={field.desc ? field.desc : t('common.photo')}
                                    className="load__preview__image"
                                />
                            </div>
                            <Form.Label className="load__button">
                                <span>{t('button.upload')}</span>
                            </Form.Label>
                        </div>
                    </FileFieldWrapper>
                    }
                    {field.type === fieldTypes['file-multiple'] &&
                    <MultipleFileFieldWrapper
                        name={field.name}
                        label={field.label}
                        accept="image/jpeg,image/png,image/gif"
                        files={form.state[field.name]}
                        onChange={(e) => {
                            let filesArray = Array.from(e.target.files);
                            for (let file of filesArray) {
                                if (file.size >= MAX_FILE_SIZE) {
                                    dispatch(alertError(t('alert.maxSizeWithName', {filename: file.name})));
                                    return;
                                }
                                if(!acceptableFileTypes.has(file.type)) {
                                    dispatch(alertError(t('alert.invalidFormat')));
                                    return;
                                }
                            }
                            if (!form.state[field.name].some(item => defaultImagePaths.set.has(item)))
                                filesArray = [...form.state[field.name], ...filesArray];
                            form.changeByFieldName(field.name, filesArray);
                        }}
                        onFileSwap={(result) => {
                            form.changeByFieldName(field.name, result)
                        }}
                        onFileDelete={(index) => {
                            const filtered = form.state[field.name].filter((item, itemIndex) => itemIndex !== index);
                            form.changeByFieldName(
                                field.name,
                                filtered
                            );
                        }}
                        loading={requestTracker.upload}
                    >
                        <div className="load">
                            <Form.Label className="load__button">
                                <span>{t('button.upload')}</span>
                            </Form.Label>
                        </div>
                    </MultipleFileFieldWrapper>
                    }
                    {field.type === fieldTypes.checkbox &&
                    <CheckboxFieldWrapper
                        name={field.name}
                        label={field.label}
                        value={form.state[field.name]}
                        onChange={value => {
                            form.changeByFieldName(field.name, value);
                        }}
                    />
                    }
                    {field.type === fieldTypes.group &&
                        field.data.map((item, index) => (
                            <>
                                <FieldWrapper
                                    key={`${index}_${field.name}`}
                                    type={item.type}
                                    name={`${index}_${field.name}`}
                                    label={item.label}
                                    placeholder={item.placeholder}
                                    desc={item.desc}
                                    value={form.state[`${index}_${field.name}`] || ''}
                                    validation={form.errors[`${index}_${field.name}`]}
                                    onChange={form.handleChange}
                                    onBlur={form.handleBlur}
                                />
                            </>
                        ))
                    }
                    {field.type === fieldTypes.page &&
                        <PageSwitcher
                            page={field.data}
                            onChange={(index) => {
                                form.changeByFieldName(field.name, designTypesArray[index].name);
                            }}
                        />
                    }
                    {(field.type && !specFields.has(field.type)) &&
                    <FieldWrapper
                        type={field.type}
                        name={field.name}
                        label={field.label}
                        placeholder={field.placeholder}
                        desc={field.desc}
                        value={form.state[field.name] || ''}
                        validation={form.errors[field.name]}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                    />
                    }
                </Fragment>
            ))
            }
            <div className={`wizard__form__buttons-wrapper${currentStep === 4 ? ' wizard__form__buttons-wrapper--mobile-fixed' : ''}`}>
                {(!!currentStep && currentStep !== 4) &&
                <Button
                    type="button"
                    variant="light"
                    size="sm"
                    className="secondary-btn light-btn wizard__form__btn"
                    disabled={requestTracker.wizard || requestTracker.upload}
                    onClick={() => {
                        setCurrentStep(currentStep - 1)
                    }}
                >
                    {t('button.back')}
                </Button>
                }
                <Button
                    type="submit"
                    size="sm"
                    className="primary-btn wizard__form__btn"
                    onClick={() => currentStep === 4 && sendAnalytics('constructor')}
                    disabled={requestTracker.wizard || requestTracker.upload || requestTracker.editor.savePage}
                    style={currentStep === 4 ? {'margin': '0 auto'} : null}
                >
                    {requestTracker.wizard || requestTracker.upload || requestTracker.editor.savePage ?
                        <Spinner animation="border" size="sm"/>
                        :
                        currentStep === 4 ? t('button.setUp') : t('button.wizardContinue')
                    }
                </Button>
            </div>
        </Form>
    );
};
