const setVH = () => {
    const callback = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    callback();

    window.addEventListener('resize', callback);
};

export default setVH;