import {LOGOUT, PERFLUENCE_SET_LINK} from "../types";

const initialState = {
    link: ''
};

const perfluence = (state = initialState, action) => {
    switch (action.type) {
        case PERFLUENCE_SET_LINK:
            return {
                ...state,
                link: action.payload
            };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default perfluence;