import {useState} from "react";
import {CreatePageByTemplatePopup, CropperPopup, PageSettingsPopup, PromptPopup, SetPasswordPopup} from "../components/Popups";

const cropperState = {
    image: null,
    aspect: 1,
    zoom: 1,
    cropShape: 'rect',
    crop: {
        x: 0,
        y: 0
    },
    targetName: '',
    filename: '',
    filetype: '',
    setCroppedImage: null
};

const promptState = {
    opened: false,
    message: '',
    onConfirm: null
};

const settingsState = {
    page: null,
    opened: false
};

const templateState = {
    opened: false,
    initial: '',
    callback: null
};

const passwordState = {
    opened: false,
}

const withPopup = (Popup, name, initialState) => (Component) => {
    return props => {
        const [state, setState] = useState({...initialState});
        const componentProps = {
            [`${name}PopupState`]: state,
            [`${name}PopupStateSetter`]: setState
        };

        return (
            <>
                <Component {...props} {...componentProps}/>
                <Popup state={state} setState={setState}/>
            </>
        );
    };
};

export const withCropperPopup = withPopup(CropperPopup, 'cropper', cropperState);
export const withPromptPopup = withPopup(PromptPopup, 'prompt', promptState);
export const withSettingsPopup = withPopup(PageSettingsPopup, 'settings', settingsState);
export const withTemplatePopup = withPopup(CreatePageByTemplatePopup, 'template', templateState);
export const withPasswordPopup = withPopup(SetPasswordPopup, 'password',passwordState);