import {
    BLOCK_TYPES,
    BLOCKS_EDIT,
    BLOCKS_SET,
    EDIT_MODE__CLEAR,
    EDITOR_UPDATE,
    LOGOUT,
    PAGES_CLEAR_HISTORY,
    PAGES_CREATE,
    PAGES_DELETE,
    PAGES_LOAD,
    PAGES_PUSH_HISTORY,
    PAGES_REDO,
    PAGES_SET_PROP,
    PAGES_SET_PROPS,
    PAGES_UNDO,
    PAGES_UPDATE
} from "../types";
import {reduceToEditableBlocks} from "../../helpers/editorUtils";

const initialState = {
    pages: [],
    block: null,
    blockTypes: {},
    blockTypesLoaded: false,
    modifiedStyle: {}
}

const editor = (state = initialState, action) => {
    switch (action.type) {
        case "MOD_STYLE": {
            return {
                ...state,
                modifiedStyle: action.payload
            }
        }
        case BLOCK_TYPES:
            return {
                ...state,
                blockTypes: action.payload,
                blockTypesLoaded: true
            };
        case PAGES_LOAD: {
            const pages = action.payload.reduce((a, e) => {
                const editable_blocks = reduceToEditableBlocks(e);
                const editable_style = e.unpublished_changes.style || e.style || 'instagram';
                a.push({
                    ...e,
                    editable_blocks,
                    editable_style,
                    sessionHistory: {
                        past: [],
                        current: {
                            style: editable_style,
                            blocks: [...editable_blocks]
                        },
                        future: []
                    }
                });
                return a;
            }, []);
            return {
                ...state,
                pages
            };
        }
        case PAGES_UPDATE: {
            const editable_blocks = reduceToEditableBlocks(action.payload);
            const editable_style = action.payload.unpublished_changes.style || action.payload.style || 'instagram';
            const prevPage = {...state.pages.find(item => item.id === action.payload.id)}
            const sessionHistory = prevPage.sessionHistory ?
                {...prevPage.sessionHistory}
                :
                {
                    past: [],
                    current: {
                        style: editable_style,
                        blocks: [...editable_blocks]
                    },
                    future: []
                }
            const page = {
                ...action.payload,
                editable_blocks,
                editable_style,
                sessionHistory
            };

            return {
                ...state,
                pages: [
                    ...state.pages.filter(item => item.id !== action.payload.id),
                    page
                ]
            };
        }
        case PAGES_DELETE: {
            return {
                ...state,
                pages: [
                    ...state.pages.filter(item => item.id !== action.payload)
                ]
            };
        }
        case PAGES_CREATE: {
            const editable_blocks = reduceToEditableBlocks(action.payload);
            const editable_style = action.payload.unpublished_changes.style || action.payload.style || 'instagram';
            const page = {
                ...action.payload,
                editable_blocks,
                editable_style,
                sessionHistory: {
                    past: [],
                    current: {
                        style: editable_style,
                        blocks: [...editable_blocks]
                    },
                    future: []
                }
            };

            return {
                ...state,
                pages: [
                    ...state.pages,
                    page
                ]
            };
        }
        case PAGES_SET_PROP: {
            const page = state.pages.find(item => item.id === action.payload.id);
            return {
                ...state,
                pages: [
                    ...state.pages.filter(item => item.id !== action.payload.id),
                    {
                        ...page,
                        [action.payload.name]: action.payload.value,
                    }
                ]
            };
        }
        case PAGES_SET_PROPS: {
            const page = state.pages.find(item => item.id === action.payload.id);
            return {
                ...state,
                pages: [
                    ...state.pages.filter(item => item.id !== action.payload.id),
                    {
                        ...page,
                        ...action.payload.settings,
                    }
                ]
            };
        }
        case PAGES_UNDO: {
            const page = state.pages.find(item => item.id === action.payload);
            const past = [...page.sessionHistory.past];
            const future = [page.sessionHistory.current, ...page.sessionHistory.future];
            const current = past.pop();

            return {
                ...state,
                pages: [
                    ...state.pages.filter(item => item.id !== action.payload),
                    {
                        ...page,
                        editable_style: current.style,
                        editable_blocks: [...current.blocks],
                        sessionHistory: {
                            past,
                            current,
                            future
                        }
                    }
                ]
            };
        }
        case PAGES_REDO: {
            const page = state.pages.find(item => item.id === action.payload);
            const past = [...page.sessionHistory.past, page.sessionHistory.current];
            const future = [...page.sessionHistory.future];
            const current = future.shift();

            return {
                ...state,
                pages: [
                    ...state.pages.filter(item => item.id !== action.payload),
                    {
                        ...page,
                        editable_style: current.style,
                        editable_blocks: [...current.blocks],
                        sessionHistory: {
                            past,
                            current,
                            future
                        }
                    }
                ]
            };
        }
        case PAGES_PUSH_HISTORY: {
            const page = state.pages.find(item => item.id === action.payload);
            const current = {
                style: page.editable_style,
                blocks: [...page.editable_blocks]
            };
            const past = [...page.sessionHistory.past, page.sessionHistory.current];

            return {
                ...state,
                pages: [
                    ...state.pages.filter(item => item.id !== action.payload),
                    {
                        ...page,
                        sessionHistory: {
                            past: past.length > 10 ? past.slice(1) : past,
                            current,
                            future: []
                        }
                    }
                ]
            };
        }
        case PAGES_CLEAR_HISTORY: {
            const page = state.pages.find(item => item.id === action.payload);

            return {
                ...state,
                pages: [
                    ...state.pages.filter(item => item.id !== action.payload),
                    {
                        ...page,
                        sessionHistory: {
                            past: [],
                            current: {
                                style: page.editable_style,
                                blocks: [...page.editable_blocks]
                            },
                            future: []
                        }
                    }
                ]
            };
        }
        case BLOCKS_EDIT:
            return {
                ...state,
                block: {
                    ...state.block,
                    data: action.payload
                }
            };
        case BLOCKS_SET: {
            return {
                ...state,
                block: {
                    index: action.payload.index,
                    type: action.payload.type,
                    data: action.payload.data
                }
            };
        }
        case EDIT_MODE__CLEAR:
            return {
                ...state,
                block: null,
            };
        case EDITOR_UPDATE:
            return action.payload;
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default editor;