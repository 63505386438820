import {fieldTypes} from "./defaults";

export const reduceWizardStep = (step) => {
    return step.reduce((a, item) => {
        if (item.type === fieldTypes.checkbox) {
            const tmp = item.options.reduce((a, option) => {
                a.push({
                    type: fieldTypes.checkbox,
                    name: option.value,
                    label: option.text,
                    value: false,
                });
                return a;
            }, []);
            a.push(...tmp);
        } else if (item.type === fieldTypes.group) {
            if (item.name === 'photos') {
                a.push({
                    name: item.name,
                    type: fieldTypes['file-multiple'],
                    label: item.label,
                    value: []
                });
            } else if (item.name === 'videos') {
                const tmp = item.data.reduce((a, url, index) => {
                    a.push({
                        ...url,
                        name: `${index}_${item.name}`,
                        value: url.default || ''
                    });
                    return a;
                }, []);
                a.push(...tmp);
            }
        } else if (item.type === fieldTypes.page) {
            a.push({
                ...item,
                value: item.data.style
            });
        } else {
            a.push({
                ...item,
                value: item.default || ''
            });
        }
        return a;
    }, []);
};

export const wizardReviver = (k, v) => {
    if (k === 'value' && Array.isArray(v))
        return v.filter(i => !!i);
    return v;
};

export const wizardReplacer = (k, v) => {
    if (k === 'value' && Array.isArray(v))
        return v.filter(i => !(i instanceof File));
    return v;
};