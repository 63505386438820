import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";

import {editorSelector, newsSelector, perfluenceSelector, statsSelector, userSelector} from "./redux/selectors";
import {loadBlockTypes, loadPages} from "./redux/editor/actions";
import {loadStats} from "./redux/stats/actions";
import {getPerfluenceLink} from "./redux/perfluence/actions";
import {loadNews} from "./redux/news/actions";
import {auth} from "./redux/user/actions";

import {appRoutes} from "./helpers/defaults";

import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import Register from "./pages/Register";
import SocialAuth from "./pages/SocialAuth";
import SendReset from "./pages/SendReset";
import ResetPassword from "./pages/ResetPassword";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import CreatePage from "./pages/CreatePage";
import Editor from "./pages/Editor";
import Wizard from "./pages/Wizard";
import Templates from "./pages/Templates";
import ImportPage from "./pages/ImportPage";
import Perfluence from "./pages/Perfluence";
import News from "./pages/News";
import Stats from "./pages/Stats";
import Knowledge from './pages/Knowledge';
import Feedback from "./components/Feedback";

import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import ScrollToTop from "./components/ScrollToTop";
import Alert from "./components/Alert";
import {sendAnalytics} from "./Analytics";

const appRoutesArray = [
    {
        link: appRoutes.login,
        component: Login,
        private: false
    }, {
        link: appRoutes.register,
        component: Register,
        private: false
    }, {
        link: `${appRoutes.socialAuth}/:provider`,
        component: SocialAuth,
        private: false
    }, {
        link: appRoutes.sendReset,
        component: SendReset,
        private: false
    }, {
        link: appRoutes.reset,
        component: ResetPassword,
        private: false
    }, {
        link: appRoutes.home,
        component: Home,
        private: true
    }, {
        link: appRoutes.profile,
        component: Profile,
        private: true
    }, {
        link: appRoutes.createPage,
        component: CreatePage,
        private: true
    }, {
        link: `${appRoutes.editor}/:pageId`,
        component: Editor,
        private: true
    },
    {
        link: appRoutes.wizard,
        component: Wizard,
        private: true
    }, {
        link: appRoutes.templates,
        component: Templates,
        private: true
    }, {
        link: appRoutes.import,
        component: ImportPage,
        private: true
    }, {
        link: `${appRoutes.news}/:newsId?`,
        component: News,
        private: true
    }, {
        link: `${appRoutes.stats}/:pageId?/:chartType?`,
        component: Stats,
        private: true
    }, {
        link: appRoutes.perfluence,
        component: Perfluence,
        private: true
    }, {
        link: appRoutes.knowledge,
        component: Knowledge,
        private: true
    }, {
        link: appRoutes.feedback,
        component: Feedback,
        private: false
    }
];

const App = () => {
    const dispatch = useDispatch();
    const user = useSelector(userSelector);
    const editor = useSelector(editorSelector);
    const news = useSelector(newsSelector);
    const stats = useSelector(statsSelector);
    const perfluence = useSelector(perfluenceSelector);
    const history = useHistory();

    const queryParams = useLocation();

    useEffect(() => {
        if(queryParams.search === "?link_pf=1"){
            sendAnalytics('bind_existing');
        }
    },[queryParams])

    useEffect(() => {
        dispatch(auth(history));
    }, [dispatch, history]);

    useEffect(() => {
        if (user && !user.need_pasword) {
            if (!editor.pages.length)
                dispatch(loadPages());
            if (!stats.loaded)
                dispatch(loadStats());
            if (!editor.blockTypesLoaded)
                dispatch(loadBlockTypes());
            if (!news.list.length)
                dispatch(loadNews());
            if (!perfluence.link)
                dispatch(getPerfluenceLink());
        }
    }, [dispatch, user]);

    return (
        <>
            <ScrollToTop/>
            <div className="App">
                <Alert/>
                <Switch>
                    {
                        appRoutesArray.map(route => {
                            const Component = route.component;
                            if (route.private)
                                return <PrivateRoute exact path={route.link} key={route.link}><Component/></PrivateRoute>;
                            return <PublicRoute exact path={route.link} key={route.link}><Component/></PublicRoute>
                        })
                    }
                    <Route path={appRoutes.notFound}>
                        <NotFound/>
                    </Route>
                </Switch>
            </div>
        </>
    );
};

export default App;