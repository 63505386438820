import {LOGOUT, NEWS_LOAD, NEWS_PAGINATE} from "../types";

const initialState = {
    list: [],
    total: 0,
    currentPage: 1
};

const news = (state = initialState, action) => {
    switch (action.type) {
        case NEWS_LOAD:
            return {
                list: action.payload.list,
                total: action.payload.total,
                currentPage: action.payload.currentPage
            };
        case NEWS_PAGINATE:
            return {
                ...state,
                list: [...state.list, ...action.payload],
                currentPage: state.currentPage + 1
            }
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default news;