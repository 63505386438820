import Chart from "chart.js/auto";
import moment from "moment";
import "chartjs-adapter-moment";

export const statsFilters = {
    week: 'week',
    month: 'month',
    quarter: 'quarter',
    year: 'year',
    interval: 'interval'
};

export const chartAxisDateUnits = {
    week: 'day',
    month: 'week',
    quarter: 'week',
    year: 'month',
    interval: 'day'
};

export const statsFiltersArrays = [
    {value: statsFilters.week, tKey: 'pages.stats.period.week'},
    {value: statsFilters.month, tKey: 'pages.stats.period.month'},
    {value: statsFilters.quarter, tKey: 'pages.stats.period.quarter'},
    {value: statsFilters.year, tKey: 'pages.stats.period.year'},
    {value: statsFilters.interval, tKey: 'pages.stats.period.interval'},
];

export const chartDataPaths = new Set(['visitors', 'devices', 'conversions']);

export const createChart = (el, type, labels, datasets, options) => {
    return new Chart(el, {
        type,
        data: {
            labels,
            datasets,
        },
        options: options || {}
    });
};

export const prepareChartProps = (period, start, end, source = null, zeroValue = 0) => {
    const from = period !== statsFilters.interval ? moment().subtract(1, period) : moment(start);
    const to = period !== statsFilters.interval ? moment() : moment(end);
    const diff = to.diff(from, 'days');
    const labels = [];
    const data = [];

    for (let i = 0; i <= diff; i++) {
        const d = moment(from).add(i, 'days').format('YYYY-MM-DD');
        labels.push(d);
        if (source)
            data.push(+source[d] || zeroValue);
    }

    return {
        labels,
        data
    };
};