const TemplateType = ({name, disabled, icon, onClick, t}) => {
    return (
        <div className={`template-type${disabled ? ' template-type--disabled' : ''}`} onClick={() => {
            if (disabled)
                return;
            onClick();
        }}>
            <div className="template-type__title">
                {t(`pages.createPage.${name}.title`)}
            </div>
            <div className="template-type__desc">
                <div className="template-type__desc__icon-wrapper">
                    {icon &&
                    <img src={icon} alt={t('common.icon')}/>
                    }
                </div>
                <div className="template-type__desc__text">
                    {t(`pages.createPage.${name}.text`)}
                </div>
            </div>
        </div>
    );
};

export default TemplateType;