import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import { HexColorPicker } from "react-colorful";

import useClickOutside from "./useOutsideclick";

import iconDrop from '../../assets/icon-drop.svg'

import {setPropertyGradient, setPropertyColor} from "../EditorDesignForm/helpers";

import {userSettings} from "../../redux/editorDesign/actions";

import {useDispatch} from "react-redux";

import {debounce} from "lodash/function";


export const PopoverPicker = ({label, config ,stylePage, params, block}) => {
  const popover = useRef();
  const popover2 = useRef();

  const dispatch = useDispatch();

  const [color, setColor] = useState();
  const [gradientColor, setGradientColor] = useState();

  const [isOpen, toggle] = useState({inline: false,gradient:false});

  const close = useCallback(() => toggle({inline: false, gradient: false}),[]);
    useClickOutside(popover, close);
    useClickOutside(popover2, close);

    const saveInlineConfig = (color,config) => {
        dispatch(userSettings(config, color));
    };

    const saveGradientConfig = (colorGradient, config,color) => {
        dispatch(userSettings(config,`linear-gradient(135deg, ${color} 2.8%, ${colorGradient} 100%)`));
    };

    const debounceInline = useMemo(() => debounce(saveInlineConfig, 300),[]);
    const debounceGradient = useMemo(() => debounce(saveGradientConfig, 300),[]);

    const handleChangeColor = (color) => {
        setColor(color);
        debounceInline(color, config)
    };

    const handleChangeGradientColor = (colorGradient) => {
        if(isOpen.gradient)
            setGradientColor(colorGradient);
        else
            setColor(colorGradient)
        debounceGradient(colorGradient,config,color);
    };

    useEffect(() => {
        if(stylePage && block)
            setColor(getComputedStyle(document.querySelector('.pfm-page')).getPropertyValue(config));
        else
            setColor(getComputedStyle(document.querySelector('.pfm-page')).getPropertyValue('--page-bg-color'));
    }, [stylePage, block]);

    useEffect(() => {
        if(isOpen.inline && params === "Color")
            setPropertyColor(stylePage,config, color)
        if((isOpen.inline || isOpen.gradient) && params === "Gradient")
            setPropertyGradient(stylePage,config,color,gradientColor)
    },[color,gradientColor, params, isOpen,stylePage]);


  return (
      <div className='picker'>
          {params === 'Gradient' ?
                  <div className={'d-flex justify-content-between w-75'}>
                      <div>
                          <span className='picker__title'>{label}</span>
                          <div className={`picker__swatch-container ${isOpen.inline? 'picker__swatch-container--selected' : ''}`} onClick={() => toggle({...isOpen, inline: true})}>
                              <div className={`picker__swatch ${isOpen.inline ? 'picker__swatch--selected' : ''}`} style={{backgroundColor: color}} />
                              <span className='picker__swatch_arrow'>
                            <img src={iconDrop} alt={'iconDrop'}/>
                          </span>
                          </div>

                          {isOpen.inline && (
                              <div className='picker__popover' ref={popover}>
                                  <HexColorPicker color={color} onChange={handleChangeGradientColor} />
                              </div>
                          )}
                      </div>
                      <div>
                          <span className='picker__title'>{label}2</span>
                          <div className={`picker__swatch-container ${isOpen.gradient? 'picker__swatch-container--selected' : ''}`} onClick={() => toggle({...isOpen, gradient: true})}>
                              <div className={`picker__swatch ${isOpen.gradient ? 'picker__swatch--selected' : ''}`} style={{backgroundColor: gradientColor}} />
                              <span className='picker__swatch_arrow'>
                            <img src={iconDrop} alt={'iconDrop'}/>
                        </span>
                          </div>
                          {isOpen.gradient && (
                              <div className='picker__popover picker__popover--left' ref={popover2}>
                                  <HexColorPicker color={gradientColor} onChange={handleChangeGradientColor}/>
                              </div>
                          )}
                      </div>
                  </div>
                    :
                  <>
                      <span className='picker__title'>{label}</span>
                      <div className={`picker__swatch-container ${isOpen.inline? 'picker__swatch-container--selected' : ''}`} onClick={() => toggle({...isOpen, inline: true})}>
                          <div className={`picker__swatch ${isOpen.inline ? 'picker__swatch--selected' : ''}`} style={{background: color}} />
                          <span className='picker__swatch_arrow'>
                                <img src={iconDrop} alt={'iconDrop'}/>
                            </span>
                      </div>

                      {isOpen.inline && (
                          <div className='picker__popover' ref={popover}>
                              <HexColorPicker color={color} onChange={handleChangeColor}/>
                          </div>
                      )}
                  </>
          }
      </div>
  );
};