import {
    APILogin,
    APILogout,
    APIMe,
    APIMeUpdate,
    APIRegister,
    APIResetPassword,
    APISendReset,
    APISocialAuth
} from "../../api";

import {
    AUTH,
    LOGOUT,
    RECEIVED_RESET,
    RECEIVED_SEND_RESET,
    REQUESTED_AUTH,
    REQUESTED_LOGIN,
    REQUESTED_LOGOUT,
    REQUESTED_REGISTER,
    REQUESTED_RESET,
    REQUESTED_SEND_RESET,
    REQUESTED_USER_UPDATE,
    USER_SET
} from "../types";

import {onRequestAction} from "../requestTracker/actions";
import {alertError, alertSuccess} from "../alert/actions";
import i18n, {defaultErrorMessages} from "../../i18n";
import errorHandler from "../../helpers/errorHandler";
import {appRoutes} from "../../helpers/defaults";

export const auth = (history) => {
    return async dispatch => {
        if (!localStorage.getItem('token')) {
            return;
        }
        try {
            dispatch(onRequestAction(REQUESTED_AUTH, true));
            let response = await APIMe();
            dispatch({type: AUTH, payload: response.data});
            dispatch(alertSuccess(() => i18n.t('alert.welcome', {name: response.data.name})));
        } catch (error) {
            dispatch(alertError(errorHandler(error)));
            history.push(appRoutes.login);
        } finally {
            dispatch(onRequestAction(REQUESTED_AUTH, false));
        }
    };
};

export const login = ({email, password, history}) => {
    return async dispatch => {
        try {
            dispatch(onRequestAction(REQUESTED_LOGIN, true));
            let response = await APILogin(email, password);
            localStorage.setItem('token', response.data.access_token);
            await dispatch(auth(history));
        } catch (error) {
            dispatch(alertError(errorHandler(error, {
                "401": () => i18n.t('error.login')
            })));
        } finally {
            dispatch(onRequestAction(REQUESTED_LOGIN, false));
        }
    }
};

export const logout = () => {
    return async dispatch => {
        try {
            dispatch(onRequestAction(REQUESTED_LOGOUT, true));
            await APILogout();
            dispatch({type: LOGOUT});
        } catch (error) {
            dispatch(alertError(errorHandler(error)));
        } finally {
            dispatch(onRequestAction(REQUESTED_LOGOUT, false));
        }
    }
};

export const register = ({email, name, password, history}) => {
    return async dispatch => {
        try {
            dispatch(onRequestAction(REQUESTED_REGISTER, true));
            let response = await APIRegister(email, name, password);
            localStorage.setItem('token', response.data.access_token);
            await dispatch(auth(history));
        } catch (error) {
            dispatch(alertError(errorHandler(error, {
                "422": () => i18n.t('error.register')
            })));
        } finally {
            dispatch(onRequestAction(REQUESTED_REGISTER, false));
        }
    }
};

export const sendReset = (email) => {
    return async dispatch => {
        try {
            dispatch(onRequestAction(REQUESTED_SEND_RESET, true));
            await APISendReset(email);
            dispatch(onRequestAction(RECEIVED_SEND_RESET, true));
        } catch (error) {
            dispatch(alertError(errorHandler(error, {
                "400": () => defaultErrorMessages["429"]
            })));
        } finally {
            dispatch(onRequestAction(REQUESTED_SEND_RESET, false));
        }
    };
};

export const resetPassword = (token, password, email) => {
    return async dispatch => {
        try {
            dispatch(onRequestAction(REQUESTED_RESET, true));
            await APIResetPassword(token, password, email);
            dispatch(alertSuccess(() => i18n.t('alert.passwordChanged')));
            dispatch(onRequestAction(RECEIVED_RESET, true));
        } catch (error) {
            dispatch(alertError(errorHandler(error, {
                "400": (error) => error.response ? error.response.data.message : defaultErrorMessages["400"],
                "422": () => i18n.t('error.reset')
            })));
        } finally {
            dispatch(onRequestAction(REQUESTED_RESET, false));
        }
    };
};

export const socialAuth = (provider, code, history) => {
    return async dispatch => {
        try {
            dispatch(onRequestAction(REQUESTED_AUTH, true));
            let response = await APISocialAuth(provider, code);
            localStorage.setItem('token', response.data.access_token);
            await dispatch(auth(history));
        } catch (error) {
            dispatch(alertError(errorHandler(error, {
                "400": () => i18n.t('error.emailTaken')
            })));
            history.push(appRoutes.login);
        } finally {
            dispatch(onRequestAction(REQUESTED_AUTH, false));
        }
    };
};

export const editProfile = (settings) => {
    return async dispatch => {
        const tmp = {}
        for (let key in settings)
            if (settings[key]) tmp[key] = settings[key];
        try {
            dispatch(onRequestAction(REQUESTED_USER_UPDATE, true));
            let response = await APIMeUpdate(tmp);
            dispatch({type: USER_SET, payload: response.data});
            dispatch(alertSuccess(() => i18n.t('alert.profileUpdated')));
        } catch (error) {
            dispatch(alertError(errorHandler(error, {
                "422": (error) =>
                    error.response && error.response.data.errors.password_old ? i18n.t('error.password') : i18n.t('error.emailTakenProfile')
            })));
        } finally {
            dispatch(onRequestAction(REQUESTED_USER_UPDATE, false));
        }
    }
};

export const updateUserData = () => {
    return async dispatch => {
        try {
            const response = await APIMe();
            dispatch({
                type: USER_SET,
                payload: response.data
            });
        } catch (error) {
            dispatch(alertError(errorHandler(error)));
        }
    };
};