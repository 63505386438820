import {useEffect, useRef, useState} from "react";

import {createChart} from "../../helpers/statsUtils";

const ChartLayout = ({id, title, type, labels, datasets, options, children}) => {
    const ref = useRef(null);
    const [chart, setChart] = useState(null);

    const handleCreateChart = (el) => {
        setChart(createChart(el.getContext('2d'), type, labels, datasets, options));
    };

    useEffect(() => {
        if (ref && ref.current && !chart)
            handleCreateChart(ref.current);

        return () => {
            if (chart) chart.destroy();
        }
    }, [ref, chart]);

    useEffect(() => {
        if (chart) {
            chart.data.labels = labels;
            chart.data.datasets = datasets;
            chart.options = options;
            chart.update();
        }
    }, [chart, labels, datasets, options]);

    return (
        <div className="chart">
            <canvas
                id={id}
                aria-label={title}
                role="img"
                ref={ref}
            />
            <div className="chart__legend">
                {children}
            </div>
        </div>
    );
};

export default ChartLayout;