import {Redirect, Route} from "react-router-dom";

import {useQuery, useToken} from "../../hooks";
import {appRoutes} from "../../helpers/defaults";

const PublicRoute = ({children, ...rest}) => {
    const query = useQuery();
    const token = useToken(query.get('api_token'));

    if (token)
        return <Redirect to={appRoutes.home}/>;

    return (
        <Route {...rest}>
            {children}
        </Route>
    );
}

export default PublicRoute;