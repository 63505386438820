import {APIWizardFillSteps, APIWizardGetFields} from "../../api";
import {reduceWizardStep} from "../../helpers/wizardUtils";
import {onRequestAction} from "../requestTracker/actions";
import {
    REQUESTED_WIZARD_UPDATE,
    WIZARD_GET_FROM_LS,
    WIZARD_INIT,
    WIZARD_POPUP,
    WIZARD_RETRIEVE,
    WIZARD_SET_COMPLETED,
    WIZARD_SET_CURRENT_STEP,
    WIZARD_SET_STEP,
    WIZARD_SET_TO_LS
} from "../types";
import {alertError} from "../alert/actions";
import errorHandler from "../../helpers/errorHandler";
import {updateUserData} from "../user/actions";
import {loadPages} from "../editor/actions";

export const initWizard = () => {
    return async dispatch => {
        try {
            dispatch(onRequestAction(REQUESTED_WIZARD_UPDATE, true));
            const response = await APIWizardGetFields();
            const result = [];
            for (let key in response.data) {
                result.push(reduceWizardStep(response.data[key]));
            }
            dispatch({
                type: WIZARD_INIT,
                payload: result
            });
        } catch (error) {
            dispatch(alertError(errorHandler(error)));
        } finally {
            dispatch(onRequestAction(REQUESTED_WIZARD_UPDATE, false));
        }
    };
};

export const setWizardCurrentStep = (step) => ({
    type: WIZARD_SET_CURRENT_STEP,
    payload: step
});

export const editWizardStep = ({index, step}) => ({
    type: WIZARD_SET_STEP,
    payload: {
        index,
        step
    }
});

export const fillWizardSteps = ({data, completed}) => {
    return async (dispatch, getState) => {
        const state = getState().wizard;
        try {
            dispatch(onRequestAction(REQUESTED_WIZARD_UPDATE, true));
            const response = await APIWizardFillSteps(data, completed);
            if (response.data.page && state.currentStep === 3) {
                dispatch(editWizardStep({
                    index: state.currentStep + 1,
                    step: [
                        ...state.steps[state.currentStep + 1],
                        {
                            type: 'page',
                            name: 'page',
                            value: response.data.page.style,
                            data: response.data.page
                        }
                    ]
                }));
            }
            if (completed) {
                dispatch({type: WIZARD_SET_COMPLETED});
                await dispatch(loadPages());
                await dispatch(updateUserData());
            } else
                dispatch(setWizardCurrentStep(state.currentStep + 1));
        } catch (error) {
            dispatch(alertError(errorHandler(error)));
        } finally {
            dispatch(onRequestAction(REQUESTED_WIZARD_UPDATE, false));
        }
    };
};

export const hideWizardPopup = () => ({
    type: WIZARD_POPUP,
    payload: false
});

export const saveWizardStateToLocalStorage = () => ({type: WIZARD_SET_TO_LS});

export const getWizardStateFromLocalStorage = (state) => ({
    type: WIZARD_GET_FROM_LS,
    payload: state
});

export const retrieveWizardState = (data) => {
    return async dispatch => {
        try {
            await dispatch(initWizard());
            dispatch({type: WIZARD_RETRIEVE, payload: data})
        } catch (error) {
            dispatch(alertError(errorHandler(error)));
        }
    }
};

export const showWizardPopup = () => {
    return async dispatch => {
        setTimeout(() => {
            dispatch({
                type: WIZARD_POPUP,
                payload: true
            });
        }, 5000);
    }
};