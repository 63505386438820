import {Fragment, useEffect, useMemo, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {Button, Form} from "react-bootstrap";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import get from "lodash/get";
import {Trans, useTranslation} from "react-i18next";

import {clearEditMode, editBlock, saveAdBlock, saveBlock} from "../../redux/editor/actions";
import {alertError, alertWarning} from "../../redux/alert/actions";

import {useForm} from "../../hooks";

import {
    regExp,
    getBlockConfigs,
    blockTypes,
    fieldTypes,
    defaultImagePaths, acceptableFileTypes
} from "../../helpers/defaults";
import {getImageMimeType, prepareImageURL} from "../../helpers/imageUtils";
import {MAX_FILE_SIZE} from "../../helpers/const";
import {createEditorFormData, socialProps} from "../../helpers/editorUtils";
import {PERFLUENCE_PROFILE_URL} from "../../helpers/services";

import {
    BlockSwitcher,
    DatetimePickerFieldWrapper,
    FieldWrapper,
    FileFieldWrapper,
    MultipleFileFieldWrapper,
    RadioFieldsWrapper,
    SelectFieldWrapper,
    SwitcherFieldWrapper,
    SwitcherTabsWrapper,
    TextareaFieldWrapper
} from "../FieldWrappers";
import RichTextEditor from "../RichTextEditor";
import CodeEditor from "../CodeEditor";

const specFields = new Set([
    fieldTypes.textarea,
    fieldTypes.select,
    fieldTypes.file,
    fieldTypes['file-multiple'],
    fieldTypes.switcher,
    fieldTypes.radio,
    fieldTypes['rich-text'],
    fieldTypes.checkbox,
    fieldTypes['datetime-picker'],
    fieldTypes.block_switcher,
    fieldTypes['section-checkbox'],
    fieldTypes.code
]);

const EditorForm = ({page, block, blockFields, setBlockModified, submitTrigger, setSubmitTrigger, cropperState, setCropperState}) => {
    const dispatch = useDispatch();
    const blockConfigs = useMemo(() => getBlockConfigs(), []);
    const {t} = useTranslation();

    const ref = useRef(null);
    const submitRef = useRef(null);

    const editorFormData = useMemo(() =>
        createEditorFormData({
            type: block.type,
            data: block.data,
            blockFields,
            prefix: 'data'
        }), [block, blockFields]);

    const validation = useMemo(() => {
        return editorFormData.fields.reduce((a, e) => {
            switch(e.type) {
                case 'url':
                    a[e.name] = {
                        required: e.required,
                        regExp: e.required ? regExp.url : regExp.urlOrEmpty,
                        error: t('validation.url')
                    };
                    break;
                case 'tel':
                    a[e.name] = {
                        required: e.required,
                        regExp: regExp.tel,
                        error: t('validation.tel')
                    };
                    break;
                case 'nickname':
                    a[e.name] = {
                        required: e.required,
                        regExp: regExp.nickname,
                        error: t('validation.nickname')
                    };
                    break;
                default:
                    a[e.name] = {
                        required: e.required,
                        error: t('validation.required')
                    };
            }

            return a;
        }, {});
    }, [editorFormData]);

    const form = useForm({
        values: editorFormData.state,
        validation,
        onSubmit: () => {
            if (block.type === blockTypes.commercial)
                dispatch(saveAdBlock({pageId: page.id, index: block.index, data: block.data}));
            else
                dispatch(saveBlock({pageId: page.id, index: block.index, data: block.data}));
            dispatch(clearEditMode());
            setBlockModified(false);
        },
        onChange: (e) => {
            const clonedBlock = cloneDeep(block);
            const name = e.target.name;
            const value = e.target.type === 'file' ?
                (e.target.multiple ? Array.from(e.target.files) : e.target.files[0]) : e.target.value;
            const isImageClickActionChanged =
                (block.type === blockTypes.image || block.type === blockTypes.commercial) &&
                name.includes('click_action');

            if (isImageClickActionChanged) {
                const url = name.replace('click_action', 'url');
                if (!regExp.urlOrEmpty.test(form.state[url]))
                    set(clonedBlock, url, '');
            }

            if(block.type === blockTypes.social && value.includes('@')){
                if(value[0] === '@'){
                    dispatch(alertWarning(t('alert.validNickname')))
                }
                set(clonedBlock, name, value.replace(/^@/, ''));
                dispatch(editBlock(clonedBlock.data));
                setBlockModified(true);

                return;
            }

            set(clonedBlock, name, value);

            dispatch(editBlock(clonedBlock.data));
            setBlockModified(true);
        },
        onChangeByFieldName: (name, value) => {
            const clonedBlock = cloneDeep(block);
            const isSocialServiceChanged =
                block.type === blockTypes.social &&
                name.includes('service') &&
                get(clonedBlock, name) !== value;


            if (isSocialServiceChanged) {
                set(clonedBlock, name.replace('service', 'icon'), value);
                set(clonedBlock, name.replace('service', 'link'), socialProps[value].link);
            }

            set(clonedBlock, name, value);

            dispatch(editBlock(clonedBlock.data));
            setBlockModified(true);
        },
        onReset: () => {
            dispatch(clearEditMode());
            setBlockModified(false);
            setSubmitTrigger(false);
        }
    });

    const handleEditImage = async (fieldName, e = null) => {
        let image, filename, filetype;
        if (e) {
            image = URL.createObjectURL(e.target.files[0]);
            filename = e.target.files[0].name;
            filetype = e.target.files[0].type;
        } else {
            image = prepareImageURL(form.state[fieldName]);
            filename = form.state[fieldName] instanceof File ? `${form.state[fieldName].name}` : `${form.state[fieldName]}`;
            filetype = form.state[fieldName] instanceof File ? form.state[fieldName].type : getImageMimeType(form.state[fieldName]);
        }
        setCropperState({
            ...cropperState,
            image,
            filename,
            filetype,
            aspect: fieldName.includes('cover') || fieldName.includes('image') ? 2 : 1,
            cropShape: fieldName.includes('photo') ? 'round' : 'rect',
            targetName: fieldName,
            setCroppedImage: form.changeByFieldName
        });
    };

    useEffect(() => {
        if (ref && ref.current) {
            ref.current.focus();
        }
    }, [ref]);

    useEffect(() => {
        if (submitTrigger && submitRef && submitRef.current) {
            submitRef.current.click();
            setSubmitTrigger(false);
        }
    }, [submitTrigger, setSubmitTrigger]);

    return (
        <>
            <Form
                noValidate
                className="editor__form"
                onSubmit={form.handleSubmit}
                onReset={form.handleReset}
            >
                {
                    block.type === blockTypes.perfluence &&
                    <div className="editor__form__perfluence">
                        <p className="pfm-paragraph">{t('editor.form.perfluence')}</p>
                        <a href={PERFLUENCE_PROFILE_URL} className="primary-btn primary-btn--small btn btn-primary" target="_blank">
                            {t('button.configurePerfluence')}
                        </a>
                        <div>
                            <p className="editor__sidebar__title mb-0 mt-3">{t('editor.perfluence.title')}</p>
                            <p className="mb-3">
                                <span className="font-weight-bold">{t('editor.perfluence.horizontal.title')}</span> - {t('editor.perfluence.horizontal.text')}<br/>
                                <span className="font-weight-bold">{t('editor.perfluence.vertical.title')}</span> - {t('editor.perfluence.vertical.text')}
                            </p>
                        </div>
                    </div>
                }
                {
                    block.type === blockTypes.html &&
                    <div className="editor__form__perfluence mb-4">
                        <p className="pfm-paragraph">
                            <Trans i18nKey="editor.form.html">
                                Блок Perfluence - автоматический рекламный блок из рекламируемого тобой проекта в Perfluence.
                                    Содержимое настраивается в личном кабинете Perfluence.
                                    С настройкой этого блока тебе поможет <a href={`${PERFLUENCE_PROFILE_URL}/tickets/create?category=pfm_link`} target="_blank">Служба Заботы</a>.
                            </Trans>
                        </p>
                        <a href={`${PERFLUENCE_PROFILE_URL}/tickets/create?category=pfm_link`} className="primary-btn primary-btn--small btn btn-primary" target="_blank">
                            {t('button.helpLead')}
                        </a>
                    </div>
                }
                {
                    editorFormData.fields.map((field, index) => (
                        <Fragment key={index}>
                            {block.type === blockTypes.timer && field.name.includes('date') &&
                            <div className="editor__sidebar__subtitle">
                                <span>{t('editor.form.timer')}</span>
                            </div>
                            }
                            {block.type === blockTypes.spoiler && field.name.includes('title') &&
                            <p className="pfm-paragraph">{t('editor.form.spoiler')}</p>
                            }
                            {field.type === fieldTypes.textarea &&
                            <TextareaFieldWrapper
                                name={field.name}
                                label={field.label}
                                value={form.state[field.name]}
                                placeholder={field.placeholder}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                validation={form.errors[field.name]}
                                ref={index === 0 ? ref : null}
                            />
                            }
                            {field.type === fieldTypes.select &&
                            <SelectFieldWrapper
                                className={field.name.includes('icon') ? 'form-group--select form-group--select-icon' : 'form-group--select'}
                                name={field.name}
                                label={field.label}
                                value={form.state[field.name] || ''}
                                onChange={(state) => {
                                    form.changeByFieldName(field.name, state.value);
                                }}
                                options={field.options}
                                onRemove={
                                    block.type === blockTypes.social && block.data.length > 1 && field.name.includes('service') ?
                                        () => {
                                            form.setModified(true);
                                            setBlockModified(true);
                                            dispatch(editBlock(block.data.filter((item, itemIndex) => !field.name.includes(itemIndex))));
                                        }
                                        :
                                        null
                                }
                                hidden={
                                    block.type === blockTypes.social &&
                                    field.name.includes('icon') &&
                                    form.state[field.name] &&
                                    !form.state[field.name].includes('other')
                                }
                            />
                            }
                            {field.type === fieldTypes.file &&
                            <FileFieldWrapper
                                name={field.name}
                                label={field.label}
                                desc={field.desc}
                                accept="image/jpeg,image/png,image/gif"
                                ref={index === 0 ? ref : null}
                                file={form.state[field.name]}
                                preview={field.name.includes('photo') ? 'avatar' : true}
                                onChange={async (e) => {
                                    if (e.target.files[0].size >= MAX_FILE_SIZE) {
                                        dispatch(alertError(t('alert.maxSize')));
                                        return;
                                    }
                                    if(!acceptableFileTypes.has(e.target.files[0].type)) {
                                        dispatch(alertError(t('alert.invalidFormat')));
                                        return;
                                    }
                                    form.handleChange(e);
                                    if (e.target.files[0] && !field.name.includes('image'))
                                        await handleEditImage(field.name, e)
                                }}
                                onFileDelete={() => {
                                    const value = field.name.includes('image') ?
                                        defaultImagePaths.placeholderPath :
                                        (field.name.includes('photo') ? defaultImagePaths.avatarPlaceholderPath : '');
                                    form.changeByFieldName(field.name, value);
                                }}
                            >
                                <div className="editor__form__file-load">
                                    {!field.name.includes('image') &&
                                    form.state[field.name] &&
                                    !defaultImagePaths.set.has(form.state[field.name]) &&
                                    <div className="editor__form__edit-btn">
                                        <Button
                                            type="button"
                                            size="sm"
                                            className="secondary-btn blue-btn"
                                            onClick={async () => {
                                                await handleEditImage(field.name)
                                            }}
                                        >
                                            {t('button.edit')}
                                        </Button>
                                    </div>
                                    }
                                    {(!form.state[field.name] || defaultImagePaths.set.has(form.state[field.name])) &&
                                    <>
                                        {!field.name.includes('photo') ?
                                            <Form.Label className="load-button">
                                                <span>{t('button.uploadImage')}</span>
                                            </Form.Label>
                                            :
                                            <Form.Label className="load-button load-button--avatar">
                                                <span>+</span>
                                            </Form.Label>
                                        }
                                    </>

                                    }
                                </div>
                            </FileFieldWrapper>
                            }
                            {field.type === fieldTypes['file-multiple'] &&
                            <MultipleFileFieldWrapper
                                name={field.name}
                                label={field.label}
                                desc={field.desc}
                                accept="image/jpeg,image/png,image/gif"
                                onChange={(e) => {
                                    let filesArray = Array.from(e.target.files);
                                    for (let file of filesArray) {
                                        if (file.size >= MAX_FILE_SIZE) {
                                            dispatch(alertError(t('alert.maxSizeWithName', {filename: file.name})));
                                            return;
                                        }
                                        if(!acceptableFileTypes.has(file.type)) {
                                            dispatch(alertError(t('alert.invalidFormat')));
                                            return;
                                        }
                                    }
                                    if (!form.state[field.name].some(item => defaultImagePaths.set.has(item)))
                                        filesArray = [...form.state[field.name], ...filesArray];
                                    form.changeByFieldName(field.name, filesArray);
                                }}
                                onFileSwap={(result) => {
                                    form.changeByFieldName(field.name, result);
                                }}
                                ref={index === 0 ? ref : null}
                                files={form.state[field.name]}
                                onFileDelete={(index) => {
                                    const filtered = form.state[field.name].filter((item, itemIndex) => itemIndex !== index);
                                    const newData = filtered.length ? filtered : blockConfigs.gallery.data.images;
                                    form.changeByFieldName(
                                        field.name,
                                        newData
                                    );
                                }}
                            >
                                <div className="editor__form__file-load">
                                    <Form.Label className="load-button">
                                        <span>{t('button.upload')}</span>
                                    </Form.Label>
                                </div>
                            </MultipleFileFieldWrapper>
                            }
                            {field.type === fieldTypes.switcher && !field.hidden &&
                            <SwitcherTabsWrapper
                                name={field.name}
                                label={field.label}
                                value={form.state[field.name]}
                                onChange={(value) => {
                                    form.changeByFieldName(field.name, value);
                                }}
                                options={field.options}
                            />
                            }
                            {field.type === fieldTypes.radio &&
                            <RadioFieldsWrapper
                                name={field.name}
                                label={field.label}
                                value={block.type === blockTypes.perfluence && !block.data.type ? 'horizontal' : form.state[field.name]}
                                onChange={form.handleChange}
                                options={field.options}
                            />
                            }
                            {field.type === fieldTypes['rich-text'] &&
                            <RichTextEditor
                                initialData={form.state[field.name]}
                                currentPageStyle={page ? page.editable_style : null}
                                onChange={(value) => {
                                    form.changeByFieldName(field.name, value);
                                }}
                            />
                            }
                            {field.type === fieldTypes['datetime-picker'] &&
                            <DatetimePickerFieldWrapper
                                name={field.name}
                                label={field.label}
                                value={form.state[field.name]}
                                onChange={value => {
                                    form.changeByFieldName(field.name, value);
                                }}
                            />
                            }
                            {(field.type === fieldTypes.checkbox || field.type === fieldTypes['section-checkbox']) &&
                            <SwitcherFieldWrapper
                                name={field.name}
                                label={field.label}
                                value={form.state[field.name]}
                                section={field.type === fieldTypes['section-checkbox']}
                                onChange={value => {
                                    form.changeByFieldName(field.name, value);
                                }}
                            />
                            }
                            {field.type === fieldTypes.block_switcher &&
                            <BlockSwitcher
                                value={form.state[field.name]}
                                blocks={block.data.blocks}
                                pageStyle={page.editable_style}
                                options={field.options}
                                onChange={(index, initial) => {
                                    const layout = blockFields[block.type][0].options[index].value;

                                    form.changeByFieldName(field.name, layout);
                                    if (layout !== block.data.layout) {
                                        dispatch(editBlock({
                                            ...blockConfigs.commercial.data[layout]
                                        }));
                                    } else {
                                        dispatch(editBlock({
                                            layout,
                                            blocks: initial
                                        }));
                                    }

                                }}
                            />
                            }
                            {field.type === fieldTypes.code &&
                                <CodeEditor
                                    data={form.state[field.name]}
                                    validation={form.errors[field.name]}
                                    onChange={(value) => {
                                        form.changeByFieldName(field.name, value);
                                    }}
                                />
                            }
                            {!specFields.has(field.type) &&
                            <FieldWrapper
                                type={field.type === 'nickname' ? 'text' : field.type}
                                name={field.name}
                                label={field.label}
                                placeholder={field.placeholder}
                                desc={field.desc}
                                value={form.state[field.name] || ''}
                                validation={form.errors[field.name]}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                ref={index === 0 ? ref : null}
                                hidden={
                                    (block.type === blockTypes.image && block.data.click_action !== 'url') ||
                                    (
                                        block.type === blockTypes.commercial &&
                                        block.data.layout === 'itb' &&
                                        block.data.blocks[0].data.click_action !== 'url' &&
                                        field.name === 'data.blocks[0].data.url'
                                    )
                                }
                            />
                            }
                        </Fragment>
                    ))
                }
                {block.type === blockTypes.social &&
                <div className="editor__form__add-button-wrapper">
                    <Button
                        type="button"
                        variant="light"
                        className="light-btn"
                        size="sm"
                        onClick={() => {
                            form.setModified(true);
                            setBlockModified(true);
                            dispatch(editBlock([
                                ...block.data,
                                {...blockConfigs.social.data[0]}
                            ]));
                        }}
                    >
                        {t('button.add')}
                    </Button>
                </div>
                }
                {block.type &&
                <div className="editor__form__buttons-wrapper">
                    <Button
                        type="submit"
                        size="sm"
                        className="secondary-btn blue-btn"
                        disabled={!form.modified}
                        ref={submitRef}
                    >
                        {t('button.save')}
                    </Button>
                    <Button type="reset" variant="outline-light" size="sm" className="secondary-btn outline-light-btn">
                        {t('button.cancel')}
                    </Button>
                </div>
                }
            </Form>
        </>
    );
}

export default EditorForm;