import {scroller} from "react-scroll";

export const scrollToEditorComponent = (index, offset = 0) => {
    scroller.scrollTo(`editor-component-${index}`, {
        duration: 1500,
        delay: 100,
        smooth: 'easeInOutQuad',
        containerId: 'editor-workspace',
        offset
    });
};