import {useTranslation, Trans} from "react-i18next";

import DefaultLayout from "../../layouts/DefaultLayout";

const ErrorComponent = () => {
    const {t} = useTranslation();

    const onClick = () => {
        document.location.reload();
    };

    return (
        <DefaultLayout title={t('pages.error.title')} maxWidth={true}>
            <div className="default__error">
                <Trans i18nKey="pages.error.text">
                    <span>Совсем скоро мы все исправим.</span>
                    <span><button className="pfm-anchor" onClick={onClick}>Перезагрузите страницу</button> для дальнейшей работы в PFM Link.</span>
                </Trans>
            </div>
        </DefaultLayout>
    );
};

export default ErrorComponent;