import {Col, Container, Row} from "react-bootstrap";

import {LANDING_URL} from "../../helpers/services"

import logo from "../../assets/logo.svg";

const DefaultLayout = ({title, maxWidth, children}) => {
    return (
        <main className="default">
            <header className="default__header">
                <a href={LANDING_URL} className="default__landing-link">
                    <img src={logo} alt="PFM Link" className="default__logo"/>
                </a>
            </header>
            <Container>
                <Row>
                    <Col>
                        <div className={`default__body${maxWidth ? ' default__body--max-width' : ''}`}>
                            <div className="default__title">
                                <h1 className="pfm-title">{title}</h1>
                            </div>
                            {children}
                        </div>
                    </Col>
                </Row>
            </Container>
        </main>
    )
};

export default DefaultLayout;