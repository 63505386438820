import {useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {Spinner} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {useSimpleOutsideClick} from "../../hooks";

import {copyPage, deletePage} from "../../redux/editor/actions";
import {requestTrackerSelector} from "../../redux/selectors";

import {getPagePublicationState} from "../../helpers/pagePublicationStateUtils";
import {appRoutes} from "../../helpers/defaults";

import PageStatus from "../PageStatus";
import {MenuPopup, MenuPopupItem, MenuPopupList} from "../MenuPopup";

import iconMore from "../../assets/icon-more.svg";
import iconPageSettings from "./media/icon-page-settings.svg";
import iconPageCopy from "./media/icon-page-copy.svg";
import iconPageDelete from "./media/icon-page-delete.svg";
import iconViews from "../../assets/icon-views.svg";

const PageListItem = ({page, views, setPageSettings, setPromptPopupState}) => {
    const dispatch = useDispatch();
    const requestTracker = useSelector(requestTrackerSelector);
    const history = useHistory();
    const [isMenuOpened, setMenuOpened] = useState(false);
    const publicationState = useMemo(() => getPagePublicationState(page), [page]);
    const ref = useRef(null);
    const {t} = useTranslation();

    const handleEdit = () => {
        history.push(`${appRoutes.editor}/${page.id}`);
    };

    const handleMenuItemClick = (e) => {
        e.stopPropagation();
        setMenuOpened(false);
    };

    useSimpleOutsideClick(ref, () => {
        setMenuOpened(false);
    });

    return (
        <li className={`pages-list__item${isMenuOpened ? ' pages-list__item--dd-opened' : ''}`} onClick={handleEdit}
            ref={ref}>
            <div className="pages-list__item__left">
                <div className="pages-list__item__title">
                    <PageStatus status={publicationState}/>
                    <span>{page.title}</span>
                </div>
                <div className="pages-list__item__status">
                    <span className={`pages-list__item__status-text pages-list__item__status-text--${publicationState}`}>
                        {t(`pages.home.pagesList.${publicationState}`)}
                    </span>
                    {views ?
                        <span className="pages-list__item__status-views">
                            <img src={iconViews} alt={t('common.views')}/>
                            {views}
                        </span>
                        :
                        null
                    }
                </div>
            </div>
            <div className="pages-list__item__right">
                <button className="pages-list__item__actions-btn"
                        onClick={(e) => {
                            e.stopPropagation();
                            setMenuOpened(!isMenuOpened);
                        }}>
                    <img src={iconMore} alt={t('button.more')}/>
                </button>
                <MenuPopup isOpened={isMenuOpened}>
                    <MenuPopupList>
                        <MenuPopupItem>
                            {() => (
                                <button
                                    type="button"
                                    className="menu-popup__list__item__button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        dispatch(copyPage(page.id, history));
                                    }}
                                    disabled={requestTracker.editor.createPage || requestTracker.editor.deletePage}
                                >
                                    <span className="menu-popup__list__item__icon">
                                        <img src={iconPageCopy} alt={t('button.makeCopy')}/>
                                    </span>
                                    {requestTracker.editor.createPage ?
                                        <Spinner animation="border" size="sm"/>
                                        :
                                        <span className="menu-popup__list__item__text">{t('button.makeCopy')}</span>
                                    }
                                </button>
                            )}
                        </MenuPopupItem>
                        <MenuPopupItem>
                            {() => (
                                <button
                                    type="button"
                                    className="menu-popup__list__item__button"
                                    onClick={(e) => {
                                        handleMenuItemClick(e);
                                        setPageSettings({
                                            opened: true,
                                            page
                                        });
                                    }}
                                    disabled={requestTracker.editor.createPage || requestTracker.editor.deletePage}
                                >
                                    <span className="menu-popup__list__item__icon">
                                        <img src={iconPageSettings} alt={t('button.customize')}/>
                                    </span>
                                    <span className="menu-popup__list__item__text">{t('button.customize')}</span>
                                </button>
                            )}
                        </MenuPopupItem>
                        <MenuPopupItem>
                            {() => (
                                <button
                                    type="button"
                                    className="menu-popup__list__item__button"
                                    onClick={(e) => {
                                        handleMenuItemClick(e);
                                        setPromptPopupState({
                                            opened: true,
                                            message: t('popup.prompt.deletePage', {title: page.title}),
                                            onConfirm: () => {
                                                dispatch(deletePage(page.id));
                                            }
                                        });
                                    }}
                                    disabled={requestTracker.editor.createPage || requestTracker.editor.deletePage}
                                >
                                    <span className="menu-popup__list__item__icon">
                                        <img src={iconPageDelete} alt={t('button.delete')}/>
                                    </span>
                                    {requestTracker.editor.deletePage ?
                                        <Spinner animation="border" size="sm"/>
                                        :
                                        <span className="menu-popup__list__item__text">{t('button.delete')}</span>
                                    }
                                </button>
                            )}
                        </MenuPopupItem>
                    </MenuPopupList>
                </MenuPopup>
            </div>
        </li>
    );
};

export default PageListItem;