import moment from "moment";

import {plural} from "../i18n/helpers";

const dateFormatTypes = {
    SMART: 'smart',
    HH_MM_SS: 'HH:MM:SS',
    D_H_M: 'D:H:M',
    M: 'M',
    H: 'H'
};

export const getFormattedDateString = ({format, days, hours, minutes, seconds, t}) => {
    switch (format) {
        case dateFormatTypes.SMART: {
            const commaD = (days <= 7 && hours !== 0 ? ', ' : days === 1 && minutes >= 1 ? ', ' : ' ');
            const commaH = (days <= 1 && minutes !== 0 ? ', ' : ' ');
            const commaM = (hours <=1 && days === 0 ? ', ' : ' ');
            const commaS = (minutes === 0 && hours !== 0 ? ', ' : ' ');

            const d = days >= 1 ? `${t([plural('timer.days', days), 'timer.days'], {count: days})}` + commaD : '';
            const h = days <= 7 && hours !== 0 ? `${t([plural('timer.hours', hours), 'timer.hours'], {count: hours})}` + commaH : '';
            const m = days <= 1 && minutes !== 0 ? `${t([plural('timer.minutes', minutes), 'timer.minutes'], {count: minutes})}` + commaM : '';
            const s = hours <= 1 && days === 0 ? commaS + `${t([plural('timer.seconds', seconds < 10 ? `0${seconds}` : `${seconds}`), 'timer.seconds'], {count: seconds})}` : '';
            const smartTimer = days === 0 && hours === 0 && minutes === 0 && seconds === 0 ? `${t('timer.time_over')}` : `${d} ${h} ${m} ${s}`;
            return `${smartTimer}`;
        }
        case dateFormatTypes.HH_MM_SS: {
            const h = hours + days * 24 < 10 ? `0${hours}` : hours + days * 24;
            const m = minutes < 10 ? `0${minutes}` : minutes;
            const s = seconds < 10 ? `0${seconds}` : seconds;
            return `${h}:${m}:${s}`;
        }
        case dateFormatTypes.D_H_M: {
            const d = t([plural('timer.days', days), 'timer.days'], {count: days});
            const h = t([plural('timer.hours', hours), 'timer.hours'], {count: hours});
            const m = t([plural('timer.minutes', minutes), 'timer.minutes'], {count: minutes});
            return `${d}, ${h}, ${m}`;
        }
        case dateFormatTypes.M: {
            const value = minutes + hours * 60 + days * 24 * 60;
            return `${t([plural('timer.minutes', value), 'timer.minutes'], {count: value})}`;
        }
        case dateFormatTypes.H: {
            const value = hours + days * 24;
            return `${t([plural('timer.hours', value), 'timer.hours'], {count: value})}`;
        }
        default:
            return '--:--:--';
    }
};

export const parseNewsDate = (dateStr) => {
    const dateObj = moment(dateStr);
    const date = dateObj.date();
    const month = dateObj.month();
    const year = dateObj.year();

    return `${date < 10 ? '0' + date : date}.${month < 10 ? '0' + month : month}.${year}`;
};