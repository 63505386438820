import {useSelector} from "react-redux";
import {Alert as BootstrapAlert} from "react-bootstrap";
import parser from "html-react-parser";

import {alertSelector} from "../../redux/selectors";

const Alert = () => {
    const alert = useSelector(alertSelector);

    return (
        <div className={`pfm-alert${alert.message ? ' pfm-alert--shown' : ''}`}>
            <BootstrapAlert variant={alert.type}>
                {parser(alert.message && typeof alert.message === 'string' ? alert.message : '')}
            </BootstrapAlert>
        </div>
    );
};

export default Alert;

