import axios from "axios";
import store from "../redux";
import {LOGOUT} from "../redux/types";
import {API_URL} from "../helpers/services";
import {createImage, calcDeviceDimensions} from "../helpers/imageUtils";

const instance = axios.create({
    baseURL: API_URL
});

instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
});

instance.interceptors.response.use(
    (response) => {
        return response;
    },
     (error) => {
        const status = error && error.response ? error.response.status : -1;
        if (!error.response || status === 401 || status === 403)
            store.dispatch({type: LOGOUT});
        return Promise.reject(error);
    }
);

/* DEFAULTS */

export const APILogin = (email, password) => {
    return instance.post('/login', {
        email,
        password
    });
};

export const APIMe = () => {
    return instance.get('/me');
};

export const APIMeUpdate = (settings) => {
    return instance.post('/me', {
        ...settings
    });
};

export const APILogout = () => {
    return instance.post('/logout');
};

export const APIResetPassword = (token, email, password) => {
    return instance.post('/reset', {
        token,
        email,
        password,
        password_confirmation: password
    });
};

export const APISendReset = (email) => {
    return instance.post('/reset/send', {
        email
    });
};

export const APIRegister = (email, name, password) => {
    return instance.post('/register', {
        email,
        name,
        password
    });
};

export const APICheck = (params) => {
    return instance.get('/register/check', {
        params: {
            ...params
        }
    });
};

export const APISocialProviders = () => {
    return instance.get('/social');
};

export const APISocialAuth = (provider, code) => {
    return instance.get(`/social/${provider}`, {
        params: {
            code
        }
    });
};

/* /DEFAULTS */

/* CONTENT */

export const APITemplates = () => {
    return instance.get('/template');
};

export const APICreateByTemplate = (templateId) => {
    return instance.post(`/template/${templateId}`);
};

export const APIBlockTypes = () => {
    return instance.get('/block_type');
};

export const APICheckPagePath = (id, path) => {
    return instance.get('/page/check', {
        params: {
            page_id: id,
            path
        }
    });
};

export const APILoadPages = () => {
    return instance.get('/page');
};

export const APICreatePage = (settings = null) => {
    return instance.post('/page', {
        title: 'Новая страница',
        ...settings
    });
};

export const APIGetPage = (pageId) => {
    return instance.post(`/page/${pageId}`);
};

export const APISavePage = (id, settings) => {
    return instance.put(`/page/${id}`, {
        ...settings
    });
};

export const APIDeletePage = (id) => {
    return instance.delete(`/page/${id}`);
};

export const APIGetPageVersions = (id) => {
    return instance.get(`/page/${id}/version`);
};

export const APIGetPageVersionById = (pageId, versionId) => {
    return instance.get(`/page/${pageId}/version/${versionId}`);
};

export const APIPublishPage = (id) => {
    return instance.post(`/page/${id}/publish`);
};

export const APIPublishPageVersion = (pageId, versionId) => {
    return instance.post(`/page/${pageId}/version/${versionId}/publish`);
};

export const APIUpload = async (file, sizes) => {
    const image = await createImage(URL.createObjectURL(file));
    const sizesStr = sizes || `orig,${calcDeviceDimensions(image).map(item => item.join('x')).join(',')}`;
    const formData = new FormData();

    formData.append('file', file);
    return instance.post('/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        params: {
            sizes: sizesStr
        }
    });
};

export const APIGetPagesStats = () => {
    return instance.get('/page/stat');
};

export const APIGetPageStatsById = (id, params) => {
    return instance.get(`/page/${id}/stat`,{
        params: params || null
    });
};

export const APIImportPage = (link) => {
    return instance.post('/page/taplink', {}, {
        params: {
            link
        }
    });
};

/* /CONTENT */

/* WIZARD */

export const APIWizardGetFields = () => {
    return instance.get('/wizard');
};

export const APIWizardFillSteps = (data, completed) => {
    return instance.post('/wizard', {...data}, {
        params: {
            completed: !!completed
        }
    });
};

/* /WIZARD */

/* NEWS */

export const APIGetNews = (page) => {
    return instance.get('/news', {
        params: {
            page: page || 1
        }
    });
};

export const APIGetNewsById = (id) => {
    return instance.get(`/news/${id}`);
};

/* /NEWS */

/* PERFLUENCE INTEGRATION */

export const APIGetPerfluenceLink = () => {
    return instance.get('/perfluence/link_url');
};

/* /PERFLUENCE INTEGRATION */

/* /FEEDBACK */

export const APISendFeedback = ({message, name = null, email = null}) => {
    return instance.post('/feedback', {
        message,
        ...(name ? {name} : {}),
        ...(email ? {email} : {})
    })
};

/* /FEEDBACK */

/* /DELETE ACCOUNT */

export const APIDeleteAccount = () => {
    return instance.delete('/me');
}

/* /DELETE ACCOUNT */