import {defaultErrorMessages} from "../i18n";

const errorHandler = (error, handlerObj = {}) => {
    const tmp = {...defaultErrorMessages, ...handlerObj};

    if (error.response) {
        for (let key in tmp) {
            if (error.response.status === +key) {
                if (typeof tmp[key] === 'function') {
                    return tmp[key](error);
                }
                return tmp[key];
            }
        }
    }

    if (error.request)
        return tmp.request;

    return tmp.default;
};

export const getErrors = (error) => {
    const canBeProcessed =
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        Array.isArray(error.response.data.errors);

    if (!canBeProcessed) {
        return [];
    }

    const res = error.response.data.errors.reduce((a, e) => {
        a.push(...getErrorStr(e));
        return a;
    }, []);

    return Array.from(new Set(res));

    function getErrorStr(obj) {
        if (Array.isArray(obj)) {
            return obj;
        }

        const tmp = [];

        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                const objType = typeof obj[key];

                if (objType === 'object') {
                    tmp.push(...getErrorStr(obj[key]));
                }

                if (objType === 'string') {
                    tmp.push(obj[key]);
                }
            }
        }

        return tmp;
    }
};

export default errorHandler;

