import {useEffect, useMemo} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {useTranslation, Trans} from "react-i18next";

import {editorSelector, newsSelector, requestTrackerSelector, statsSelector, userSelector} from "../../redux/selectors";

import {appRoutes} from "../../helpers/defaults";

import {withPasswordPopup, withPromptPopup, withSettingsPopup} from "../../hoc/withPopup";

import AppLayout from "../../layouts/AppLayout";

import PageListItem from "../../components/PageListItem";
import PageLoader from "../../components/PageLoader";
import NewsList from "../../components/NewsList";

import banner from "../../assets/bg-info.png";
import bannerEvent from "../../assets/bg-info-event.png";
import {eventGA, sendAnalytics} from "../../Analytics";

const Home = ({settingsPopupStateSetter, promptPopupStateSetter, passwordPopupStateSetter}) => {
    const user = useSelector(userSelector);
    const editor = useSelector(editorSelector);
    const stats = useSelector(statsSelector);
    const history = useHistory();
    const requestTracker = useSelector(requestTrackerSelector);
    const news = useSelector(newsSelector);
    const {t} = useTranslation();

    const pages = useMemo(() => {
        if (user)
            return editor.pages.filter(item => item.id !== user.settings.wizard_page);
        return [];
    }, [user, editor]);

    useEffect(() => {
        if(user && user.need_password)
            passwordPopupStateSetter({opened: true})
    },[user])

    return (
        <AppLayout>
            <div className="page-wrapper">
                {requestTracker.editor.loadPages ?
                    <PageLoader/>
                    :
                    <div className="home">
                        <Container className="custom-container">
                            <Row className="custom-row">
                                <Col className="custom-col" sm={12} lg={6}>
                                    <div
                                        className="home__info"
                                        style={{
                                            backgroundImage: `url(${!pages.length ? banner : bannerEvent})`
                                        }}
                                    >
                                        {!pages.length ?
                                            <Trans i18nKey="pages.home.welcome">
                                                <h1>Добро пожаловать в PFM Link</h1>
                                                <p>Создай свою микространичку и размести на ней то, чего так не хватает в шапке профиля соцсети.</p>
                                                <p>Текст, кнопки, ссылки, графика и многое другое <br/>в пару кликов. Это бесплатно.</p>
                                            </Trans>
                                            :
                                            <Trans i18nKey="pages.home.welcomeBack">
                                                <h1>С возвращением!</h1>
                                                <p>Рады видеть тебя снова.<br/>Создавай новые страницы или дорабатывай готовые. У нас всё для этого есть. А ещё у нас появилась статистика просмотров опубликованных страниц.</p>
                                            </Trans>
                                        }
                                    </div>
                                    <div className="home__pages">
                                        <div className="d-flex flex-row align-items-center">
                                            <h2 className="pfm-subtitle">{t('title.pages')}</h2>
                                            <div className="home__pages__btn-wrapper">
                                                <Button className="primary-btn" onClick={() => {
                                                    history.push(appRoutes.createPage);
                                                    sendAnalytics('create_new_page');
                                                }}>
                                                    {pages.length ?
                                                        t('button.createNewPage')
                                                        :
                                                        t('button.createFirstPage')
                                                    }
                                                </Button>
                                            </div>
                                        </div>
                                        <ul className="pages-list">
                                            {pages.length ?
                                                [...pages]
                                                    .sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at))
                                                    .map(page => {
                                                        const statsPage = stats.data.pages.find(item => item.page_id === page.id);

                                                        return (
                                                            <PageListItem
                                                                key={page.id}
                                                                page={page}
                                                                views={statsPage ? statsPage.views : 0}
                                                                setPageSettings={settingsPopupStateSetter}
                                                                setPromptPopupState={promptPopupStateSetter}
                                                            />
                                                        )
                                                    })
                                                :
                                                <div className="home__pages__no-pages">
                                                    <Trans i18nKey="pages.home.noPages">
                                                        Ты ещё не создал ни одной страницы.<br/>Попробуй сейчас.
                                                    </Trans>
                                                </div>
                                            }

                                        </ul>
                                    </div>
                                </Col>
                                <Col className="custom-col" sm={12} lg={6}>
                                    <div className="pfm-divider pfm-divider--mobile"/>
                                    {/*<div className="home__knowledge-base"*/}
                                    {/*     style={{order: !pages.length ? 1 : 3}}>*/}
                                    {/*    <h2 className="pfm-subtitle">{t('title.knowledge')}</h2>*/}
                                    {/*    <ul className="knowledge-base-list">*/}
                                    {/*        <li className="knowledge-base-list__item">*/}
                                    {/*            <Link className="knowledge-base-list__item__link" to={"#"}>{t('pages.home.gettingStarted')}</Link>*/}
                                    {/*        </li>*/}
                                    {/*        <li className="knowledge-base-list__item">*/}
                                    {/*            <Link className="knowledge-base-list__item__link" to={"#"}>{t('pages.home.faq')}</Link>*/}
                                    {/*        </li>*/}
                                    {/*        <li className="knowledge-base-list__item">*/}
                                    {/*            <Link className="knowledge-base-list__item__link" to={"#"}>{t('pages.home.askQuestion')}</Link>*/}
                                    {/*        </li>*/}
                                    {/*    </ul>*/}
                                    {/*</div>*/}
                                    {/*<div className="pfm-divider" style={{order: 2}}/>*/}
                                    <div className="home__news"
                                         style={{order: !pages.length ? 3 : 1}}>
                                        <h2 className="pfm-subtitle">{t('title.news')}</h2>
                                        <NewsList
                                            news={news}
                                            buttonText={t('button.allNews')}
                                            onButtonClick={() => {
                                                history.push(appRoutes.news);
                                            }}
                                            short={true}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                }
            </div>
        </AppLayout>
    );
};

export default withPromptPopup(withSettingsPopup(withPasswordPopup(Home)));