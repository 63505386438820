import iconDropDown from "../../assets/icon-dropdown.svg";
import {useState} from "react";

const FAQ = ({question, answer}) => {
    const [isOpened, setOpened] = useState(false);

    return (
        <div className='feedback-faq_question' >
            <div className='feedback-text feedback-faq_title' onClick={() => setOpened(!isOpened)}>
                <p className={'mb-0 pr-2'}>{question}</p>
                <img className={`feedback-faq_arrow ${isOpened ? 'arrow--opened' : ''}`} src={iconDropDown} alt={'iconDropDown'}/>
            </div>
            {isOpened &&
            <p className='feedback-text feedback-faq_answer'>{answer}</p>
            }
        </div>
    )
};

export default FAQ;