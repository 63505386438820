export const copyToClipboard = (node, changeIcon = false,event) =>  {
    if(document.body.createTextRange) {
        const range = document.body.createTextRange();
        range.moveToElementText(node);
        range.select();
        document.execCommand("copy");
    }
    else if(window.getSelection) {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(node);
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
    }
    if (changeIcon){
        let targetEl = event.currentTarget;
        targetEl.classList.add('copied')
        targetEl.classList.remove('copy')
        if(targetEl.classList.contains('copied')){

            setTimeout(()=>{
                console.log(targetEl)
                targetEl.classList.remove('copied')
                targetEl.classList.add('copy')
            },1500)
        }
    }
};