import moment from "moment";

import {MODE, NODE_ENV} from "./const";
import {BASE_URL, LANDING_URL} from "./services";
import i18n from "../i18n";

import iconDefault from "../assets/theme-instagram.png";
import iconTiffany from "../assets/theme-tiffany.png";
import iconAtoms from "../assets/theme-atoms.png";
import iconGraphite from "../assets/theme-graphite.png";
import iconClouds from "../assets/theme-clouds.png";
import iconAutumn from "../assets/theme-autumn.png";
import iconPink from "../assets/theme-pink.png";
import iconEco from "../assets/theme-eco.png";
import iconMosaic from "../assets/theme-mosaic.png";
import iconAurora from "../assets/theme-aurora.png";
import iconMinimal from "../assets/theme-minimal.png";
import iconPastel from "../assets/theme-pastel.png";

import iconHeader from "../assets/blockTypeIcons/header.svg";
import iconText from "../assets/blockTypeIcons/text.svg";
import iconButton from "../assets/blockTypeIcons/button.svg";
import iconSeparator from "../assets/blockTypeIcons/separator.svg";
import iconImage from "../assets/blockTypeIcons/image.svg";
import iconSocial from "../assets/blockTypeIcons/social.svg";
import iconCommercial from "../assets/blockTypeIcons/commercial.svg";
import iconGallery from "../assets/blockTypeIcons/gallery.svg";
import iconTimer from "../assets/blockTypeIcons/timer.svg";
import iconVideo from "../assets/blockTypeIcons/video.svg";
import iconSpoiler from "../assets/blockTypeIcons/spoiler.svg";
// import iconHTML from "../assets/blockTypeIcons/html.svg";
import iconHTML from "../assets/blockTypeIcons/perfluence.svg";
import iconPerfluence from "../assets/blockTypeIcons/perfluence.svg";

import iconEmpty from "../assets/templateTypes/icon-empty.svg";
import iconTemplate from "../assets/templateTypes/icon-template.svg";
import iconWizard from "../assets/templateTypes/icon-wizard.svg";
import iconImport from "../assets/templateTypes/icon-import.svg";
import {getSearchParams} from "./urlParams";

export const appRoutes = {
    home: '/',
    socialAuth: '/auth',
    login: '/login',
    register: '/register',
    sendReset: '/send-reset',
    reset: '/reset',
    profile: '/profile',
    createPage: '/create-page',
    editor: '/editor',
    wizard: '/wizard',
    templates: '/templates',
    import: '/import',
    perfluence: '/perfluence',
    news: '/news',
    stats: '/stats',
    knowledge: '/knowledge',
    feedback: '/feedback',
    notFound: '*'
};

export const designTypes = {
    default: {name: 'instagram', icon: iconDefault},
    tiffany: {name: 'tiffany', icon: iconTiffany},
    atoms: {name: 'atoms', icon: iconAtoms},
    graphite: {name: 'graphite', icon: iconGraphite},
    clouds: {name: 'clouds', icon: iconClouds},
    autumn: {name: 'autumn', icon: iconAutumn},
    pink: {name: 'pink', icon: iconPink},
    eco: {name: 'eco', icon: iconEco},
    mosaic: {name: 'mosaic', icon: iconMosaic},
    aurora: {name: 'aurora', icon: iconAurora},
    minimal: {name: 'minimal', icon: iconMinimal},
    pastel: {name: 'pastel', icon: iconPastel}
};

export const designTypesArray = Object.values(designTypes);

export const deviceTypes = {
    mobile: 'mobile',
    tablet: 'tablet',
    desktop: 'desktop'
};

const defaultImagePathsArray = ['/images', '/img/default.jpeg', '/users/default.svg'];

export const acceptableFileTypes = new Set(['image/png', 'image/svg', 'image/jpg','image/jpeg', 'image/gif']);

export const defaultImagePaths = {
    array: defaultImagePathsArray,
    set: new Set(defaultImagePathsArray),
    commonPath: defaultImagePathsArray[0],
    placeholderPath: defaultImagePathsArray[1],
    avatarPlaceholderPath: defaultImagePathsArray[2]
};

export const getDefaultImageByPropName = (propName) => {
    switch (propName) {
        case 'cover':
            return null;
        case 'photo':
            return defaultImagePaths.avatarPlaceholderPath;
        default:
            return defaultImagePaths.placeholderPath;
    }
};

export const blockTypes = {
    header: 'header',
    text: 'text',
    gallery: 'gallery',
    social: 'social',
    timer: 'timer',
    html: 'html',
    video: 'video',
    separator: 'separator',
    button: 'button',
    image: 'image',
    commercial: 'commercial',
    'commercial.header': 'commercial.header',
    'commercial.content': 'commercial.content',
    'commercial.promocode': 'commercial.promocode',
    spoiler: 'spoiler',
    perfluence: 'perfluence',
};

export const fieldTypes = {
    text: 'text',
    file: 'file',
    'file-multiple': 'file-multiple',
    url: 'url',
    select: 'select',
    textarea: 'textarea',
    switcher: 'switcher',
    'rich-text': 'rich-text',
    checkbox: 'checkbox',
    radio: 'radio',
    'datetime-picker': 'datetime-picker',
    'block_switcher': 'block_switcher',
    group: 'group',
    page: 'page',
    'section-checkbox': 'section-checkbox',
    code: 'code'
};

export const getBlockConfigs = () => {
    let enabledEditors = getSearchParams().get('show_editors');
    enabledEditors = enabledEditors ? enabledEditors.split(',') : [];

    return {
        [blockTypes.header]: {
            type: blockTypes.header,
            toolIcon: iconHeader,
            hidden: false,
            data: {
                cover_enable: true,
                cover: '',
                cover_size: 'sm',
                photo_enable: true,
                photo: defaultImagePaths.avatarPlaceholderPath,
                photo_size: 'sm',
                photo_align: 'center',
                name_enable: true,
                name: 'nickname',
                name_url: LANDING_URL
            }
        },

        [blockTypes.text]: {
            type: blockTypes.text,
            toolIcon: iconText,
            hidden: false,
            data: {
                text: i18n.t('blockConfigs.text')
            }
        },

        [blockTypes.button]: {
            type: blockTypes.button,
            toolIcon: iconButton,
            hidden: false,
            data: {
                url: LANDING_URL,
                title: i18n.t('blockConfigs.button.title'),
                desc: i18n.t('blockConfigs.button.desc'),
                style: 'primary',
                // new_tab: false
            }
        },

        [blockTypes.separator]: {
            type: blockTypes.separator,
            toolIcon: iconSeparator,
            hidden: false,
            data: {
                size: 'sm',
                style: 'dotted'
            }
        },

        [blockTypes.image]: {
            type: blockTypes.image,
            toolIcon: iconImage,
            hidden: false,
            data: {
                image: defaultImagePaths.placeholderPath,
                align: 'center',
                click_action: 'none',
                url: '',
            }
        },

        [blockTypes.social]: {
            type: blockTypes.social,
            toolIcon: iconSocial,
            hidden: false,
            data: [
                {service: 'vk', link: 'username', icon: 'vk'},
                {service: 'youtube', link: 'https://youtube.com/username', icon: 'youtube'},
                {service: 'twitter', link: 'username', icon: 'twitter'}
            ]
        },

        [blockTypes.commercial]: {
            type: blockTypes.commercial,
            toolIcon: iconCommercial,
            hidden: false,
            data: {
                'itb': {
                    layout: 'itb',
                    blocks: [
                        {
                            type: blockTypes.image,
                            data: {
                                image: defaultImagePaths.placeholderPath,
                                align: 'center',
                                click_action: 'none',
                                url: ''
                            }
                        }, {
                            type: blockTypes.text,
                            data: {
                                text: i18n.t('blockConfigs.text')
                            }
                        }, {
                            type: blockTypes.button,
                            data: {
                                url: LANDING_URL,
                                title: i18n.t('blockConfigs.button.title'),
                                desc: '',
                                style: 'primary'
                            }
                        }
                    ]
                },
                'ttb': {
                    layout: 'ttb',
                    blocks: [
                        {
                            type: blockTypes.text,
                            data: {
                                text: i18n.t('blockConfigs.text')
                            }
                        }, {
                            type: blockTypes.timer,
                            data: {
                                date: moment(),
                                size: 'sm',
                                format: 'HH:MM:SS'
                            }
                        }, {
                            type: blockTypes.button,
                            data: {
                                url: LANDING_URL,
                                title: i18n.t('blockConfigs.button.title'),
                                desc: '',
                                style: 'primary'
                            }
                        }
                    ]
                },
                'tb': {
                    layout: 'tb',
                    blocks: [
                        {
                            type: blockTypes.text,
                            data: {
                                text: i18n.t('blockConfigs.text')
                            }
                        }, {
                            type: blockTypes.button,
                            data: {
                                url: LANDING_URL,
                                title: i18n.t('blockConfigs.button.title'),
                                desc: '',
                                style: 'primary'
                            }
                        }
                    ]
                }
            }
        },

        [blockTypes.gallery]: {
            type: blockTypes.gallery,
            toolIcon: iconGallery,
            hidden: false,
            data: {
                type: 'grid',
                images: Array.from({length: 4}, () => defaultImagePaths.placeholderPath),
                grid_columns: '2',
                // zoom_enable: true
            }
        },

        [blockTypes.timer]: {
            type: blockTypes.timer,
            toolIcon: iconTimer,
            hidden: false,
            data: {
                date: moment(),
                size: 'sm',
                format: 'HH:MM:SS'
            }
        },

        [blockTypes.video]: {
            type: blockTypes.video,
            toolIcon: iconVideo,
            hidden: false,
            data: {
                video: 'https://youtu.be/ScMzIvxBSi4'
            }
        },

        [blockTypes.spoiler]: {
            type: blockTypes.spoiler,
            toolIcon: iconSpoiler,
            hidden: false,
            data: {
                title: i18n.t('blockConfigs.spoiler.title'),
                hidden: i18n.t('blockConfigs.spoiler.hidden')
            }
        },

        [blockTypes.perfluence]: {
            type: blockTypes.perfluence,
            toolIcon: iconPerfluence,
            hidden: !(enabledEditors.find((item) => item === blockTypes.perfluence)),
            data: {
                blocks: [],
                type: "horizontal"
            }
        },

        [blockTypes.html]: {
            type: blockTypes.html,
            toolIcon: iconHTML,
            hidden: false,
            // disabled: (NODE_ENV !== MODE.DEV && BASE_URL !== 'https://my.pfmlink.tech' && !(enabledEditors.find((item) => item === blockTypes.html))),
            disabled: false,
            data: {
                code: i18n.t('blockConfigs.html')
            }
        }
    }
};

export const templateTypes = [
    {name: 'empty', icon: iconEmpty},
    {name: 'template', icon: iconTemplate},
    {name: 'wizard', icon: iconWizard},
    {name: 'import', icon: iconImport}
];

export const tabsSettings = [
    {index: 0, name: 'content'},
    {index: 1, name: 'design'},
    {index: 2, name: 'publish'}
];

export const regExp = {
    url: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
    password: /^((?!.*[\s])(?=.*[A-ZА-Я])(?=.*[a-zа-я])(?=.*\d).{8,})/,
    email: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
    urlOrEmpty: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)|(^$)/,
    pageUrl: /^[A-z0-9-_.]{3,255}$/,
    tel: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
    taplinkUrl: /^((https?:\/\/)?(www\.)?(taplink\.cc)\b([-a-zA-Z0-9()!@:%_+.~#?&\/=]*))+$/,
    googleAnalytics: /(^(G|UA|YT|MO)-[a-zA-Z0-9-]+$)|(^$)/,
    yandexMetrika: /(^[0-9]{8}$)|(^$)/,
    nickname: /^((?!https?:\/\/)[a-zA-Z0-9@_.])+$/
};