import {onRequestAction} from "../requestTracker/actions";
import {REQUESTED_STATS_LOAD, STATS_LOAD, STATS_SET_PAGE} from "../types";
import {APIGetPagesStats, APIGetPageStatsById} from "../../api";
import {alertError} from "../alert/actions";
import errorHandler from "../../helpers/errorHandler";

export const loadStats = () => {
    return async dispatch => {
        try {
            dispatch(onRequestAction(REQUESTED_STATS_LOAD, true));
            const response = await APIGetPagesStats();
            dispatch({type: STATS_LOAD, payload: response.data});
        } catch (error) {
            dispatch(alertError(errorHandler(error)));
        } finally {
            dispatch(onRequestAction(REQUESTED_STATS_LOAD, false));
        }
    };
};

export const loadStatsById = ({id, params, onError}) => {
    return async dispatch => {
        try {
            dispatch(onRequestAction(REQUESTED_STATS_LOAD, true));
            const response = await APIGetPageStatsById(id, {
                ...params,
                period: params.period !== 'interval' ? params.period : null
            });
            const data = params.from && params.to ?
                {
                    ...response.data,
                    from: params.from,
                    to: params.to
                }
                :
                response.data;

            dispatch({
                type: STATS_SET_PAGE,
                payload: {
                    id,
                    data,
                    period: params.period
                }
            });
        } catch (error) {
            dispatch(alertError(errorHandler(error)));
            onError();
        } finally {
            dispatch(onRequestAction(REQUESTED_STATS_LOAD, false));
        }
    };
};