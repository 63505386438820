import {LOGOUT, TEMPLATES_LOAD} from "../types";

const initialState = [];

const templates = (state = initialState, action) => {
    switch (action.type) {
        case TEMPLATES_LOAD:
            return action.payload;
        case LOGOUT:
            return initialState;
        default:
            return state
    }
};

export default templates;