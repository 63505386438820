import {useEffect, useMemo, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {appRoutes} from "../../helpers/defaults";
import {createChart, prepareChartProps} from "../../helpers/statsUtils";

const PagesStatsItem = ({page, history, t}) => {
    const ref = useRef(null);
    const [chart, setChart] = useState(null);

    const chartProps = useMemo(() =>
        prepareChartProps('week', 0, 0, page.by_day || {}, 0.1), [page.by_day]);

    useEffect(() => {
        if (ref && ref.current && !chart) {
            setChart(createChart(
                ref.current,
                'bar',
                chartProps.labels,
                [{
                    label: 'test',
                    data: chartProps.data,
                    backgroundColor: ['#FF6384'],
                    barThickness: 7,
                    maxBarThickness: 8
                }],
                {
                    scales: {
                        x: {display: false},
                        y: {display: false, min: 0, suggestedMax: 5}
                    },
                    plugins: false
                }
            ));
        }

        return () => {
            if (chart) chart.destroy();
        }
    }, [ref, chart]);

    return (
        <li
            className="pages-list__item pages-list__item--stats"
            onClick={() => {
                history.push(`${appRoutes.stats}/${page.page_id}`)
            }}
        >
            <div className="pages-list__item__title">
                <span>{page.title}</span>
            </div>
            <div className="pages-list__item__stats">
                <div className="pages-list__item__stats__chart">
                    <canvas
                        id={page.page_id}
                        style={{width: '100%', height: '100%'}}
                        ref={ref}
                        aria-label={t('pages.stats.weekChart')}
                        role="img"
                    />
                </div>
                <div className="pages-list__item__stats__item">
                    <span>{t('common.views')}</span>
                    <span>{page.views}</span>
                </div>
                <div className="pages-list__item__stats__item">
                    <span>{t('title.visitors')}</span>
                    <span>{page.visitors}</span>
                </div>
            </div>
        </li>
    );
};

const PagesStatsList = ({pages}) => {
    const history = useHistory();
    const {t} = useTranslation();

    if (pages && pages.length) {
        return (
            <ul className="pages-list">
                {pages.map((page, index) => <PagesStatsItem key={index} page={page} history={history} t={t}/>)}
            </ul>
        );
    }

    return <span>{t('pages.stats.noData')}</span>;
};

export default PagesStatsList;