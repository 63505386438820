import {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {Button, Form, Spinner} from "react-bootstrap";
import {Trans, useTranslation} from "react-i18next";

import {APISocialProviders} from "../../api";

import {clearCheckFieldsState} from "../../redux/requestTracker/actions";
import {alertError} from "../../redux/alert/actions";
import {requestTrackerSelector, userSelector} from "../../redux/selectors";

import {useForm} from "../../hooks";

import errorHandler from "../../helpers/errorHandler";
import {appRoutes, regExp} from "../../helpers/defaults";
import {checkEmail, checkEmailDebounced} from "../../helpers/checkEmail";

import {sendAnalytics} from "../../Analytics";

import DefaultLayout from "../../layouts/DefaultLayout";

import {FieldWrapper, PasswordFieldWrapper} from "../../components/FieldWrappers";

import iconGoogle from "./media/icon-google.svg";
import iconFacebook from "./media/icon-facebook.svg";
import iconVk from "./media/icon-vk.svg";
import privacyPolicy from "./files/gdpr_policy_PFM_LINK.pdf"
import rules from './files/rules_PFM_LINK.pdf';

const AuthLayout = ({onSubmitAction, forRegister}) => {
    const dispatch = useDispatch();
    const requestTracker = useSelector(requestTrackerSelector);
    const user = useSelector(userSelector);
    const history = useHistory();
    const [isCheckRequesting, setCheckRequesting] = useState(false);
    const {t} = useTranslation();

    const form = useForm({
        values: useMemo(() => {
            return {
                email: '',
                name: '',
                password: ''
            }
        }, []),
        validation: {
            email: {
                required: true,
                regExp: regExp.email,
                error: t('validation.default'),
                async: {
                    callback: ({value, name, errors, setErrors}) => {
                        if (forRegister)
                            return checkEmail({
                                value,
                                name,
                                errors,
                                setErrors,
                                setCheckRequesting,
                                errorMsg: t('validation.emailTaken')
                            });
                        return true;
                    },
                    error: t('validation.emailTaken')
                }
            },
            name: {
                callback: () => !(forRegister && !form.state.name),
                error: t('validation.default'),
            },
            password: {
                required: true,
                regExp: regExp.password,
                error: t('validation.default')
            }
        },
        onSubmit: () => {
            dispatch(onSubmitAction({
                email: form.state.email,
                name: form.state.name,
                password: form.state.password,
                history
            }));
        },
        onKeyUp: (e, errors, setErrors) => {
            if (forRegister) {
                const {value, name} = e.target;
                checkEmailDebounced({value, name, errorMsg: t('validation.emailTaken'), errors, setErrors, setCheckRequesting});
            }
        }
    });

    const [socialProviders, setSocialProviders] = useState({
        google: '',
        vkontakte: ''
    });

    useEffect(() => {
        if (!user) {
            APISocialProviders()
                .then(response => {
                    const tmp = response.data.reduce((a, e) => {
                        a[e.name] = e.url.url;
                        return a;
                    }, {});
                    setSocialProviders(state => ({...state, ...tmp}));
                })
                .catch(error => dispatch(alertError(errorHandler(error))));
        }
    }, [dispatch, user]);

    useEffect(() => {
        return () => {
            dispatch(clearCheckFieldsState());
            setSocialProviders({
                google: '',
                vkontakte: ''
            });
        };
    }, [dispatch]);

    return (
        <DefaultLayout title={forRegister ? t('title.registration') : t('title.authorization')}>
            <div className="default__socials-wrapper">
                <div className="default__socials-title">
                    <span>{t('pages.auth.withSocials')}</span>
                </div>
                <div className="default__socials">
                    {/*<a href={socialProviders.facebook}*/}
                    {/*   className={socialProviders.facebook ? "default__social-link" : "default__social-link default__social-link--disabled"}*/}
                    {/*   onClick={(e) => {*/}
                    {/*       if (!socialProviders.facebook) e.preventDefault()*/}
                    {/*   }}*/}
                    {/*>*/}
                    {/*    <img src={iconFacebook} alt="Facebook"/>*/}
                    {/*</a>*/}
                    <a href={socialProviders.google}
                       className={socialProviders.google ? "default__social-link mr-2" : "default__social-link default__social-link--disabled mr-2"}
                       onClick={(e) => {
                           if (!socialProviders.google) e.preventDefault()
                       }}
                    >
                        <img src={iconGoogle} alt="Google"/>
                    </a>
                    <a href={socialProviders.vkontakte}
                       className={socialProviders.vkontakte ? "default__social-link" : "default__social-link default__social-link--disabled"}
                       onClick={(e) => {
                           if (!socialProviders.vkontakte) e.preventDefault()
                       }}
                    >
                        <img src={iconVk} alt="VK"/>
                    </a>
                </div>
            </div>
            <div className="default__divider">{t('common.or')}</div>
            <div className="default__form-wrapper">
                <Form noValidate className="default__form" onSubmit={form.handleSubmit}>
                    <FieldWrapper
                        type="email"
                        name="email"
                        value={form.state.email}
                        label={t('label.email')}
                        placeholder={t('placeholder.email')}
                        validation={form.errors.email}
                        onKeyUp={form.handleKeyUp}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                    />
                    {forRegister &&
                    <>
                        <FieldWrapper
                            type="text"
                            name="name"
                            value={form.state.name}
                            label={t('label.name')}
                            placeholder={t('placeholder.name')}
                            validation={form.errors.name}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                        />
                    </>
                    }
                    <PasswordFieldWrapper
                        className="mb-0"
                        name="password"
                        value={form.state.password}
                        label={t('label.password')}
                        validation={form.errors.password}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                    />
                    {!forRegister &&
                    <div className="default__form__reset-link">
                        <Link to={appRoutes.sendReset} className="pfm-anchor">{t('pages.auth.forgotPassword')}</Link>
                    </div>
                    }
                    {forRegister &&
                    <div className="default__form-register-help">
                        <Form.Text>
                            {t('desc.password')}
                        </Form.Text>
                        {/*<Form.Text>*/}
                        {/*    <Trans i18nKey="pages.auth.agreement">*/}
                        {/*        Создавая аккаунт, я соглашаюсь с <a href={rules} className="pfm-anchor" target="_blank"  >правилами</a> и даю согласие <a href={privacyPolicy} className="pfm-anchor" target="_blank"> на обработку персональных данных</a>.*/}
                        {/*    </Trans>*/}
                        {/*</Form.Text>*/}
                    </div>
                    }
                    <Button
                        type="submit" className="primary-btn default__form__submit"
                        onClick={() => forRegister && sendAnalytics('registration')}
                        disabled={
                            requestTracker.auth ||
                            requestTracker.login ||
                            requestTracker.register ||
                            isCheckRequesting
                        }>
                        {requestTracker.auth ||
                        requestTracker.login ||
                        requestTracker.register ||
                        isCheckRequesting ?
                            <Spinner animation={"border"} size={"sm"}/>
                            :
                            forRegister ? t('button.signUp') : t('button.signIn')
                        }
                    </Button>
                </Form>
            </div>
            <div className="default__bottom">
                {forRegister ?
                    <Trans i18nKey="pages.auth.haveAccount">
                        <span>Есть аккаунт? <Link className="pfm-anchor" to={appRoutes.login}>Войдите</Link></span>
                    </Trans>
                    :
                    <Trans i18nKey="pages.auth.noAccount">
                        <span>Нет аккаунта? <Link className="pfm-anchor" to={appRoutes.register}>Зарегистрируйтесь</Link></span>
                    </Trans>
                }
            </div>
        </DefaultLayout>
    )
};

export default AuthLayout;
