import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Form, Spinner} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";

import {sendReset} from "../../redux/user/actions";
import {clearSendReset} from "../../redux/requestTracker/actions";
import {requestTrackerSelector} from "../../redux/selectors";

import {useForm} from "../../hooks";

import {appRoutes, regExp} from "../../helpers/defaults";

import DefaultLayout from "../../layouts/DefaultLayout";

import {FieldWrapper} from "../../components/FieldWrappers";

const SendReset = () => {
    const dispatch = useDispatch();
    const requestTracker = useSelector(requestTrackerSelector);
    const {t} = useTranslation();
    const form = useForm({
        values: useMemo(() => {
            return {
                email: ''
            }
        }, []),
        validation: {
            email: {
                required: true,
                regExp: regExp.email,
                error: t('validation.default')
            }
        },
        onSubmit: () => {
            dispatch(sendReset(form.state.email));
        }
    });

    useEffect(() => {
        return () => {
            dispatch(clearSendReset());
        };
    }, [dispatch]);

    return (
        <DefaultLayout title={t('title.passwordRecovery')}>
            {requestTracker.sendReset.receivedSuccess ?
                <div className="send-reset__success">
                    <Trans i18nKey="pages.reset.text">
                        Мы отправили вам на почту инструкции по восстановлению пароля. <br/>Чтобы восстановить доступ к аккаунту, пройдите по ссылке из письма. Если этого письма нет во «Входящих», пожалуйста, проверьте «Спам».
                    </Trans>
                </div>
                :
                <div className="default__form-wrapper">
                    <Form noValidate className="default__form" onSubmit={form.handleSubmit}>
                        <FieldWrapper
                            type="email"
                            name="email"
                            label={t('label.email')}
                            placeholder={t('placeholder.email')}
                            value={form.state.email}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            validation={form.errors.email}
                        />
                        <Button
                            type="submit"
                            className="primary-btn default__form__submit"
                            disabled={requestTracker.sendReset.isRequesting}
                        >
                            {requestTracker.sendReset.isRequesting ?
                                <Spinner animation={"border"} size={"sm"}/>
                                :
                                t('button.resetPassword')
                            }
                        </Button>
                    </Form>
                </div>
            }
            <div className="default__bottom">
                <Trans i18nKey="pages.reset.links">
                    <span><Link className="pfm-anchor" to={appRoutes.login}>Войти</Link> или <Link className="pfm-anchor" to={appRoutes.register}>Зарегистрироваться</Link></span>
                </Trans>
            </div>
        </DefaultLayout>
    )
};

export default SendReset;