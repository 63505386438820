import {setSubVariable} from "../../components/EditorDesignForm/helpers";

import {
    USER_SETTINGS,
    LOAD_EDITABLE_STYLE,
    DESIGN_MODE_CLEAR
} from "../types";

const initialState = {
    settingsUsers:{
        editableTheme: "",
        configTheme: {}
    },
    style_config: []
}

const editorDesign = (state = initialState, action) => {
   switch (action.type) {
       case USER_SETTINGS:
           const subConfig = setSubVariable(action.payload.key, action.payload.value);
           return {
               ...state,
               // style_config: {
               //     ...state.style_config,
               //     ...subConfig,
               //     [action.payload.key]: action.payload.value
               // }
               settingsUsers: {
                    ...state.settingsUsers,
                    configTheme: {
                        ...state.settingsUsers.configTheme,
                        ...subConfig,
                        [action.payload.key]: action.payload.value
                    }
               }
           }
       case LOAD_EDITABLE_STYLE:
           const page = action.payload;

           return {
               ...state,
               settingsUsers: {
                   configTheme: {},
                   editableTheme: page.editable_style,
               },
               style_config: page.style_config
           }
       case DESIGN_MODE_CLEAR:
           return {
               ...state,
               settingsUsers: {
                   ...state.settingsUsers,
                   configTheme: {}
               }
           }
       default:
           return state
   }
};

export default editorDesign;