import {
    LOGOUT,
    WIZARD_GET_FROM_LS,
    WIZARD_INIT,
    WIZARD_POPUP,
    WIZARD_RETRIEVE,
    WIZARD_SET_COMPLETED,
    WIZARD_SET_CURRENT_STEP,
    WIZARD_SET_STEP,
    WIZARD_SET_TO_LS
} from "../types";
import {wizardReplacer} from "../../helpers/wizardUtils";

const initialState = {
    steps: [],
    currentStep: 0,
    popup: false,
    completed: false
};

const initialStateFromLS = JSON.parse(localStorage.getItem('wizard'));

const wizard = (state = initialStateFromLS || initialState, action) => {
    switch (action.type) {
        case WIZARD_INIT:
            return {
                ...state,
                steps: action.payload,
            };
        case WIZARD_SET_STEP:
            const newSteps = [...state.steps];
            newSteps[action.payload.index] = action.payload.step;

            return {
                ...state,
                steps: newSteps
            };
        case WIZARD_SET_CURRENT_STEP:
            return {
                ...state,
                currentStep: action.payload
            };
        case WIZARD_POPUP:
            return {
                ...state,
                popup: action.payload
            };
        case WIZARD_SET_TO_LS:
            if (!state.steps.length || state.completed) {
                localStorage.removeItem('wizard');
                return initialState;
            } else {
                localStorage.setItem('wizard', JSON.stringify(state, wizardReplacer));
                return state;
            }
        case WIZARD_GET_FROM_LS:
            return action.payload;
        case WIZARD_RETRIEVE: {
            const newSteps = state.steps.map((item, index) => {
                return item.map(field => {
                    if (action.payload[index]) {
                        for (let key in action.payload[index]) {
                            if (field.name === key) {
                                return {
                                    ...field,
                                    value: action.payload[index][key]
                                }
                            }
                        }
                    }
                    return field;
                });
            });
            const currentStep = action.payload.length - 1;
            return {
                ...state,
                steps: newSteps,
                currentStep
            }
        }
        case WIZARD_SET_COMPLETED:
            return {
                ...state,
                completed: true
            }
        case LOGOUT:
            localStorage.removeItem('wizard');
            return initialState;
        default:
            return state;
    }
};

export default wizard;