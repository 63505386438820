import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {editorDesignSelector, editorSelector} from "../../redux/selectors";

import {useTranslation} from "react-i18next";

import {Button} from "react-bootstrap";

import {FieldFontSizeWrapper, SelectFontWrapper, SelectFieldWrapper} from "../FieldWrappers";
import {PopoverPicker} from "../PopoverPicker";

import {
    optionsFonts,
    changedVariable,
} from "./helpers";
import {clearDesignMode, loadEditableStyle, saveStyleBlocks} from "../../redux/editorDesign/actions";
import {clearEditMode} from "../../redux/editor/actions";


const EditorDesignForm = ({page, block,setSubmitTrigger,submitTrigger}) => {
    const submitRef = useRef(null);

    const dispatch = useDispatch();

    const {t} = useTranslation();

    const stylePage = useSelector(editorSelector).modifiedStyle;
    const designState = useSelector(editorDesignSelector).settingsUsers;

    const [pageOptions, setPageOptions ] = useState('Color');

    const optionsPage = [
        {value: 'Color', text: `${t('label.color')}`},
        {value: 'Gradient', text: `${t('label.gradient')}`}
    ];

    useEffect(() => {
        if (submitTrigger && submitRef && submitRef.current) {
            submitRef.current.click();
            setSubmitTrigger(false);
        }
    }, [submitTrigger, setSubmitTrigger]);

    useEffect(()=> {
        if(page)
            dispatch(loadEditableStyle(page))
    },[page]);

    return (
        <div className={'editor__sidebar__design'}>
            {!block ?
                <div className={'design design__editor'}>
                    <SelectFieldWrapper
                        className={'form-group--select select__bg-color'}
                        label={t('label.pageBg')}
                        value={pageOptions}
                        options={optionsPage}
                        name={pageOptions}
                        onChange={(e) => {
                            setPageOptions(e.value);
                        }}
                    />
                    <PopoverPicker
                        config={'--page-bg-color'}
                        stylePage={stylePage}
                        params={pageOptions}
                        label={pageOptions}
                    />
                </div>
                :
                block.type === 'header' ?
                    <div className={'design design__editor mb-3'}>
                        <SelectFontWrapper
                            className={'form-group--select'}
                            label={t('label.fontFamily')}
                            page={stylePage}
                            variable={'--font-family'}
                            options={optionsFonts}

                        />
                        <PopoverPicker
                            config={'--avatar-color'}
                            stylePage={stylePage}
                            params={t('label.color')}
                            label={t('label.textColor')}
                            block={block}
                        />
                    </div>
                    :
                block.type === 'text' ?
                    <div className={'design design__editor'}>
                        <h3 className={'design__editor--subtitle'}>{t('desc.title')}</h3>
                        <SelectFontWrapper
                            className={'form-group--select'}
                            label={t('label.font')}
                            page={stylePage}
                            variable={'--title-font-family'}
                            options={optionsFonts}
                        />
                        <div className={'d-flex'}>
                            <PopoverPicker
                                config={'--title-color'}
                                stylePage={stylePage}
                                params={t('label.color')}
                                label={t('label.color')}
                                block={block}
                            />
                            <div  className={'form-size'}>
                                <FieldFontSizeWrapper
                                    label={t('label.fontSize')}
                                    type='number'
                                    page={stylePage}
                                    variable={'--title-font-size'}
                                    limiter={{min: 14, max: 45}}
                                    onChange={changedVariable}
                                />
                            </div>
                        </div>
                        <h3 className={'design__editor--subtitle'}>{t('desc.subtitle')}</h3>
                        <SelectFontWrapper
                            className={'form-group--select'}
                            label={t('label.font')}
                            page={stylePage}
                            variable={'--subtitle-font-family'}
                            options={optionsFonts}
                        />
                        <div className={'d-flex'}>
                            <PopoverPicker
                                config={'--subtitle-color'}
                                stylePage={stylePage}
                                params={t('label.color')}
                                label={t('label.color')}
                                block={block}
                            />
                            <div  className={'form-size'}>
                                <FieldFontSizeWrapper
                                    label={t('label.fontSize')}
                                    type='number'
                                    page={stylePage}
                                    variable={'--subtitle-font-size'}
                                    limiter={{min: 14, max: 45}}
                                    onChange={changedVariable}
                                />
                            </div>
                        </div>
                        <h3 className={'design__editor--subtitle'}>{t('desc.text')}</h3>
                        <SelectFontWrapper
                            className={'form-group--select'}
                            label={t('label.font')}
                            page={stylePage}
                            variable={'--text-font-family'}
                            options={optionsFonts}
                        />
                        <div className={'d-flex'}>
                            <PopoverPicker
                                config={'--color'}
                                stylePage={stylePage}
                                params={t('label.color')}
                                label={t('label.color')}
                                block={block}
                            />
                            <div  className={'form-size'}>
                                <FieldFontSizeWrapper
                                    label={t('label.fontSize')}
                                    type='number'
                                    page={stylePage}
                                    variable={'--text-font-size'}
                                    limiter={{min: 12, max: 32}}
                                    onChange={changedVariable}
                                />
                            </div>
                        </div>
                    </div>
                    :
                block.type === 'button' ?
                    <div className={'design design__editor'}>
                        <SelectFontWrapper
                            className={'form-group--select'}
                            label={t('label.font')}
                            page={stylePage}
                            variable={'--button-font-family'}
                            options={optionsFonts}
                        />
                        <div className={'d-flex'}>
                            <PopoverPicker
                                config={'--button-desc-color'}
                                stylePage={stylePage}
                                params={t('label.color')}
                                label={t('label.descColor')}
                                block={block}
                            />
                            <div  className={'form-size'}>
                                <FieldFontSizeWrapper
                                    label={t('label.fontSize')}
                                    type='number'
                                    page={stylePage}
                                    variable={'--button-desc-font-size'}
                                    limiter={{min: 10, max: 24}}
                                    onChange={changedVariable}
                                />
                            </div>
                        </div>
                        {block.data.style === 'primary' ?
                            <div>
                                <div className={'d-flex'}>
                                    <PopoverPicker
                                        config={'--button-color'}
                                        stylePage={stylePage}
                                        params={t('label.color')}
                                        label={t('label.textColor')}
                                        block={block}
                                    />
                                    <div  className={'form-size'}>
                                        <FieldFontSizeWrapper
                                            label={t('label.fontSize')}
                                            type='number'
                                            page={stylePage}
                                            variable={'--button-font-size'}
                                            limiter={{min: 14, max: 45}}
                                            onChange={changedVariable}
                                        />
                                    </div>
                                </div>
                                <div className={'d-flex'}>
                                    <PopoverPicker
                                        config={'--button-bg-color'}
                                        stylePage={stylePage}
                                        params={t('label.color')}
                                        label={t('label.btnColor')}
                                        block={block}
                                    />
                                    <div  className={'form-size'}>
                                        <FieldFontSizeWrapper
                                            label={t('label.rounding')}
                                            type='number'
                                            page={stylePage}
                                            variable={'--button-border-radius'}
                                            limiter={{min: 0, max: 32}}
                                            onChange={changedVariable}
                                        />
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                                <div className={'d-flex'}>
                                    <PopoverPicker
                                        config={'--button-color--secondary'}
                                        stylePage={stylePage}
                                        params={t('label.color')}
                                        label={t('label.textColor')}
                                        block={block}
                                    />
                                    <div  className={'form-size'}>
                                        <FieldFontSizeWrapper
                                            label={t('label.fontSize')}
                                            type='number'
                                            page={stylePage}
                                            variable={'--button-font-size--secondary'}
                                            limiter={{min: 14, max: 45}}
                                            onChange={changedVariable}
                                        />
                                    </div>
                                </div>
                                <div className={'d-flex'}>
                                    <PopoverPicker
                                        config={'--button-bg-color--secondary'}
                                        stylePage={stylePage}
                                        params={t('label.color')}
                                        label={t('label.btnColor')}
                                        block={block}
                                    />
                                    <div  className={'form-size'}>
                                        <FieldFontSizeWrapper
                                            label={t('label.rounding')}
                                            type='number'
                                            page={stylePage}
                                            variable={'--button-border-radius--secondary'}
                                            limiter={{min: 0, max: 32}}
                                            onChange={changedVariable}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    :
                block.type === 'image' ?
                    <div  className={'form-size ml-0'}>
                        <FieldFontSizeWrapper
                            label={t('label.rounding')}
                            type='number'
                            page={stylePage}
                            variable={'--image-border-radius'}
                            limiter={{min: 0, max: 32}}
                            onChange={changedVariable}
                        />
                    </div>
                    :
                block.type === 'separator' ?
                    <div className='design design__editor mb-3'>
                        <PopoverPicker
                            config={'--separator-border-color'}
                            stylePage={stylePage}
                            params={t('label.color')}
                            label={t('label.separatorColor')}
                            block={block}
                        />
                    </div>
                    :
                block.type === 'social' ?
                    <div className='design design__editor'>
                        <div className={'d-flex'}>
                            <PopoverPicker
                                config={'--socials-bg-color'}
                                stylePage={stylePage}
                                params={t('label.color')}
                                label={t('label.btnColor')}
                                block={block}
                            />
                            <div  className={'form-size'}>
                                <FieldFontSizeWrapper
                                    label={t('label.rounding')}
                                    type='number'
                                    page={stylePage}
                                    variable={'--socials-border-radius'}
                                    limiter={{min: 0, max: 32}}
                                    onChange={changedVariable}
                                />
                            </div>
                        </div>
                        <div  className={'form-size ml-0'}>
                            <FieldFontSizeWrapper
                                label={t('label.btnSize')}
                                type='number'
                                page={stylePage}
                                variable={'--socials-size'}
                                limiter={{min: 32, max: 64}}
                                onChange={changedVariable}
                            />
                        </div>
                        <div className={'d-flex'}>
                            <PopoverPicker
                                config={'--socials-fill'}
                                stylePage={stylePage}
                                params={t('label.color')}
                                label={t('label.iconColor')}
                                block={block}
                            />
                            <div  className={'form-size'}>
                                <FieldFontSizeWrapper
                                    label={t('label.iconSize')}
                                    type='number'
                                    page={stylePage}
                                    variable={'--socials-icon-size'}
                                    limiter={{min: 16, max: 32}}
                                    onChange={changedVariable}
                                />
                            </div>
                        </div>
                    </div>
                    :
                block.type === 'gallery' ?
                    <div className={'design design__editor'}>
                        <div  className={'form-size ml-0'} onClick={()=>console.log(block.data.type)}>
                            <FieldFontSizeWrapper
                                label={t('label.rounding')}
                                type='number'
                                page={stylePage}
                                variable={'--gallery-image-border-radius'}
                                limiter={{min: 0, max: 32}}
                                onChange={changedVariable}
                            />
                        </div>
                        {block.data.type === 'carousel' &&
                        <div className=''>
                            <div className='mb-3'>
                                <PopoverPicker
                                    config={'--gallery-pagination-bullet-color'}
                                    stylePage={stylePage}
                                    params={t('label.color')}
                                    label={t('label.inactiveBtnColor')}
                                    block={block}
                                />
                            </div>
                            <div className='mb-3'>
                                <PopoverPicker
                                    config={'--gallery-pagination-bullet-color--active'}
                                    stylePage={stylePage}
                                    params={t('label.color')}
                                    label={t('label.activeBtnColor')}
                                    block={block}
                                />
                            </div>
                        </div>
                        }
                    </div>
                    :
                block.type === 'timer' ?
                    <div className='design design__editor'>
                        <SelectFontWrapper
                            className={'form-group--select'}
                            label={t('label.font')}
                            page={stylePage}
                            variable={'--timer-font-family'}
                            options={optionsFonts}
                        />
                        <div className={'d-flex'}>
                            <PopoverPicker
                                config={'--timer-color'}
                                stylePage={stylePage}
                                params={t('label.color')}
                                label={t('label.color')}
                                block={block}
                            />
                            <div  className={'form-size'}>
                                <FieldFontSizeWrapper
                                    label={t('label.fontSize')}
                                    type='number'
                                    page={stylePage}
                                    variable={`--timer-font-size${block.data.size === 'sm' ? '' :`--${block.data.size}`}`}
                                    limiter={{min: 14, max: 45}}
                                    onChange={changedVariable}
                                />
                            </div>
                        </div>
                    </div>
                    :
                block.type === 'spoiler' ?
                    <div className='design design__editor'>
                        <div>
                            <h3 className={'design__editor--subtitle'}>{t('desc.title')}</h3>
                            <div className={'mb-3'}>
                                <PopoverPicker
                                    config={'--spoiler-bg-color'}
                                    stylePage={stylePage}
                                    params={t('label.color')}
                                    label={t('label.bgColor')}
                                    block={block}
                                />
                            </div>
                            <SelectFontWrapper
                                className={'form-group--select'}
                                label={t('label.font')}
                                page={stylePage}
                                variable={'--spoiler-title-font-family'}
                                options={optionsFonts}
                            />
                            <div className={'d-flex'}>
                                <PopoverPicker
                                    config={'--spoiler-title-color'}
                                    stylePage={stylePage}
                                    params={t('label.color')}
                                    label={t('label.color')}
                                    block={block}
                                />
                                <div  className={'form-size'}>
                                    <FieldFontSizeWrapper
                                        label={t('label.fontSize')}
                                        type='number'
                                        page={stylePage}
                                        variable={'--spoiler-title-font-size'}
                                        limiter={{min: 14, max: 45}}
                                        onChange={changedVariable}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <h3 className={'design__editor--subtitle'}>{t('desc.hiddenText')}</h3>
                            <SelectFontWrapper
                                className={'form-group--select'}
                                label={t('label.font')}
                                page={stylePage}
                                variable={'--spoiler-hidden-font-family'}
                                options={optionsFonts}
                            />
                            <div className={'d-flex'}>
                                <PopoverPicker
                                    config={'--spoiler-hidden-color'}
                                    stylePage={stylePage}
                                    params={t('label.color')}
                                    label={t('label.color')}
                                    block={block}
                                />
                                <div  className={'form-size'}>
                                    <FieldFontSizeWrapper
                                        label={t('label.fontSize')}
                                        type='number'
                                        page={stylePage}
                                        variable={'--spoiler-hidden-font-size'}
                                        limiter={{min: 14, max: 45}}
                                        onChange={changedVariable}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                block.type === 'commercial' &&
                    <div className='design design__editor'>
                        <div className={'d-flex'}>
                            <PopoverPicker
                                config={'--commercial-bg-color'}
                                stylePage={stylePage}
                                params={t('label.color')}
                                label={t('label.bgColor')}
                                block={block}
                            />
                            <div  className={'form-size'}>
                                <FieldFontSizeWrapper
                                    label={t('label.rounding')}
                                    type='number'
                                    page={stylePage}
                                    variable={'--commercial-border-radius'}
                                    limiter={{min: 0, max: 32}}
                                    onChange={changedVariable}
                                />
                            </div>
                        </div>
                    </div>

            }

            <div className={`editor__form__buttons-wrapper ${!block ? 'mt-4' : ''}`}>
                <Button
                    type="submit"
                    size="sm"
                    className="secondary-btn blue-btn"
                    // disabled={!modified}
                    ref={submitRef}
                    onClick={()=> {
                        dispatch(clearDesignMode());
                        dispatch(clearEditMode());
                        dispatch(saveStyleBlocks({pageId: page.id, data: designState, page: page}));
                        setSubmitTrigger(false)
                    }}
                >
                    {t('button.save')}
                </Button>
                <Button
                    type="reset"
                    variant="outline-light"
                    size="sm"
                    className="secondary-btn outline-light-btn"
                    onClick={() => {
                        dispatch(clearDesignMode());
                        dispatch(clearEditMode());
                    }}
                >
                    {t('button.cancel')}
                </Button>
            </div>


        </div>
    )

}

export default EditorDesignForm;