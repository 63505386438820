import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Spinner} from "react-bootstrap";
import parser from "html-react-parser";

import {APIGetNewsById} from "../../api";

import {onRequestAction} from "../../redux/requestTracker/actions";
import {REQUESTED_NEWS_LOAD} from "../../redux/types";

import {parseNewsDate} from "../../helpers/dateFormattingUtils";
import {appRoutes} from "../../helpers/defaults";

const NewsPageItem = ({id, setNotFound, t}) => {
    const [news, setNews] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (id) {
            dispatch(onRequestAction(REQUESTED_NEWS_LOAD, true));
            APIGetNewsById(id)
                .then(response => {
                    setNews(response.data);
                })
                .catch(() => {
                    setNotFound(true);
                })
                .finally(() => {
                    dispatch(onRequestAction(REQUESTED_NEWS_LOAD, false));
                });
        }
    }, [id, dispatch, setNotFound]);

    return (
        <div className="news__item">
            <div className="news__item__back">
                <Link to={appRoutes.news} className="news__item__back__link">&lt;- <span>{t('pages.news.back')}</span></Link>
            </div>
            {news ?
                <>
                    <div className="news__item__date">
                        <span className="news-list__item__date">{parseNewsDate(news.created_at)}</span>
                    </div>
                    <div className="news__item__title">
                        {news.title}
                    </div>
                    <div className="news__item__text">
                        {parser(news.text)}
                    </div>
                </>
                :
                <Spinner animation="border"/>
            }
        </div>
    );
};

export default NewsPageItem;