import {
    LOGOUT,
    RECEIVED_CHECK_EMAIL,
    RECEIVED_RESET,
    RECEIVED_SEND_RESET,
    REQUESTED_AUTH,
    REQUESTED_CHECK_EMAIL,
    REQUESTED_LOGIN,
    REQUESTED_LOGOUT,
    REQUESTED_NEWS_LOAD,
    REQUESTED_NEWS_PAGINATE,
    REQUESTED_PAGE_CHECK,
    REQUESTED_PAGE_PUBLISH,
    REQUESTED_PAGES_CREATE,
    REQUESTED_PAGES_DELETE,
    REQUESTED_PAGES_LOAD,
    REQUESTED_PAGES_SAVE,
    REQUESTED_REGISTER,
    REQUESTED_RESET,
    REQUESTED_SEND_RESET,
    REQUESTED_STATS_LOAD,
    REQUESTED_TEMPLATES,
    REQUESTED_UPLOAD,
    REQUESTED_USER_UPDATE,
    REQUESTED_WIZARD_UPDATE
} from "../types";

const initialState = {
    login: false,
    auth: false,
    logout: false,
    register: false,
    editor: {
        loadPages: false,
        createPage: false,
        savePage: false,
        publishPage: false,
        checkPagePath: false,
        deletePage: false
    },
    checkEmail: {
        receivedError: false,
        isRequesting: false
    },
    sendReset: {
        receivedSuccess: false,
        isRequesting: false
    },
    reset: {
        receivedSuccess: false,
        isRequesting: false
    },
    upload: false,
    updateUser: false,
    wizard: false,
    templates: false,
    loadNews: false,
    paginateNews: false,
    loadStats: false
};

const requestTracker = (state = initialState, action) => {
    switch(action.type) {
        case REQUESTED_LOGIN:
            return {
                ...state,
                login: action.payload
            };
        case REQUESTED_AUTH:
            return {
                ...state,
                auth: action.payload
            };
        case REQUESTED_LOGOUT:
            return {
                ...state,
                logout: action.payload
            };
        case REQUESTED_REGISTER:
            return {
                ...state,
                register: action.payload
            };
        case REQUESTED_CHECK_EMAIL:
            return {
                ...state,
                checkEmail: {
                    ...state.checkEmail,
                    isRequesting: action.payload
                }
            };
        case RECEIVED_CHECK_EMAIL:
            return {
                ...state,
                checkEmail: {
                    ...state.checkEmail,
                    receivedError: action.payload
                }
            };
        case REQUESTED_SEND_RESET:
            return {
                ...state,
                sendReset: {
                    ...state.sendReset,
                    isRequesting: action.payload
                }
            };
        case REQUESTED_RESET:
            return {
                ...state,
                reset: {
                    ...state.reset,
                    isRequesting: action.payload
                }
            };
        case RECEIVED_SEND_RESET:
            return {
                ...state,
                sendReset: {
                    ...state.sendReset,
                    receivedSuccess: action.payload
                }
            };
        case RECEIVED_RESET:
            return {
                ...state,
                reset: {
                    ...state.reset,
                    receivedSuccess: action.payload
                }
            };
        case REQUESTED_PAGES_LOAD:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    loadPages: action.payload
                }
            };
        case REQUESTED_PAGES_CREATE:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    createPage: action.payload
                }
            };
        case REQUESTED_PAGES_SAVE:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    savePage: action.payload
                }
            };
        case REQUESTED_PAGE_PUBLISH:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    publishPage: action.payload
                }
            };
        case REQUESTED_PAGE_CHECK:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    checkPagePath: action.payload
                }
            };
        case REQUESTED_PAGES_DELETE:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    deletePage: action.payload
                }
            };
        case REQUESTED_UPLOAD:
            return {
                ...state,
                upload: action.payload
            };
        case REQUESTED_USER_UPDATE:
            return {
                ...state,
                updateUser: action.payload
            };
        case REQUESTED_WIZARD_UPDATE:
            return {
                ...state,
                wizard: action.payload
            };
        case REQUESTED_TEMPLATES:
            return {
                ...state,
                templates: action.payload
            };
        case REQUESTED_NEWS_LOAD:
            return {
                ...state,
                loadNews: action.payload
            };
        case REQUESTED_NEWS_PAGINATE:
            return {
                ...state,
                paginateNews: action.payload
            }
        case REQUESTED_STATS_LOAD:
            return {
                ...state,
                loadStats: action.payload
            }
        case LOGOUT:
            return {
                ...state,
                checkEmail: initialState.checkEmail,
                sendReset: initialState.sendReset,
                reset: initialState.reset
            };
        default:
            return state;
    }
};

export default requestTracker;