import {Spinner} from "react-bootstrap";

const PageLoader = () => {
    return (
        <div className="page-loader">
            <Spinner animation="border"/>
        </div>
    );
};

export default PageLoader;