import {ALERT_HIDE, ALERT_SHOW} from "../types";

const initialState = {
    type: null,
    message: null
};

const alert = (state = initialState, action) => {
    switch (action.type) {
        case ALERT_SHOW:
            return {
                type: action.payload.type,
                message: action.payload.message
            };
        case ALERT_HIDE:
            return initialState;
        default:
            return state;
    }
};

export default alert;