import {useEffect, useMemo, useState} from "react";
import {Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {APIUpload} from "../../api";

import {REQUESTED_UPLOAD} from "../../redux/types";
import {onRequestAction} from "../../redux/requestTracker/actions";
import {alertError} from "../../redux/alert/actions";
import {editProfile} from "../../redux/user/actions";
import {requestTrackerSelector, userSelector} from "../../redux/selectors";

import {useForm} from "../../hooks";

import errorHandler from "../../helpers/errorHandler";
import {regExp} from "../../helpers/defaults";
import {prepareImageURL} from "../../helpers/imageUtils";
import {AVATAR_SIZES} from "../../helpers/const";

import AppLayout from "../../layouts/AppLayout";

import {FieldWrapper, FileFieldWrapper, PasswordFieldWrapper} from "../../components/FieldWrappers";

import avatarPlaceholder from "../../assets/avatar-placeholder.svg";

const Profile = () => {
    const dispatch = useDispatch();
    const [avatar, setAvatar] = useState('');
    const requestTracker = useSelector(requestTrackerSelector);
    const user = useSelector(userSelector);
    const {t} = useTranslation();

    const form = useForm({
        values: useMemo(() => {
            return {
                avatar: user ? user.avatar : null,
                email: user ? user.email : '',
                name: user ? user.name : '',
                password: '',
                passwordConfirm: '',
                passwordOld: ''
            }
        }, [user]),
        validation: {
            avatar: {
                required: false
            },
            email: {
                regExp: regExp.email,
                error: t('validation.default')
            },
            name: {
                callback: () => form.state.name,
                error: t('validation.default')
            },
            password: {
                callback: () => form.state.password ? regExp.password.test(form.state.password) : true,
                error: t('validation.default')
            },
            passwordOld: {
                callback: () => {
                    return form.state.passwordOld ?
                        regExp.password.test(form.state.passwordOld)
                        :
                        !form.state.password
                },
                error: t('validation.default')
            },
            passwordConfirm: {
                callback: () => form.state.password === form.state.passwordConfirm,
                error: t('validation.passwordCompare')
            }
        },
        onChange: (e) => {
            if (e.target.type === 'file') {
                (() => {
                    setAvatar(URL.createObjectURL(e.target.files[0]));
                })();
            }
        },
        onSubmit: async () => {
            let avatar = form.state.avatar;
            if (avatar instanceof File) {
                try {
                    dispatch(onRequestAction(REQUESTED_UPLOAD, true));
                    let response = await APIUpload(avatar, AVATAR_SIZES);
                    avatar = response.data.file;
                } catch (error) {
                    dispatch(alertError(errorHandler(error)));
                } finally {
                    dispatch(onRequestAction(REQUESTED_UPLOAD, false));
                }
            }
            dispatch(editProfile({
                avatar,
                email: form.state.email !== user.email ? form.state.email : null,
                name: form.state.name,
                password: form.state.password || null,
                password_confirmation: form.state.passwordConfirm || null,
                password_old: form.state.passwordOld || null
            }));
        }
    });

    useEffect(() => {
        if (user) {
            setAvatar(prepareImageURL(user.avatar));
        }
    }, [user]);

    return (
        <AppLayout>
            <div className="page-wrapper">
                <div className="profile">
                    <Container className="custom-container">
                        <Row className="custom-row">
                            <Col className="custom-col custom-col--with-max-width" sm={12}>
                                <div className="profile__form">
                                    <Form noValidate className="profile__form" onSubmit={form.handleSubmit}>
                                        <h2 className="pfm-subtitle">{t('title.profileData')}</h2>
                                        <FileFieldWrapper
                                            name="avatar"
                                            onChange={form.handleChange}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <div className="profile__form__avatar-wrapper">
                                                <img className="profile__form__avatar" src={avatar || avatarPlaceholder} alt={t('common.avatar')}/>
                                            </div>
                                            <Form.Label className="load-button">{t('button.uploadPhoto')}</Form.Label>
                                        </FileFieldWrapper>
                                        <FieldWrapper
                                            type="text"
                                            name="name"
                                            label={t('label.name')}
                                            placeholder={t('placeholder.name')}
                                            value={form.state.name}
                                            onChange={form.handleChange}
                                            onBlur={form.handleBlur}
                                            validation={form.errors.name}
                                        />
                                        <FieldWrapper
                                            type="email"
                                            name="email"
                                            label={t('label.email')}
                                            placeholder={t('placeholder.email')}
                                            value={form.state.email}
                                            onChange={form.handleChange}
                                            onBlur={form.handleBlur}
                                            validation={form.errors.email}
                                        />
                                        <div className="pfm-divider"/>
                                        <h2 className="pfm-subtitle profile__subtitle">{t('title.changePassword')}</h2>
                                        <PasswordFieldWrapper
                                            style={{
                                                maxWidth: '264px'
                                            }}
                                            name="passwordOld"
                                            value={form.state.passwordOld}
                                            label={t('label.oldPassword')}
                                            onChange={form.handleChange}
                                            onBlur={form.handleBlur}
                                            validation={form.errors.passwordOld}
                                        />
                                        <PasswordFieldWrapper
                                            className="mb-0"
                                            style={{
                                                maxWidth: '264px'
                                            }}
                                            name="password"
                                            value={form.state.password}
                                            label={t('label.newPassword')}
                                            validation={form.errors.password}
                                            onChange={form.handleChange}
                                            onBlur={form.handleBlur}
                                        />
                                        <div className="form__help-label">
                                            <span>{t('desc.password')}</span>
                                        </div>
                                        <PasswordFieldWrapper
                                            style={{
                                                maxWidth: '264px'
                                            }}
                                            name="passwordConfirm"
                                            value={form.state.passwordConfirm}
                                            label={t('label.repeatNewPassword')}
                                            onChange={form.handleChange}
                                            onBlur={form.handleBlur}
                                            validation={form.errors.passwordConfirm}
                                        />
                                        <Button
                                            type="submit"
                                            className="primary-btn profile__form__save-btn"
                                            disabled={requestTracker.updateUser || !form.modified}
                                        >
                                            {requestTracker.updateUser || requestTracker.upload ?
                                                <Spinner animation={"border"} size={"sm"}/>
                                                :
                                                t('button.save')
                                            }
                                        </Button>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </AppLayout>
    );
};

export default Profile;