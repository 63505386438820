import {useEffect, useState} from "react";
import {GridContextProvider, GridDropZone, GridItem, swap} from "react-grid-dnd";

import {prepareImageURL} from "../../helpers/imageUtils";
import {defaultImagePaths} from "../../helpers/defaults";

import {useFileList} from "../../hooks";

import CloseButton from "../CloseButton";

const FileListItem = ({item, index, onDelete}) => {
    return (
        <GridItem
            className="file-list__item-wrapper"
            style={{
                opacity: 1
            }}
        >
            <div className="file-list__item">
                <img
                    className="file-list__item__image"
                    src={item}
                    alt={item}
                    draggable={false}
                />
                <CloseButton
                    className="file-list__item__delete"
                    danger={true}
                    onClick={() => {
                        onDelete(index);
                    }}
                />
            </div>
        </GridItem>
    )
};

const FileList = ({children, files, onFileSwap, onDelete}) => {
    const [preparedItems, setPreparedItem] = useState([]);
    const fileListProps = useFileList(preparedItems);

    useEffect(() => {
        if (files && !preparedItems.length) {
            setPreparedItem(files.reduce((a, e) => {
                if (e && !defaultImagePaths.set.has(e))
                    a.push(prepareImageURL(e));
                return a;
            }, []));
        } else if (files && files.length > preparedItems.length) {
            setPreparedItem(prev => {
                const additional = files
                    .filter((item, index) => index >= preparedItems.length)
                    .reduce((a, e) => {
                        if (e && !defaultImagePaths.set.has(e))
                            a.push(prepareImageURL(e));
                        return a;
                    }, []);

                return [...prev, ...additional];
            });
        }
    }, [files, preparedItems.length]);

    return (
        <GridContextProvider onChange={(sourceId, sourceIndex, targetIndex) => {
            setPreparedItem(swap(preparedItems, sourceIndex, targetIndex))
            onFileSwap(swap(files, sourceIndex, targetIndex));
        }}>
            <GridDropZone
                boxesPerRow={3}
                rowHeight={86}
                id="file-list-droppable"
                className="file-list"
                style={{
                    height: `${fileListProps.listHeight}px`
                }}
            >
                {preparedItems.map((item, index) => {
                    return (
                        <FileListItem
                            key={item}
                            item={item}
                            index={index}
                            onDelete={(index) => {
                                onDelete(index);
                                setPreparedItem(prev => prev.filter((item, i) => i !== index));
                            }}
                        />
                    );
                })}
                {children(fileListProps)}
            </GridDropZone>
        </GridContextProvider>
    );
};

export default FileList;