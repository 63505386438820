export const ALERT_HIDE = 'ALERT_HIDE';
export const ALERT_SHOW = 'ALERT_SHOW';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const AUTH = 'AUTH';
export const REGISTER = 'REGISTER';
export const USER_SET = 'USER_SET';

export const REQUESTED_LOGIN = 'REQUESTED_LOGIN';
export const REQUESTED_LOGOUT = 'REQUESTED_LOGOUT';
export const REQUESTED_AUTH = 'REQUESTED_AUTH';
export const REQUESTED_REGISTER = 'REQUESTED_REGISTER';
export const REQUESTED_SEND_RESET = 'REQUESTED_SEND_RESET';
export const REQUESTED_RESET = 'REQUESTED_RESET';
export const REQUESTED_PAGES_LOAD = 'REQUESTED_PAGES_LOAD';
export const REQUESTED_PAGES_CREATE = 'REQUESTED_PAGES_CREATE';
export const REQUESTED_PAGES_SAVE = 'REQUESTED_PAGES_SAVE';
export const REQUESTED_CHECK_EMAIL = 'REQUESTED_CHECK_EMAIL';
export const REQUESTED_PAGE_CHECK = 'REQUESTED_PAGE_CHECK';
export const REQUESTED_UPLOAD = 'REQUESTED_UPLOAD';
export const REQUESTED_PAGE_PUBLISH = 'REQUESTED_PAGE_PUBLISH';
export const REQUESTED_USER_UPDATE = 'REQUESTED_USER_UPDATE';
export const REQUESTED_WIZARD_UPDATE = 'REQUESTED_WIZARD_UPDATE';
export const REQUESTED_TEMPLATES = 'REQUESTED_TEMPLATES';
export const REQUESTED_PAGES_DELETE = 'REQUESTED_PAGES_DELETE';
export const REQUESTED_NEWS_LOAD = 'REQUESTED_NEWS_LOAD';
export const REQUESTED_NEWS_PAGINATE = 'REQUESTED_NEWS_PAGINATE';
export const REQUESTED_STATS_LOAD = 'REQUESTED_STATS_LOAD';

export const RECEIVED_SEND_RESET = 'RECEIVED_SEND_RESET';
export const RECEIVED_RESET = 'RECEIVED_RESET';
export const RECEIVED_CHECK_EMAIL = 'RECEIVED_CHECK_EMAIL';
export const RECEIVED_PAGE_CHECK = 'RECEIVED_PAGE_CHECK';

export const BLOCK_TYPES = 'BLOCK_TYPES';

export const BLOCKS_EDIT = 'BLOCKS_EDIT';
export const BLOCKS_SET = 'BLOCKS_SET';

export const PAGES_LOAD = 'PAGES_LOAD';
export const PAGES_CREATE = 'PAGES_CREATE';
export const PAGES_UPDATE = 'PAGES_UPDATE';
export const PAGES_DELETE = 'PAGES_DELETE';
export const PAGES_SET_PROP = 'PAGES_SET_PROP';
export const PAGES_SET_PROPS = 'PAGES_SET_PROPS';
export const PAGES_UNDO = 'PAGES_UNDO';
export const PAGES_REDO = 'PAGES_REDO';
export const PAGES_PUSH_HISTORY = 'PAGES_PUSH_HISTORY';
export const PAGES_CLEAR_HISTORY = 'PAGES_CLEAR_HISTORY';

export const EDITOR_UPDATE = 'EDITOR_UPDATE';

export const EDIT_MODE__CLEAR = 'EDIT_MODE__CLEAR';

export const WIZARD_INIT = 'WIZARD_INIT';
export const WIZARD_SET_STEP = 'WIZARD_SET_STEP';
export const WIZARD_SET_CURRENT_STEP = 'WIZARD_SET_CURRENT_STEP';
export const WIZARD_POPUP = 'WIZARD_POPUP';
export const WIZARD_SET_TO_LS = 'WIZARD_SET_TO_LS';
export const WIZARD_GET_FROM_LS = 'WIZARD_GET_FROM_LS';
export const WIZARD_RETRIEVE = 'WIZARD_RETRIEVE';
export const WIZARD_SET_COMPLETED = 'WIZARD_SET_COMPLETED';

export const TEMPLATES_LOAD = 'TEMPLATES_LOAD';

export const NEWS_LOAD = 'NEWS_LOAD';
export const NEWS_PAGINATE = 'NEWS_PAGINATE';

export const STATS_LOAD = 'STATS_LOAD';
export const STATS_SET_PAGE = 'STATS_SET_PAGE';

export const PERFLUENCE_SET_LINK = 'PERFLUENCE_SET_LINK';

export const USER_SETTINGS = "USER_SETTINGS";
export const LOAD_EDITABLE_STYLE = "LOAD_EDITABLE_STYLE";
export const DESIGN_MODE_CLEAR = "DESIGN_MODE_CLEAR";
