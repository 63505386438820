import {NavLink as RouterNavLink, useLocation} from "react-router-dom";

const NavLink = (props) => {
    const {pathname} = useLocation();

    if (pathname === props.to)
        return <RouterNavLink {...props} replace/>;

    return <RouterNavLink {...props}/>;
};

export default NavLink;