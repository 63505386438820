import {useMemo} from "react";
import {Link, Redirect} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Button, Form, Spinner} from "react-bootstrap";
import {Trans, useTranslation} from "react-i18next"

import {resetPassword} from "../../redux/user/actions";
import {requestTrackerSelector} from "../../redux/selectors";

import {useForm, useQuery} from "../../hooks";

import {appRoutes, regExp} from "../../helpers/defaults";

import DefaultLayout from "../../layouts/DefaultLayout";

import NotFound from "../NotFound";
import {PasswordFieldWrapper} from "../../components/FieldWrappers";

const ResetPassword = () => {
    const dispatch = useDispatch();
    const requestTracker = useSelector(requestTrackerSelector)
    const query = useQuery();
    const {t} = useTranslation();

    const token = query.get('token');
    const email = query.get('email');

    const form = useForm({
        values: useMemo(() => {
            return {
                password: '',
                passwordConfirm: ''
            }
        }, []),
        validation: {
            password: {
                required: true,
                regExp: regExp.password,
                error: t('validation.default')
            },
            passwordConfirm: {
                callback: () => form.state.password === form.state.passwordConfirm,
                error: t('validation.passwordCompare')
            }
        },
        onSubmit: () => {
            dispatch(resetPassword(token, email, form.state.password));
        }
    });

    if (!token || !email)
        return <NotFound/>;

    if (requestTracker.reset.receivedSuccess)
        return <Redirect to={appRoutes.login}/>;

    return (
        <DefaultLayout title={t('title.changePassword')}>
            <div className="default__form-wrapper">
                <Form noValidate className="default__form" onSubmit={form.handleSubmit}>
                    <PasswordFieldWrapper
                        className="mb-0"
                        name="password"
                        value={form.state.password}
                        label={t('label.newPassword')}
                        isInvalid={form.errors.password}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        validation={form.errors.password}
                    />
                    <div className="form__help-label">
                        <span>{t('desc.password')}</span>
                    </div>
                    <PasswordFieldWrapper
                        name="passwordConfirm"
                        value={form.state.passwordConfirm}
                        label={t('label.repeatNewPassword')}
                        isInvalid={form.errors.passwordConfirm}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        validation={form.errors.passwordConfirm}
                    />
                    <Button
                        type="submit"
                        className="primary-btn default__form__submit"
                        disabled={requestTracker.reset.isRequesting}
                    >
                        {requestTracker.reset.isRequesting ?
                            <Spinner animation={"border"} size={"sm"}/>
                            :
                            t('button.changePassword')
                        }
                    </Button>
                </Form>
            </div>
            <div className="default__bottom">
                <Trans i18nKey="pages.reset.links">
                    <span><Link className="pfm-anchor" to={appRoutes.login}>Войти</Link> или <Link className="pfm-anchor" to={appRoutes.register}>Зарегистрироваться</Link></span>
                </Trans>
            </div>
        </DefaultLayout>
    )
};

export default ResetPassword;