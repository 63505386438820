import {defaultImagePaths} from "./defaults";

const ITEM_WIDTH = 104;
const ITEM_HEIGHT = 86;
const ITEMS_PER_ROW = 3;

export const calcFileListProps = (fileList) => {
    if (!fileList || fileList.every(item => defaultImagePaths.set.has(item))) {
        return {
            transformX: 0,
            transformY: 0,
            listHeight: ITEM_HEIGHT
        };
    }

    return {
        transformX: (fileList.length % ITEMS_PER_ROW) * ITEM_WIDTH,
        transformY: Math.floor(fileList.length / ITEMS_PER_ROW) * ITEM_HEIGHT,
        listHeight: ITEM_HEIGHT * Math.ceil((fileList.length + 1) / ITEMS_PER_ROW)
    };
};