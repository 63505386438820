import {ALERT_HIDE, ALERT_SHOW} from "../types";

const updateStore = (type, message) => ({
    type: ALERT_SHOW,
    payload: {
        type,
        message
    }
});

const hide = () => ({type: ALERT_HIDE});

const show = (type, message) => {
    return dispatch => {
        const callback = () => {
            dispatch(hide());
        };
        dispatch(updateStore(type, typeof message === 'function' ? message() : message));
        setTimeout(callback, 4000);
    };
};

export const alertSuccess = (message) => {
    return show('success', message);
};

export const alertError = (message) => {
    return show('danger', message);
};

export const alertWarning = (message) => {
    return show('warning', message);
};