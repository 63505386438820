import {AUTH, LOGOUT, USER_SET} from "../types";

const initialState = null;

const user = (state = initialState, action) => {
    switch (action.type) {
        case AUTH:
            return action.payload;
        case USER_SET:
            return action.payload;
        case LOGOUT:
            localStorage.removeItem('token');
            return initialState;
        default:
            return state;
    }
};

export default user;