
export const optionsFonts = [
    {value: 'CeraPro', text: 'Cera Pro'},
    {value: 'DroidSans', text: 'Droid Sans'},
    {value: 'Inter', text: 'Inter'},
    {value: 'Lato', text: 'Lato'},
    {value: 'Montserrat', text: 'Montserrat'},
    {value: 'NotoSans', text: 'Noto Sans'},
    {value: 'OpenSans', text: 'Open Sans'},
    {value: 'PTSerif', text: 'PT Serif'},
    {value: 'Roboto', text: 'Roboto'},
    {value: 'Rubik', text: 'Rubik'},
    {value: 'SourceSansPro', text: 'Source Sans Pro'},
    {value: 'Manrope', text: 'Manrope'}
];

// export const optionsPage = [
//     {value: 'Color', text: i18n.t('label.color')},
//     {value: 'Gradient', text: i18n.t('label.gradient')}
// ];

export const getFonts = (component, variable) => {
    return getComputedStyle(component).getPropertyValue(variable).split(',')[0].replace(/[^A-Za-zА-Яа-яЁё]/g, "");
};

export const changedFonts = (component, variable, value) => {
    component.style.setProperty(variable,`'${value}', sans-serif`)
};

export const getNumVariable = (component, variable) => {
    return getComputedStyle(component).getPropertyValue(variable).replace(/[^0-9]+/g, "");
};

export const changedVariable = (component, variable, value,limiter) => {
    if(value >= limiter.min && value <= limiter.max)
        component.style.setProperty(variable, `${value}px`)
};

const spoilerWebkitUnset = () => {
    const title = document.querySelector('.spoiler__title')
    title.style.setProperty('-webkit-background-clip', 'unset');
    title.style.setProperty('-webkit-text-fill-color', 'unset');
}

export const setPropertyColor = (stylePage,config, color) => {
    if(config === '--spoiler-title-color') {
        stylePage.style.setProperty(config, color);
        stylePage.style.setProperty('--spoiler-arrow-fill', color);
        stylePage.style.setProperty('--spoiler-title-bg-image', 'none');
        spoilerWebkitUnset();
    } else if(config === '--spoiler-bg-color') {
        stylePage.style.setProperty(config, color);
        stylePage.style.setProperty('--spoiler-title-bg-color', color);
        stylePage.style.setProperty('--spoiler-border-radius', '5px');
        stylePage.style.setProperty('--spoiler-title-border-radius', '5px');
    } else if(config === '--socials-bg-color' || config ===  '--socials-fill') {
        stylePage.style.setProperty(config, color);
        stylePage.style.setProperty(`${config}--nth2`, color);
        stylePage.style.setProperty(`${config}--nth3`, color);
        stylePage.style.setProperty(`--socials-border`,`none`);
    } else if(config === '--commercial-bg-color') {
        stylePage.style.setProperty(config, color);
        stylePage.style.setProperty('--commercial-border', 'none');
    } else if(config === '--button-color' || config === '--button-color--secondary'){
        stylePage.style.setProperty(config, color);
        stylePage.style.setProperty(`${config}__hover`, color);
        stylePage.style.setProperty(`${config}__active`, color);
    } else if(config === '--button-bg-color--secondary') {
        stylePage.style.setProperty(config, color);
        stylePage.style.setProperty('--button-border--secondary', `1px solid ${color}`);
        stylePage.style.setProperty('--button-bg-color--secondary__hover', color);
        stylePage.style.setProperty('--button-border--secondary__hover', `1px solid ${color}`);
        stylePage.style.setProperty('--button-opacity--secondary__hover', '0.8');
        stylePage.style.setProperty('--button-bg-color--secondary__active', color);
        stylePage.style.setProperty('--button-border--secondary__active', `1px solid ${color}`);
        stylePage.style.setProperty('--button-filter--secondary__active', 'brightness(1.1)');
    } else if(config === '--button-bg-color') {
        stylePage.style.setProperty(config, color);
        stylePage.style.setProperty('--button-border', `1px solid ${color}`);
        stylePage.style.setProperty('--button-bg-color__hover', color);
        stylePage.style.setProperty('--button-border__hover', `1px solid ${color}`);
        stylePage.style.setProperty('--button-opacity__hover', `0.8`);
        stylePage.style.setProperty('--button-bg-color__active', color);
        stylePage.style.setProperty('--button-border__active', `1px solid ${color}`);
        stylePage.style.setProperty('--button-filter__active', 'brightness(1.1)');
    } else if(config === '--color') {
        stylePage.style.setProperty(config, color);
        stylePage.style.setProperty('--footer-link-color', color);
        stylePage.style.setProperty('--footer-color', color);
    } else {
        stylePage.style.setProperty(config, color);
    }
};

export const setPropertyGradient = (stylePage,config,color,gradientColor) => {
    stylePage.style.setProperty(config, `linear-gradient(135deg, ${color} 2.8%, ${gradientColor} 100%)`)
};

export const getVariable = (page, variable) => {
    return getComputedStyle(page).getPropertyValue(variable);
};

export const setSubVariable = (variable, value) => {
    if (variable === '--button-bg-color') {
        return {
            '--button-border': `1px solid ${value}`,
            '--button-bg-color__hover': value,
            '--button-border__hover': `1px solid ${value}`,
            '--button-opacity__hover': '0.8',
            '--button-bg-color__active': value,
            '--button-border__active': `1px solid ${value}`,
            '--button-filter__active': 'brightness(1.1)'
        }
    } else if(variable === '--socials-bg-color' || variable ===  '--socials-fill') {
        return {
            [`${variable}--nth2`]: value,
            [`${variable}--nth3`]: value,
            '--socials-border': 'none'
        }
    } else if (variable === '--button-bg-color--secondary') {
        return {
            '--button-border--secondary': `1px solid ${value}`,
            '--button-bg-color--secondary__hover': value,
            '--button-border--secondary__hover': `1px solid ${value}`,
            '--button-opacity--secondary__hover': '0.8',
            '--button-bg-color--secondary__active': value,
            '--button-border--secondary__active': `1px solid ${value}`,
            '--button-filter--secondary__active': 'brightness(1.1)',
        }
    } else if (variable === '--button-color' || variable === '--button-color--secondary') {
        return {
            [`${variable}__hover`]: value,
            [`${variable}__active`]: value
        }
    } else if(variable === '--commercial-bg-color') {
        return {
            '--commercial-border': 'none'
        }
    } else if (variable === '--spoiler-title-color') {
        return {
            '--spoiler-arrow-fill': value
        }
    } else if(variable === '--spoiler-bg-color') {
        return {
            '--spoiler-title-bg-color': value,
            '--spoiler-border-radius': '5px',
            '--spoiler-title-border-radius': '5px'
        }
    } else if (variable === '--color') {
        return {
            '--footer-link-color' : value,
            '--footer-color' : value
        }
    } else {
        return {}
    }
};