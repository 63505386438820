import {Button, Spinner} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {requestTrackerSelector} from "../../redux/selectors";

import {parseNewsDate} from "../../helpers/dateFormattingUtils";
import {appRoutes} from "../../helpers/defaults";

const NewsList = ({news, buttonText, onButtonClick, short}) => {
    const requestTracker = useSelector(requestTrackerSelector);
    const {t} = useTranslation();

    if (requestTracker.loadNews)
        return <Spinner animation="border"/>;

    return (
        news.list.length ?
            <>
                <ul className="news-list">
                    {
                        news.list.map((item, index) => {
                            if (short && index >= 5)
                                return null;
                            return (
                                <li className="news-list__item" key={item.id}>
                                    <Link to={`${appRoutes.news}/${item.id}`} className="news-list__item__link">
                                        <span className="news-list__item__date">{parseNewsDate(item.created_at)}</span>
                                        <span className="news-list__item__text">{item.title}</span>
                                    </Link>
                                </li>
                            );
                        })
                    }
                </ul>
                {(news.list.length < news.total || short) &&
                <div className="news-list__button">
                    <Button
                        variant="light"
                        size="sm"
                        className="light-btn"
                        onClick={onButtonClick}
                        disabled={requestTracker.paginateNews}
                    >
                        {requestTracker.paginateNews ?
                            <Spinner animation="border" size="sm"/>
                            :
                            buttonText
                        }
                    </Button>
                </div>
                }
            </>
            :
            <span>{t('pages.news.noNews')}</span>
    );
};

export default NewsList;