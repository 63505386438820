export const alertSelector = store => store.alert;
export const userSelector = store => store.user;
export const requestTrackerSelector = store => store.requestTracker;
export const editorSelector = store => store.editor;

export const wizardStepsSelector = store => store.wizard.steps;
export const wizardCurrentStepSelector = store => store.wizard.currentStep;
export const wizardPopupSelector = store => store.wizard.popup;

export const templatesSelector = store => store.templates;

export const newsSelector = store => store.news;

export const statsSelector = store => store.stats;

export const perfluenceSelector = store => store.perfluence;

export const editorDesignSelector = store => store.editorDesign;