import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Spinner} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {APISendFeedback} from "../../api";

import AppLayout from "../../layouts/AppLayout";

import {FieldWrapper} from "../../components/FieldWrappers";
import FAQ from "../../components/Faq";
import {alertSuccess, alertError} from "../../redux/alert/actions";

import knowledgeImage from '../../assets/knowledge-image.png';
import {userSelector} from "../../redux/selectors";

const Knowledge = () => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [validation, setValidation] = useState(false);
    const user = useSelector(userSelector)

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const checkValid = () => {
        if(message.length <= 0) {
            setValidation(true);
        } else if(message.length > 2) {
            setValidation(false);
        }
    };

    const sendFeedback = async () => {
        await checkValid();
        if(message.length > 2) {
            setLoading(true)
            APISendFeedback({message})
                .then(() => {
                    dispatch(alertSuccess(t('alert.sentMessage')));
                    setMessage('');
                })
                .catch(() => alertError(t('error.default')))
                .finally(() => setLoading(false));
        }
    };

    return (
        <AppLayout>
            <div className="page-wrapper">
                <div className='knowledge-wrapper'>
                    <div className='knowledge'>
                        <h2 className='pfm-subtitle'>{t('title.knowledge')}</h2>
                        <img className='knowledge-image' src={knowledgeImage} alt={t('title.knowledge')}/>
                        <p className='knowledge-text'>{t("knowledge.desc")}</p>
                        <div className='knowledge-base'>
                            <ul className='knowledge-base-list'>
                                <li className='knowledge-base-list__item'>
                                    <a className='knowledge-base-list__item__link' href={'https://pfmlink.me/help_create_page'} target={"_blank"} rel="noreferrer">
                                        {t('knowledge.links.createPage')}</a>
                                </li>
                                <li className='knowledge-base-list__item'>
                                    <a className='knowledge-base-list__item__link' href={'https://pfmlink.me/help_blocks'} target={"_blank"} rel="noreferrer">
                                        {t('knowledge.links.blocks')}</a>
                                </li>
                                <li className='knowledge-base-list__item'>
                                    <a className='knowledge-base-list__item__link' href={'https://pfmlink.me/help_process'} target={"_blank"} rel="noreferrer">
                                        {t('knowledge.links.processCreate')}</a>
                                </li>
                                <li className='knowledge-base-list__item'>
                                    <a className='knowledge-base-list__item__link' href={'https://pfmlink.me/help_content'} target={"_blank"} rel="noreferrer">
                                        {t('knowledge.links.content')}</a>
                                </li>
                            </ul>
                            <ul className='knowledge-base-list'>
                                <li className='knowledge-base-list__item'>
                                    <a className='knowledge-base-list__item__link' href={'https://pfmlink.me/help_design'} target={"_blank"} rel="noreferrer">
                                        {t('knowledge.links.design')}</a>
                                </li>
                                <li className='knowledge-base-list__item'>
                                    <a className='knowledge-base-list__item__link' href={'https://pfmlink.me/help_publish'} target={"_blank"} rel="noreferrer">
                                        {t('knowledge.links.publication')}</a>
                                </li>
                                <li className='knowledge-base-list__item'>
                                    <a className='knowledge-base-list__item__link' href={'https://pfmlink.me/help_delete_page'} target={"_blank"} rel="noreferrer">
                                        {t('knowledge.links.deletePage')}</a>
                                </li>
                                <li className='knowledge-base-list__item'>
                                    <a className='knowledge-base-list__item__link' href={'https://pfmlink.me/help_stat'} target={"_blank"} rel="noreferrer">
                                        {t('knowledge.links.statistics')}</a>
                                </li>
                            </ul>
                        </div>
                        <div className='feedback__divider knowledge__divider'> </div>
                    </div>
                    <div className='feedback'>
                        <h2 className='pfm-subtitle' onClick={()=>console.log(user)}>{t('knowledge.feedback.title')}</h2>
                        <div className={'d-flex feedback__form'}>
                            <FieldWrapper
                                placeholder={t('knowledge.feedback.placeholder')}
                                desc={t('knowledge.feedback.desc')}
                                value={message}
                                onChange={(e) => {
                                    setMessage(e.target.value);
                                    if(e.target.value.length > 2)
                                        setValidation(false)
                                }}
                                validation={validation}
                            />
                            <button
                                type={'button'}
                                disabled={loading}
                                className={'feedback-btn__send light-btn editor__publication__button btn btn-light btn-sm'}
                                onClick={sendFeedback}
                            >{loading ?
                                <Spinner animation='border' size='sm'/>
                                :t('knowledge.feedback.send')}
                            </button>
                        </div>
                        <div className='feedback__divider'> </div>
                        <div className='feedback-faq'>
                            <h2 className='pfm-subtitle'>{t('knowledge.questions.title')}</h2>
                            <FAQ
                                question={t('knowledge.questions.q1')}
                                answer={t('knowledge.questions.answers.a1')}
                            />
                            <FAQ
                                question={t('knowledge.questions.q2')}
                                answer={t('knowledge.questions.answers.a2')}
                            />
                            <FAQ
                                question={t('knowledge.questions.q3')}
                                answer={t('knowledge.questions.answers.a3')}
                            />
                        </div>
                        <a target='_blank' rel='noreferrer' href='https://pfmlink.me/faq' className={'feedback-faq_btn light-btn editor__publication__button btn btn-light btn-sm'}>
                            {t('knowledge.questions.allQuestions')}
                        </a>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
};

export default Knowledge;