import {useCallback, useEffect, useLayoutEffect, useMemo, useState} from "react";
import {useLocation} from "react-router-dom";
import {getInvalidBlockProps} from "../helpers/editorUtils";
import {getBlockConfigs, blockTypes} from "../helpers/defaults";
import {calcFileListProps} from "../helpers/fileListDnDUtils";

export const useQuery = () => new URLSearchParams(useLocation().search);

export const useOutsideClick = (ref, containerRef, callback) => {

    const handleClickOutside = useCallback((event) => {
        if (containerRef.current && containerRef.current.contains(event.target)) {
            if (ref.current && !ref.current.contains(event.target))
                callback(event);
        }
    }, [ref, containerRef, callback]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [ref, containerRef, callback, handleClickOutside]);
};

export const useSimpleOutsideClick = (ref, callback) => {
    const handleClickOutside = useCallback(event => {
        if (ref.current && !ref.current.contains(event.target))
            callback(event);
    }, [ref, callback]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [ref, callback, handleClickOutside]);
};

export const useToken = (initial) => {
    if (initial)
        localStorage.setItem('token', initial);
    return localStorage.getItem('token');
};

export const useForm = ({values, validation, onSubmit, onChange, onReset, onKeyUp, onChangeByFieldName}) => {
    const [state, setState] = useState({...values});
    const [modified, setModified] = useState(false);
    const [valid, setValid] = useState(true);
    const [errors, setErrors] = useState({});

    const validate = (name) => {
        if (!validation[name])
            return false;
        return !!((validation[name].required && !state[name] && (typeof state[name] === 'string' ? !state[name].trim() : false)) ||
            (validation[name].regExp && !validation[name].regExp.test(state[name] || '')) ||
            (validation[name].callback && !validation[name].callback()));
    };

    const handleChange = (e) => {
        setErrors({
            ...errors,
            [e.target.name]: null
        });

        setState({
            ...state,
            [e.target.name]: e.target.type === 'file' ?
                (e.target.multiple ?
                    Array.from(e.target.files)
                    :
                    e.target.files[0])
                :
                e.target.value
        });
        setModified(true);
        if (onChange) onChange(e);
    };

    const changeByFieldName = (name, value) => {
        setErrors({
            ...errors,
            [name]: null
        });

        setState({
            ...state,
            [name]: value
        });
        setModified(true);
        if (onChangeByFieldName) onChangeByFieldName(name, value);
    };

    const handleBlur = async (e) => {
        const name = e.target.name;
        let valid = true;

        if (!state[name])
            return;

        if (validate(name)) {
            setErrors({
                ...errors,
                [name]: validation[name].error
            });
            valid = false;
        }
        setValid(valid);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const tmp = {};
        let valid = true;
        for (let key in state) {
            let res = validate(key);
            if (res)
                tmp[key] = validation[key].error;

            if (!res && validation[key] && validation[key].async) {
                res = !(await validation[key].async.callback({
                    value: state[key],
                    name: key,
                    errors,
                    setErrors
                }));
                if (res)
                    tmp[key] = validation[key].async.error;
            }

            if (valid && res)
                valid = false;
        }
        setErrors({
            ...errors,
            ...tmp
        });
        if (!valid) {
            setValid(false);
            return;
        }
        setModified(false);
        setValid(true);
        onSubmit();
    };

    const handleReset = () => {
        setState({...values});
        setModified(false);
        setValid(true);
        if (onReset) onReset();
    };

    const handleKeyUp = (e) => {
        if (onKeyUp) onKeyUp(e, errors, setErrors);
    }

    useEffect(() => {
        setState({...values});
    }, [values]);

    return {
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
        handleKeyUp,
        changeByFieldName,
        setModified,
        state,
        errors,
        modified,
        valid
    };
};

export const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
        const updateSize = () => {
            setSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', updateSize);

        updateSize();

        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return size;
};

export const useCountdownTimer = (endTime) => {
    const [total, setTotal] = useState(Date.parse(endTime) - Date.now());

    const seconds = useMemo(() => {
        if (total <= 0 || isNaN(total)) return 0;
        return Math.floor( (total/1000) % 60 );
    }, [total]);
    const minutes = useMemo(() => {
        if (total <= 0 || isNaN(total)) return 0;
        return Math.floor( (total/1000/60) % 60 );
    }, [total]);
    const hours = useMemo(() => {
        if (total <= 0 || isNaN(total)) return 0;
        return Math.floor( (total/(1000*60*60)) % 24 );
    }, [total]);
    const days = useMemo(() => {
        if (total <= 0 || isNaN(total)) return 0;
        return Math.floor( total/(1000*60*60*24) );
    }, [total]);

    const clock = useCallback(() => {
        return setInterval(() => {
            if (total <= 0)
                clearInterval(clock())
            else
                setTotal(Date.parse(endTime) - Date.now());
        }, 1000);
    }, [endTime, total, setTotal]);

    useLayoutEffect(() => {
        setTotal(Date.parse(endTime) - Date.now());
    }, [endTime, setTotal]);

    useEffect(() => {
        const interval = clock();

        return () => {
            clearInterval(interval);
        }
    }, [clock]);

    return {
        seconds,
        minutes,
        hours,
        days
    };
};

export const useInvalidBlockProps = (blockType, blockData) => {
    const blockConfigs = useMemo(() => getBlockConfigs(), []);

    return useMemo(() => {
        if (blockType === blockTypes.social)
            return getInvalidBlockProps(blockType, blockData[0], blockConfigs[blockType].data[0]);

        if (blockType === blockTypes.commercial)
            return getInvalidBlockProps(blockType, blockData, blockConfigs[blockType].data[blockData.layout]);

        if (blockType === blockTypes.perfluence)
            return {unknownType: false, unknownProps: [], missedProps: [], defaultProps: []};

        return getInvalidBlockProps(blockType, blockData, blockConfigs[blockType] ? blockConfigs[blockType].data : {});
    }, [blockType, blockData]);
};

export const useFileList = (fileList) => {
    const [state, setState] = useState(calcFileListProps(fileList));

    useEffect(() => {
        setState(calcFileListProps(fileList));
    }, [fileList]);

    return state;
};