import {Route, Redirect} from "react-router-dom";

import {useToken} from "../../hooks";

import {appRoutes} from "../../helpers/defaults";

const PrivateRoute = ({children, ...rest}) => {
    const token = useToken();

    if (!token)
        return <Redirect to={appRoutes.login}/>;

    return (
        <Route {...rest}>
            {children}
        </Route>
    );
};

export default PrivateRoute;