import iconPublished from "../assets/icon-published.svg";
import iconEdited from "../assets/icon-edited.svg";
import iconUnpublished from "../assets/icon-unpublished.svg";

export const publicationState = {
    unpublished: {
        name: 'unpublished',
        icon: iconUnpublished
    },
    edited: {
        name: 'edited',
        icon: iconEdited
    },
    published: {
        name: 'published',
        icon: iconPublished
    }
};

export const getPublicationStateIcon = (state) => {
    if (!publicationState[state])
        return publicationState.unpublished.icon;

    return publicationState[state].icon;
};

export const getPagePublicationState = (page) => {
    if (page) {
        const changesArePublished = !page.unpublished_changes.blocks && !page.unpublished_changes.style;
        const changesAreEqualToPublished =
            JSON.stringify(page.unpublished_changes.blocks) === JSON.stringify(page.blocks) &&
            (!page.unpublished_changes.style || page.unpublished_changes.style === page.style);

        if(page.unpublished_changes.style_config) {
            return publicationState.edited.name;
        }
        if (!page.blocks.length)
            return publicationState.unpublished.name;

        if (changesArePublished || changesAreEqualToPublished)
            return publicationState.published.name;

        return publicationState.edited.name;
    }

    return publicationState.unpublished.name;
};