import {Spinner} from "react-bootstrap";

const Loader = () => {
    return (
        <main className="default">
            <div className="default__loading">
                <Spinner animation="border"/>
            </div>
        </main>
    );
};

export default Loader;

