import {useState} from "react";

const SidebarDropdown = ({className, title, icon, children}) => {
    const [isOpened, setOpened] = useState(false);

    return (
        <div className={isOpened ? 'sidebar__dropdown sidebar__dropdown--opened' : 'sidebar__dropdown'}>
            <div className={className ? `sidebar__dropdown__head ${className}` : 'sidebar__dropdown__head'}
                 onClick={() => setOpened(!isOpened)}
            >
                {icon}
                <span>{title}</span>
            </div>
            <div className="sidebar__dropdown__body">
                {children}
            </div>
        </div>
    );
};

export default SidebarDropdown;