const config = {
    toolbar: {
        items: [
            'paragraph',
            'heading1',
            'heading2',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'link',
            'alignment',
            'undo',
            'redo'
        ]
    },
    heading: {
        options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: {name: 'h1', classes: 'title'}, title: 'Heading 1', class: 'ck-heading_heading1'},
            { model: 'heading2', view: {name: 'h2', classes: 'subtitle'}, title: 'Heading 2', class: 'ck-heading_heading2'}
        ]
    },
    alignment: {
        options: [
            {name: 'left', className: 'alignment-left'},
            {name: 'right', className: 'alignment-right'},
            {name: 'center', className: 'alignment-center'},
            {name: 'justify', className: 'alignment-justify'}
        ]
    },
    link: {
        defaultProtocol: 'https://'
    }
};

export default config;