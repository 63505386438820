import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Col, Container, Row} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {APIUpload} from "../../api";

import {REQUESTED_UPLOAD} from "../../redux/types";
import {userSelector, wizardCurrentStepSelector, wizardStepsSelector} from "../../redux/selectors";
import {
    editWizardStep,
    fillWizardSteps,
    getWizardStateFromLocalStorage,
    initWizard,
    retrieveWizardState,
    saveWizardStateToLocalStorage,
    setWizardCurrentStep
} from "../../redux/wizard/actions";
import {onRequestAction} from "../../redux/requestTracker/actions";

import {useForm} from "../../hooks";

import {regExp, fieldTypes, defaultImagePaths, appRoutes} from "../../helpers/defaults";
import {AVATAR_SIZES} from "../../helpers/const";
import {wizardReviver} from "../../helpers/wizardUtils";

import AppLayout from "../../layouts/AppLayout";

import {WizardForm, WizardHeader} from "../../components/Wizard";
import PageLoader from "../../components/PageLoader";

const Wizard = () => {
    const dispatch = useDispatch();
    const user = useSelector(userSelector);
    const steps = useSelector(wizardStepsSelector);
    const currentStep = useSelector(wizardCurrentStepSelector);
    const history = useHistory();
    const {t} = useTranslation();

    const header = useMemo(() => {
        if (steps[currentStep]) {
            return steps[currentStep].find(item => item.name === 'header');
        }
        return {};
    }, [steps, currentStep]);

    const initialValues = useMemo(() => {
        if (steps[currentStep]) {
            return steps[currentStep].reduce((a, e) => {
                if (e.name === 'header') {
                    return a;
                } else if (e.name === 'videos' && e.type === fieldTypes.group) {
                    return e.data.reduce((a, item, index) => {
                        a[`${index}_${e.name}`] = item.value || item.default || '';
                        return a;
                    }, {});
                } else if (e.type === fieldTypes.checkbox) {
                    a[e.name] = e.value;
                    return a;
                } else {
                    a[e.name] = e.value;
                    return a;
                }
            }, {});
        }
        return {};
    }, [steps, currentStep]);

    const validation = useMemo(() => {
        if (steps[currentStep]) {
            return steps[currentStep].reduce((a, e) => {
                if (e.type === fieldTypes.url) {
                    a[e.name] = {
                        regExp: regExp.urlOrEmpty,
                        error: t('validation.url')
                    }
                    return a;
                } else if (e.name === 'videos' && e.type === fieldTypes.group) {
                    return e.data.reduce((a, item, index) => {
                        if (item.type === fieldTypes.url) {
                            a[`${index}_${e.name}`] = {
                                regExp: regExp.urlOrEmpty,
                                error: t('validation.url')
                            }
                        }
                        return a;
                    }, {});
                }
                return a;
            }, {});
        }
        return {};
    }, [steps, currentStep]);

    const handleChange = (name, value) => {
        const item = steps[currentStep].find(item => item.name === name);
        const indexOfItem = steps[currentStep].indexOf(item);
        const newValues = [...steps[currentStep]];

        newValues[indexOfItem] = {
            ...item,
            value
        };
        dispatch(editWizardStep({
            index: currentStep,
            step: newValues
        }));
    };

    const createRequestBody = async (stepIndex) => {
        const requestBody = steps[stepIndex].reduce((a, i) => {
            if (i.name === 'header') {
                return a;
            }

            if (i.name.includes('videos')) {
                if (a['videos'] && i.value) {
                    a['videos'] = [
                        ...a['videos'],
                        {
                            url: i.value
                        }
                    ];
                } else if (i.value) {
                    a['videos'] = [
                        {
                            url: i.value
                        }
                    ];
                }
            } else if (i.name === 'photos') {
                if (i.value.length) {
                    a[i.name] = i.value.reduce((a, item) => {
                        if (item) {
                            a.push(item);
                        }
                        return a;
                    }, []);
                }
            } else {
                if (i.value)
                    a[i.name] = i.value;
            }

            return a;
        }, {});

        for (let key in requestBody) {
            if (requestBody[key] instanceof File) {
                try {
                    dispatch(onRequestAction(REQUESTED_UPLOAD, true));
                    const response = await APIUpload(requestBody[key], AVATAR_SIZES);
                    requestBody[key] = response.data.file;
                } catch (e) {
                    requestBody[key] = defaultImagePaths.avatarPlaceholderPath;
                } finally {
                    dispatch(onRequestAction(REQUESTED_UPLOAD, false));
                }
                handleChange(key, requestBody[key]);
            } else if (key === 'photos') {
                const tmp = [];
                for (let i = 0; i < requestBody[key].length; i++) {
                    if (requestBody[key][i] instanceof File) {
                        try {
                            dispatch(onRequestAction(REQUESTED_UPLOAD, true));
                            const response = await APIUpload(requestBody[key][i]);
                            requestBody[key][i] = response.data.file
                            tmp.push(response.data.file);
                        } catch (e) {
                            requestBody[key][i] = defaultImagePaths.placeholderPath
                            tmp.push(defaultImagePaths.placeholderPath);
                        } finally {
                            dispatch(onRequestAction(REQUESTED_UPLOAD, false));
                        }
                    } else
                        tmp.push(requestBody[key][i]);
                    handleChange(key, tmp);
                }
            }
        }
        return requestBody;
    }

    const form = useForm({
        values: initialValues,
        validation,
        onChange: (e) => {
            const value = e.target.type === 'file' ?
                (e.target.multiple ? Array.from(e.target.files) : e.target.files[0]) : e.target.value
            handleChange(e.target.name, value);
        },
        onChangeByFieldName: handleChange,
        onSubmit: async () => {
            const requestBody = {};
            for (let i = 0; i <= currentStep; i++) {
                requestBody[`step${i + 1}`] = await createRequestBody(i);
            }
            if (currentStep < 4) {
                dispatch(fillWizardSteps({
                    data: {
                        ...requestBody
                    },
                    completed: false
                }));
            } else if (currentStep === 4) {
                const id = steps[currentStep].find(item => item.name === 'page').data.id;
                await dispatch(fillWizardSteps({
                    data: {
                        [`step${currentStep + 1}`]: {
                            style: form.state.page
                        }
                    },
                    completed: true
                }));
                (() => {
                    history.push(`${appRoutes.editor}/${id}`);
                })();
            }
        },
    });

    useEffect(() => {
        const wizardLS = JSON.parse(localStorage.getItem('wizard'), wizardReviver);

        if (user) {
            if (!user.settings.wizard_page && !wizardLS) {
                dispatch(initWizard());
            } else if (user.settings.wizard_page && user.settings.wizard_data && !wizardLS) {
                const data = [
                    user.settings.wizard_data.step1,
                    user.settings.wizard_data.step2,
                    user.settings.wizard_data.step3,
                    user.settings.wizard_data.step4
                ].filter(item => !!item);
                dispatch(retrieveWizardState(data));
            } else if (user.settings.wizard_page && wizardLS) {
                dispatch(getWizardStateFromLocalStorage(wizardLS));
            }
        }

        return () => {
            dispatch(saveWizardStateToLocalStorage());
        };
    }, [user, dispatch]);

    return (
        <AppLayout>
            <div className="page-wrapper">
                {!steps[currentStep] ?
                    <PageLoader/>
                    :
                    <div className="wizard">
                        <Container className="custom-container">
                            <Row className="custom-row">
                                <Col className="custom-col custom-col--with-max-width">
                                    <WizardHeader
                                        title={header.title}
                                        img={header.img}
                                        intro={header.intro}
                                        currentStep={currentStep + 1}
                                        stepsAmount={5}
                                    />
                                    <WizardForm
                                        fields={steps[currentStep]}
                                        currentStep={currentStep}
                                        setCurrentStep={(currentStep) => {
                                            dispatch(setWizardCurrentStep(currentStep))
                                        }}
                                        form={form}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                }
            </div>
        </AppLayout>
    );
};

export default Wizard;