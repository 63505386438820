import {useMemo, useState} from "react";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {useParams} from "react-router-dom";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {editorSelector, requestTrackerSelector, statsSelector} from "../../redux/selectors";

import {statsFilters, chartDataPaths} from "../../helpers/statsUtils";

import AppLayout from "../../layouts/AppLayout";

import NotFound from "../NotFound";

import PagesStatsList from "../../components/PagesStatsList";
import {StatsPageItem} from "../../components/StatsComponents";

const StatsLayout = ({children}) => {
    return (
        <AppLayout>
            <div className="page-wrapper">
                <div className="stats">
                    {children}
                </div>
            </div>
        </AppLayout>
    );
};

const Stats = () => {
    const stats = useSelector(statsSelector);
    const editor = useSelector(editorSelector);
    const requestTracker = useSelector(requestTrackerSelector)
    const [notFound, setNotFound] = useState(false);
    const [filters, setFilters] = useState({
        period: statsFilters.month,
        interval: {
            from: moment().subtract(7, 'day').format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD'),
        }
    });
    const {pageId, chartType} = useParams();
    const {t} = useTranslation();

    const pagesList = useMemo(() => {
        if (stats.data.pages.length && editor.pages.length) {
            return stats.data.pages.reduce((a, e) => {
                const page = editor.pages.find(item => item.id === e.page_id);
                if (page) {
                    a.push({
                        ...e,
                        title: page.title
                    });
                }

                return a;
            }, []);
        }
        return [];
    }, [stats.data.pages, editor.pages]);

    if (
        (pageId !== undefined && isNaN(pageId)) ||
        (chartType !== undefined && !chartDataPaths.has(chartType)) ||
        notFound
    )
        return <NotFound/>;

    if (pageId)
        return (
            <StatsLayout>
                <Container className="custom-container">
                    <StatsPageItem
                        id={pageId}
                        chartType={chartType}
                        page={stats.data.pagesDetailed[pageId]}
                        filters={filters}
                        setFilters={setFilters}
                        setNotFound={setNotFound}
                    />
                </Container>
            </StatsLayout>
        );

    return (
        <StatsLayout>
            <Container className="custom-container">
                <Row className="custom-row">
                    <Col className="custom-col" sm={12} lg={6}>
                        <h2 className="pfm-subtitle">{t('title.statistics')}</h2>
                        {requestTracker.loadStats ?
                            <Spinner animation="border"/>
                            :
                            <PagesStatsList pages={pagesList}/>
                        }
                    </Col>
                </Row>
            </Container>
        </StatsLayout>
    );
};

export default Stats;