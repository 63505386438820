import {forwardRef, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Button, Form, FormControl, FormGroup, FormLabel, Spinner} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Cropper from "react-easy-crop";
import {useTranslation} from "react-i18next";

import {requestTrackerSelector, userSelector, wizardPopupSelector} from "../../redux/selectors";
import {hideWizardPopup} from "../../redux/wizard/actions";

import {appRoutes, regExp} from "../../helpers/defaults";
import {convertImageToFile, getCroppedImage} from "../../helpers/imageUtils";

import {useForm, useOutsideClick} from "../../hooks";

import {FieldWrapper, PasswordFieldWrapper} from "../FieldWrappers";
import CloseButton from "../CloseButton";
import {onRequestAction} from "../../redux/requestTracker/actions";
import {REQUESTED_PAGE_CHECK, REQUESTED_UPLOAD} from "../../redux/types";
import {APICheckPagePath, APIUpload} from "../../api";
import {setPageProperties} from "../../redux/editor/actions";
import {LANDING_URL, PAGES_URL} from "../../helpers/services";
import Validation from "../Validation";
import {editProfile} from "../../redux/user/actions";
import logo from "../../assets/logo.svg";
import {sendAnalytics} from "../../Analytics";

const PopupWrapper = forwardRef(({opened, children}, ref) => {
    return (
        <div className={`popup${opened ? ' popup--opened' : ''}`} ref={ref}>
            {children}
        </div>
    );
});

export const WizardPopup = () => {
    const dispatch = useDispatch();
    const opened = useSelector(wizardPopupSelector);
    const elementRef = useRef(null);
    const containerRef = useRef(null);
    const history = useHistory();
    const {t} = useTranslation();

    useOutsideClick(elementRef, containerRef, () => {
        dispatch(hideWizardPopup());
    });

    return (
        <PopupWrapper opened={opened} ref={containerRef}>
            <div className="wizard-popup" ref={elementRef}>
                <div className="wizard-popup__title">
                    <span className="pfm-title">{t('popup.wizard.title')}</span>
                </div>
                <div className="wizard-popup__text">
                    {t('popup.wizard.text')}
                </div>
                <div className="wizard-popup__buttons-wrapper">
                    <Button variant="outline-primary" className="wizard-popup__button" onClick={() => {
                        dispatch(hideWizardPopup());
                        sendAnalytics('pop_up_skip');
                    }}>
                        {t('button.skip')}
                    </Button>
                    <Button className="wizard-popup__button" onClick={() => {
                        history.push(appRoutes.wizard);
                        dispatch(hideWizardPopup());
                        sendAnalytics('pop_up_try');
                    }}>
                        {t('button.try')}
                    </Button>
                </div>
            </div>
        </PopupWrapper>
    );
};

export const CreatePageByTemplatePopup = ({state, setState}) => {
    const elementRef = useRef(null);
    const containerRef = useRef(null);
    const requestTracker = useSelector(requestTrackerSelector);
    const {t} = useTranslation();

    const form = useForm({
        values: useMemo(() => {
            return {
                title: state.initial
            }
        }, [state.initial]),
        validation: {
            title: {
                required: true,
                error: t('validation.default')
            }
        },
        onSubmit: () => {
            if (state.callback)
                state.callback(form.state.title);
        }
    });

    useOutsideClick(elementRef, containerRef, () => {setState({...state, opened: false})});

    return (
        <PopupWrapper opened={state.opened} ref={containerRef}>
            <div className="templates__popup" ref={elementRef}>
                <div className="templates__popup__title">
                    <span className="pfm-subtitle">{t('title.createPage')}</span>
                    <CloseButton onClick={() => {setState({...state, opened: false})}}/>
                </div>
                <div className="templates__popup__text">
                    {t('popup.createByTemplate.text')}
                </div>
                <div className="templates__popup__form">
                    <Form noValidate className="templates__popup__form" onSubmit={form.handleSubmit}>
                        <FieldWrapper
                            type="text"
                            name="title"
                            value={form.state.title}
                            placeholder={t('placeholder.pageTitlePopup')}
                            validation={form.errors.title}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                        />
                        <Button
                            type="submit"
                            className="primary-btn templates__popup__form__button"
                            disabled={requestTracker.editor.createPage}
                        >
                            {requestTracker.editor.createPage ?
                                <Spinner animation="border" size="sm"/>
                                :
                                t('button.continue')
                            }
                        </Button>
                    </Form>
                </div>
            </div>
        </PopupWrapper>
    );
};

export const PromptPopup = ({state, setState}) => {
    const elementRef = useRef(null);
    const containerRef = useRef(null);
    const {t} = useTranslation();

    useOutsideClick(elementRef, containerRef, () => {setState({...state, opened: false})});

    return (
        <PopupWrapper opened={state.opened} ref={containerRef}>
            <div className="prompt-popup" ref={elementRef}>
                <div className="prompt-popup__message">
                    {state.message}
                </div>
                <div className="prompt-popup__buttons-wrapper">
                    <Button
                        type="button"
                        className="primary-btn light-btn prompt-popup__button prompt-popup__button--cancel"
                        onClick={() => {
                            setState({...state, opened: false});
                        }}
                    >
                        {t('button.no')}
                    </Button>
                    <Button
                        type="button"
                        className="primary-btn prompt-popup__button"
                        onClick={() => {
                            if(state.onConfirm)
                                state.onConfirm();

                            setState({...state, opened: false});
                        }}
                    >
                        {t('button.yes')}
                    </Button>
                </div>
            </div>
        </PopupWrapper>
    );
};

export const CropperPopup = ({state, setState}) => {
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const elementRef = useRef(null);
    const containerRef = useRef(null);
    const {t} = useTranslation();

    const handleCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleClose = useCallback(() => {
        setState(state => ({
            ...state,
            image: null
        }));
    }, [setState]);

    const createCroppedImage = useCallback(async () => {
        if (state.setCroppedImage) {
            try {
                const image = await getCroppedImage(state.image, croppedAreaPixels, state.filetype);
                const imageFile = await convertImageToFile(image, state.filename);
                state.setCroppedImage(state.targetName, imageFile);
            } catch (e) {
                state.setCroppedImage(state.targetName, null);
            }
        }
    }, [croppedAreaPixels, state]);

    useOutsideClick(elementRef, containerRef, handleClose);

    return (
        <PopupWrapper opened={state.image} ref={containerRef}>
            <div className="cropper" ref={elementRef}>
                <div className="cropper__close-wrapper">
                    <CloseButton onClick={handleClose}/>
                </div>
                <div className="cropper__wrapper">
                    <Cropper
                        image={state.image}
                        crop={state.crop}
                        zoom={state.zoom}
                        aspect={state.aspect}
                        onCropChange={(newCrop) => {
                            setState({
                                ...state,
                                crop: newCrop
                            });
                        }}
                        onZoomChange={(value) => {
                            setState({
                                ...state,
                                zoom: value
                            });
                        }}
                        onCropComplete={handleCropComplete}
                        cropShape={state.cropShape}
                    />
                </div>
                <div className="cropper__btn-wrapper">
                    <Button size="sm" className="secondary-btn blue-btn" onClick={async () => {
                        await createCroppedImage();
                        handleClose();
                    }}>
                        {t('button.save')}
                    </Button>
                    <Button size="sm" variant="light" className="secondary-btn outline-light-btn" onClick={handleClose}>
                        {t('button.cancel')}
                    </Button>
                </div>
            </div>
        </PopupWrapper>
    );
};

export const PageSettingsPopup = ({state, setState}) => {
    const dispatch = useDispatch();
    const requestTracker = useSelector(requestTrackerSelector);
    const elementRef = useRef(null);
    const containerRef = useRef(null);
    const {t} = useTranslation();

    const form = useForm({
        values: useMemo(() => {
            return {
                title: state.page ? state.page.title : '',
                path: state.page ? state.page.path : ''
            }
        }, [state.page]),
        validation: {
            title: {
                required: true,
                error: t('validation.default')
            },
            path: {
                required: true,
                error: t('validation.default'),
                async: {
                    callback: async () => {
                        dispatch(onRequestAction(REQUESTED_PAGE_CHECK, true));
                        const response = await APICheckPagePath(state.page.id, form.state.path)
                            .then(() => true)
                            .catch(() => false);
                        dispatch(onRequestAction(REQUESTED_PAGE_CHECK, false));
                        return response;
                    },
                    error: t('validation.URLTaken')
                }
            }
        },
        onSubmit: async () => {
            await dispatch(setPageProperties({
                id: state.page.id,
                settings: {
                    title: form.state.title,
                    path: form.state.path
                }
            }));
            setState({...state, opened: false});
        },
        onReset: () => {
            setState({...state, opened: false});
        }
    });

    useOutsideClick(elementRef, containerRef, () => {
        form.handleReset();
    });

    return (
        <PopupWrapper opened={state.opened} ref={containerRef}>
            <div className="page-settings" ref={elementRef}>
                <div className="page-settings__title">
                    <span>{t('title.pageSettings')}</span>
                    <CloseButton onClick={() => {setState({...state, opened: false})}}/>
                </div>
                <Form
                    noValidate
                    className="page-settings__form"
                    onSubmit={form.handleSubmit}
                    onReset={form.handleReset}
                >
                    <FieldWrapper
                        type="text"
                        name="title"
                        label={t('label.pageTitle')}
                        value={form.state.title}
                        validation={form.errors.title}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                    />
                    <FormGroup controlId="path">
                        <FormLabel className="label">{t('label.pageURL')}</FormLabel>
                        <div className="page-settings__form__path">
                            <span className="page-settings__form__path__domain">
                                {PAGES_URL.split('pfm')[0]}
                            </span>
                            <FormControl
                                className="field"
                                type="text"
                                name="path"
                                value={form.state.path}
                                isInvalid={form.errors.path}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                            />
                            <span className="page-settings__form__path__domain">
                                .{PAGES_URL.split('//')[1]}
                            </span>
                        </div>
                        {form.errors.path &&
                        <Validation>
                            {form.errors.path}
                        </Validation>
                        }
                    </FormGroup>
                    <div className="page-settings__form__buttons-wrapper">
                        <Button
                            type="submit"
                            size="sm"
                            className="secondary-btn blue-btn"
                            disabled={!form.modified || requestTracker.editor.checkPagePath}
                        >
                            {t('button.save')}
                        </Button>
                        <Button type="reset" variant="outline-light" size="sm" className="secondary-btn outline-light-btn">
                            {t('button.cancel')}
                        </Button>
                    </div>
                </Form>
            </div>
        </PopupWrapper>
    )
};

export const SetPasswordPopup = ({state, setState}) => {
    const dispatch = useDispatch();

    const user = useSelector(userSelector);
    const requestTracker = useSelector(requestTrackerSelector);

    const [success, setSuccess] = useState(false)

    const {t} = useTranslation();

    useEffect(() => {
        if(success)
            setTimeout(() => {
                setState(false)
                if(!state.opened){
                    setSuccess(false)
                }
            }, 3000)

    },[success])

    const form = useForm({
        values: useMemo(() => {
            return {
                password: '',
                passwordConfirm: '',
            }
        }, [user]),
        validation: {
            password: {
                callback: () => form.state.password ? regExp.password.test(form.state.password) : true,
                error: t('validation.default')
            },
            passwordConfirm: {
                callback: () => form.state.password === form.state.passwordConfirm,
                error: t('validation.passwordCompare')
            }
        },
        onSubmit: async () => {
            dispatch(editProfile({
                password: form.state.password || null,
                password_confirmation: form.state.passwordConfirm || null,
            }));
            setSuccess(true)
        }
    });

    return (
        <PopupWrapper opened={state.opened}>
            <div className='password-popup'>
                <header className="default__header">
                    <img src={logo} alt="PFM Link" className="default__logo"/>
                </header>
                <div className='password-popup-container'>
                    {success ?
                        <div className='password-popup__success'>
                            <p className='password-popup__success-text'>{t('popup.setPassword.successMessage')}</p>
                        </div>
                        :
                        <>
                            <div className='password-popup__title'>
                                <span>{t('label.completionRegistration')}</span>
                            </div>
                            <Form className="profile__form" onSubmit={form.handleSubmit}>
                                <PasswordFieldWrapper
                                    className="mb-0"
                                    style={{
                                        maxWidth: '264px'
                                    }}
                                    name="password"
                                    value={form.state.password}
                                    label={t('label.setPassword')}
                                    validation={form.errors.password}
                                    onChange={form.handleChange}
                                    onBlur={form.handleBlur}
                                />
                                <div className="form__help-label">
                                    <span>{t('desc.password')}</span>
                                </div>
                                <PasswordFieldWrapper
                                    style={{
                                        maxWidth: '264px'
                                    }}
                                    name="passwordConfirm"
                                    value={form.state.passwordConfirm}
                                    label={t('label.repeatPassword')}
                                    onChange={form.handleChange}
                                    onBlur={form.handleBlur}
                                    validation={form.errors.passwordConfirm}
                                />
                                <Button
                                    type="submit"
                                    className="primary-btn profile__form__save-btn"
                                    disabled={requestTracker.updateUser || !form.modified}
                                >
                                    {requestTracker.updateUser || requestTracker.upload ?
                                        <Spinner animation={"border"} size={"sm"}/>
                                        :
                                        t('button.continue')
                                    }
                                </Button>
                            </Form>
                        </>
                    }
                </div>
            </div>
        </PopupWrapper>
    )
}