import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import thunk from "redux-thunk";
import alert from "./alert/reducer";
import requestTracker from "./requestTracker/reducer";
import user from "./user/reducer";
import editor from "./editor/reducer";
import wizard from "./wizard/reducer";
import templates from "./templates/reducer";
import news from "./news/reducer";
import stats from "./stats/reducer";
import perfluence from "./perfluence/reducer";
import editorDesign from "./editorDesign/reducer";

import {MODE, NODE_ENV} from "../helpers/const";
import {BASE_URL} from "../helpers/services";

const rootReducer = combineReducers({
    requestTracker,
    alert,
    user,
    editor,
    wizard,
    templates,
    news,
    stats,
    perfluence,
    editorDesign
});

const enhancer = (NODE_ENV === MODE.DEV || BASE_URL === 'https://my.pfmlink.tech') && window.__REDUX_DEVTOOLS_EXTENSION__ ?
    compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__()) :
    applyMiddleware(thunk);

const store = createStore(rootReducer, enhancer);

export default store;