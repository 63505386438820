import {forwardRef} from "react";

export const MenuPopup = forwardRef(({isOpened, children}, ref) => {
    return (
        <div className={`menu-popup${isOpened ? ' menu-popup--opened' : ''}`} ref={ref}>
            {children}
        </div>
    );
});

export const MenuPopupTitle = ({children}) => {
    return (
        <div className="menu-popup__title">
            {children}
        </div>
    )
};

export const MenuPopupList = ({children}) => {
    return (
        <ul className="menu-popup__list">
            {children}
        </ul>
    )
};

export const MenuPopupItem = ({children, disabled}) => {
    return (
        <li className={`menu-popup__list__item${disabled ? ' menu-popup__list__item--disabled' : ''}`}>
            {children(disabled)}
        </li>
    );
};