import {STATIC_URL} from "./services";
import axios from "axios";

const MAX_WIDTH_DESKTOP = 1920;
const MAX_WIDTH_TABLET = 1200;
const MAX_WIDTH_MOBILE = 768;

export const createImage = url =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', error => reject(error));
        image.setAttribute('crossOrigin', 'anonymous');
        image.src = url;
        return image;
    });

export const calcDeviceDimensions = (image) => {
    const desktop = image.width < MAX_WIDTH_DESKTOP ?
        [image.width, image.height] : [MAX_WIDTH_DESKTOP, Math.round(MAX_WIDTH_DESKTOP / image.width * image.height)];
    const tablet  = image.width < MAX_WIDTH_TABLET ?
        [image.width, image.height] : [MAX_WIDTH_TABLET, Math.round(MAX_WIDTH_TABLET / image.width * image.height)];
    const mobile = image.width < MAX_WIDTH_MOBILE ?
        [image.width, image.height] : [MAX_WIDTH_MOBILE, Math.round(MAX_WIDTH_MOBILE / image.width * image.height)];

    return [desktop, tablet, mobile];
};

export const getCroppedImage = async (url, cropped, type) => {
    const image = await createImage(url);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = cropped.width;
    canvas.height = cropped.height;

    ctx.drawImage(image, cropped.x, cropped.y, cropped.width, cropped.height, 0, 0, cropped.width, cropped.height);

    return new Promise(resolve => {
        canvas.toBlob(file => {
            resolve(URL.createObjectURL(file));
        }, type);
    });
};

export const prepareImageURL = (image) => {
    if (!image)
        return null;

    if (image instanceof File)
        return URL.createObjectURL(image);

    if (image.startsWith('/images/'))
        return `${STATIC_URL}/storage${image}`;

    if (image.startsWith('/users/') || image.startsWith('/img/'))
        return STATIC_URL + image;

    return image;
};

export const convertImageToFile = async (blobURL, filename) => {
    const response = await axios.get(blobURL, {
        responseType: 'arraybuffer'
    });
    return new File([response.data], filename, {type: response.headers['content-type']});
};

export const getImageMimeType = (name) => {
    if ((/\.(gif)$/i).test(name))
        return 'image/gif';

    if ((/\.(jpe?g)$/i).test(name))
        return 'image/jpeg';

    return 'image/png';
};