import {useCallback, useEffect, useMemo} from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Col, Row, Spinner} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {loadStatsById} from "../../redux/stats/actions";
import {editorSelector, requestTrackerSelector} from "../../redux/selectors";

import {chartAxisDateUnits, prepareChartProps, statsFilters, statsFiltersArrays} from "../../helpers/statsUtils";
import {appRoutes} from "../../helpers/defaults";

import ChartLayout from "../ChartLayout";
import {DateIntervalFieldWrapper, SelectFieldWrapper} from "../FieldWrappers";

const StatsItemLayout = ({link, title, children, report}) => {
    const requestTracker = useSelector(requestTrackerSelector);

    return (
        <div className={`stats__item${report ? ' stats__item--no-border' : ''}`}>
            {!report &&
            <div className="stats__item__title">
                <Link to={link} className="pfm-anchor">{title}</Link>
            </div>
            }
            <div className="stats__item__content">
                {requestTracker.loadStats ?
                    <div className="stats__item__content__placeholder">
                        <Spinner animation="border"/>
                    </div>
                    :
                    children
                }
            </div>
        </div>
    );
};

const StatsTable = ({titles, data}) => {
    const {t} = useTranslation();

    return (
        <div className={titles ? "stats__table" : "stats__table stats__table--mt12"}>
            {data && Object.entries(data).length ?
                <>
                    {titles &&
                    <div className="stats__table__head">
                        {
                            titles.map((item, index) => (
                                <span className="stats__table__head__item" key={index}>
                            {item}
                        </span>
                            ))
                        }
                    </div>
                    }
                    {data &&
                    <ul className="stats__table__body">
                        {
                            Object.entries(data).map((item, index) => (
                                <li className="stats__table__body__item" key={index}>
                                    <a href={item[0]} className="pfm-anchor" target="_blank"
                                       rel="noreferrer">{item[0]}</a>
                                    <span className="chart__legend__item__right">{item[1]}</span>
                                </li>
                            ))
                        }
                    </ul>
                    }
                </>
                :
                <div className="stats__item__content__placeholder">
                    {t('pages.stats.noData')}
                </div>
            }
        </div>
    );
};

export const StatsPageItem = ({id, page, filters, setFilters, setNotFound, chartType}) => {
    const dispatch = useDispatch();
    const editor = useSelector(editorSelector);
    const {t} = useTranslation();

    const title = useMemo(() => {
        const page = editor.pages.find(item => item.id === +id);
        if (page)
            return page.title;
        return '';
    }, [id, editor.pages]);

    const onError = useCallback(() => {
        setNotFound(true);
    }, [setNotFound]);

    const lineChartProps = useMemo(() => {
        if (page && page[filters.period]) {
            return prepareChartProps(
                filters.period,
                filters.interval.from,
                filters.interval.to,
                page[filters.period].date
            );
        }
        return {
            labels: [],
            data: []
        }
    }, [page, filters]);

    useEffect(() => {
        if (!page && filters.period !== statsFilters.interval) {
            dispatch(loadStatsById({
                id,
                params: {
                    period: filters.period
                },
                onError
            }));
        } else if (!page && filters.period === statsFilters.interval) {
            dispatch(loadStatsById({
                id,
                params: {
                    period: filters.period,
                    from: filters.interval.from,
                    to: filters.interval.to
                },
                onError
            }));
        } else if (
            page &&
            !page[filters.period] &&
            filters.period !== statsFilters.interval
        ) {
            dispatch(loadStatsById({
                id,
                params: {
                    period: filters.period
                },
                onError
            }));
        } else if (
            (page && !page[filters.period] && filters.period === statsFilters.interval) ||
            (filters.period === statsFilters.interval &&
                (filters.interval.from !== page[filters.period].from || filters.interval.to !== page[filters.period].to)
            )
        ) {
            dispatch(loadStatsById({
                id,
                params: {
                    period: filters.period,
                    from: filters.interval.from,
                    to: filters.interval.to
                },
                onError
            }));
        }
    }, [page, filters, dispatch, onError, id]);

    return (
        <>
            <Row className="custom-row">
                <Col className="custom-col" sm={12} lg={6}>
                    <h2 className="pfm-subtitle stats__page-title">{chartType ? t(`title.${chartType}`) : title}</h2>
                    <div className="stats__filters">
                        <SelectFieldWrapper
                            name="period"
                            label={t('label.period')}
                            className="form-group--stats"
                            value={filters.period}
                            options={statsFiltersArrays}
                            onChange={(option) => {
                                setFilters({
                                    ...filters,
                                    period: option.value
                                });
                            }}
                        />
                        {filters.period === statsFilters.interval &&
                        <DateIntervalFieldWrapper
                            name="interval"
                            valueFrom={filters.interval.from}
                            valueTo={filters.interval.to}
                            onChangeFrom={(date) => {
                                const res = date.format('YYYY-MM-DD');
                                setFilters({
                                    ...filters,
                                    interval: {
                                        to: res > filters.interval.to ? res : filters.interval.to,
                                        from: res
                                    }
                                });
                            }}
                            onChangeTo={(date) => {
                                const res = date.format('YYYY-MM-DD');
                                setFilters({
                                    ...filters,
                                    interval: {
                                        to: res,
                                        from: res < filters.interval.from ? res : filters.interval.from
                                    }
                                });
                            }}
                        />
                        }
                    </div>
                </Col>
            </Row>
            <Row className="custom-row">
                {(!chartType || chartType === 'visitors') &&
                <Col className="custom-col" sm={12} lg={6}>
                    <StatsItemLayout
                        link={`${appRoutes.stats}/${id}/visitors`}
                        title={t('title.visitors')}
                        report={chartType}
                    >
                        <ChartLayout
                            id={`chart-visitors-${id}`}
                            title={t('title.visitors')}
                            type="line"
                            labels={lineChartProps.labels}
                            datasets={[
                                {
                                    backgroundColor: 'rgba(255,99,132,0.5)',
                                    borderColor: '#FF6384',
                                    data: lineChartProps.data,
                                    label: t('title.visitors')
                                }
                            ]}
                            options={{
                                datasets: {
                                    line: {
                                        pointRadius: lineChartProps.labels.length >= 90 ? 0 : 3
                                    }
                                },
                                scales: {
                                    x: {
                                        type: 'time',
                                        time: {
                                            unit: chartAxisDateUnits[filters.period],
                                            tooltipFormat: 'DD MMM YYYY',
                                            displayFormats: {
                                                day: 'DD MMM',
                                                week: 'DD MMM',
                                                month: 'DD MMM YYYY',
                                                year: 'MMM YYYY'
                                            }
                                        }
                                    },
                                    y: {
                                        type: 'linear',
                                        min: 0,
                                        suggestedMax: 5,
                                        ticks: {
                                            stepSize: 1
                                        }
                                    }
                                }
                            }}
                        >
                            <div className="chart__legend__item">
                                <div className="chart__legend__item__left">
                                    <span
                                        className="chart__legend__item__color"
                                        style={{
                                            backgroundColor: '#FF6384'
                                        }}
                                    />
                                    <span>{t('title.visitors')}</span>
                                </div>
                                <div className="chart__legend__item__right">
                                    {(page && page[filters.period] && page[filters.period].total_visitors) || 0}
                                </div>
                            </div>
                            <div className="chart__legend__item">
                                <div className="chart__legend__item__left">
                                    <span
                                        className="chart__legend__item__color"
                                        style={{
                                            backgroundColor: '#75A3CD'
                                        }}
                                    />
                                    <span>{t('common.views')}</span>
                                </div>
                                <div className="chart__legend__item__right">
                                    {(page && page[filters.period] && page[filters.period].total_view) || 0}
                                </div>
                            </div>
                        </ChartLayout>
                    </StatsItemLayout>
                </Col>
                }
                {(!chartType || chartType === 'devices') &&
                <Col className="custom-col" sm={12} lg={6}>
                    <StatsItemLayout
                        link={`${appRoutes.stats}/${id}/devices`}
                        title={t('title.devices')}
                        report={chartType}
                    >
                        <ChartLayout
                            id={`chart-devices-${id}`}
                            title={t('title.devices')}
                            type="doughnut"
                            labels={[t('pages.stats.mobile'), t('pages.stats.desktop')]}
                            datasets={[{
                                backgroundColor: ['#FF6384', '#FF9F40'],
                                data: [
                                    (page && page[filters.period] && page[filters.period].device_types.phone) || 0,
                                    (page && page[filters.period] && page[filters.period].device_types.desktop) || 0
                                ]
                            }]}
                        >
                            <div className="chart__legend__item">
                                <div className="chart__legend__item__left">
                                    <span
                                        className="chart__legend__item__color"
                                        style={{
                                            backgroundColor: '#FF9F40'
                                        }}
                                    />
                                    <span>{t('pages.stats.desktop')}</span>
                                </div>
                                <div className="chart__legend__item__right">
                                    {(page && page[filters.period] && page[filters.period].device_types.desktop) || 0}
                                </div>
                            </div>
                            <div className="chart__legend__item">
                                <div className="chart__legend__item__left">
                                    <span
                                        className="chart__legend__item__color"
                                        style={{
                                            backgroundColor: '#FF6384'
                                        }}
                                    />
                                    <span>{t('pages.stats.mobile')}</span>
                                </div>
                                <div className="chart__legend__item__right">
                                    {(page && page[filters.period] && page[filters.period].device_types.phone) || 0}
                                </div>
                            </div>
                        </ChartLayout>
                    </StatsItemLayout>
                </Col>
                }
                {(!chartType || chartType === 'conversions') &&
                <Col className="custom-col" sm={12} lg={6}>
                    <StatsItemLayout
                        link={`${appRoutes.stats}/${id}/conversions`}
                        title={t('title.conversions')}
                        report={chartType}
                    >
                        <StatsTable
                            titles={chartType ? [t('common.link'), t('title.conversions')] : null}
                            data={page && page[filters.period] && page[filters.period].clicks}
                        />
                    </StatsItemLayout>
                </Col>
                }
            </Row>
        </>
    );
};
