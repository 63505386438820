import camelCase from "lodash/camelCase";

import {blockTypes, defaultImagePaths, designTypes, fieldTypes} from "./defaults";
import {prepareImageURL} from "./imageUtils";

import {ReactComponent as IconSocialsFacebook} from "../assets/socials/fb.svg";
import {ReactComponent as IconSocialsTwitter} from "../assets/socials/twitter.svg";
import {ReactComponent as IconSocialsInstagram} from "../assets/socials/instagram.svg";
import {ReactComponent as IconSocialsYoutube} from "../assets/socials/youtube.svg";
import {ReactComponent as IconSocialsTelegram} from "../assets/socials/telegram.svg";
import {ReactComponent as IconSocialsVK} from "../assets/socials/vk.svg";
import {ReactComponent as IconSocialsFacebookMessenger} from "../assets/socials/fbmessenger.svg";
import {ReactComponent as IconSocialsTikTok} from "../assets/socials/tt.svg";
import {ReactComponent as IconSocialsWhatsApp} from "../assets/socials/whatsapp.svg";
import {ReactComponent as IconSocialsViber} from "../assets/socials/viber.svg";
import {ReactComponent as IconSocialsSpotify} from "../assets/socials/spotify.svg";
import {ReactComponent as IconSocialsSkype} from "../assets/socials/skype.svg";
import {ReactComponent as IconSocialsSnapchat} from "../assets/socials/snapchat.svg";
import {ReactComponent as IconSocialsFlickr} from "../assets/socials/flickr.svg";
import {ReactComponent as IconSocialsLinkedIn} from "../assets/socials/linkedin.svg";
import {ReactComponent as IconSocialsMySpace} from "../assets/socials/myspace.svg";
import {ReactComponent as IconSocialsSinaWeibo} from "../assets/socials/weibo.svg";
import {ReactComponent as IconSocialsTumblr} from "../assets/socials/tumblr.svg";
import {ReactComponent as IconSocialsOK} from "../assets/socials/ok.svg";

import {ReactComponent as IconSocialsOtherShare} from "../assets/socials/other-share.svg";

const socialLinkTypes = {
    url: 'url',
    nickname: 'nickname',
    tel: 'tel'
};

export const socialProps = {
    // 'instagram': {
    //     icon: <IconSocialsInstagram className="socials__icon"/>,
    //     link: 'username',
    //     type: socialLinkTypes.nickname
    // },
    'tg': {
        icon: <IconSocialsTelegram className="socials__icon"/>,
        link: 'username',
        type: socialLinkTypes.nickname
    },
    'vk': {
        icon: <IconSocialsVK className="socials__icon"/>,
        link: 'username',
        type: socialLinkTypes.nickname
    },
    // 'facebook': {
    //     icon: <IconSocialsFacebook className="socials__icon"/>,
    //     link: 'username',
    //     type: socialLinkTypes.nickname
    // },
    // 'facebook-messenger': {
    //     icon: <IconSocialsFacebookMessenger className="socials__icon"/>,
    //     link: '+79991234567',
    //     type: socialLinkTypes.tel
    // },
    'tiktok': {
        icon: <IconSocialsTikTok className="socials__icon"/>,
        link: 'username',
        type: socialLinkTypes.nickname
    },
    'twitter': {
        icon: <IconSocialsTwitter className="socials__icon"/>,
        link: 'username',
        type: socialLinkTypes.nickname
    },
    'whatsapp': {
        icon: <IconSocialsWhatsApp className="socials__icon"/>,
        link: '+79991234567',
        type: socialLinkTypes.tel
    },
    'viber': {
        icon: <IconSocialsViber className="socials__icon"/>,
        link: '+79991234567',
        type: socialLinkTypes.tel
    },
    'spotify': {
        icon: <IconSocialsSpotify className="socials__icon"/>,
        link: 'https://spotify.com/username',
        type: socialLinkTypes.url
    },
    'skype': {
        icon: <IconSocialsSkype className="socials__icon"/>,
        link: 'username',
        type: socialLinkTypes.nickname
    },
    'snapchat': {
        icon: <IconSocialsSnapchat className="socials__icon"/>,
        link: 'username',
        type: socialLinkTypes.nickname
    },
    'youtube': {
        icon: <IconSocialsYoutube className="socials__icon"/>,
        link: 'https://youtube.com/username',
        type: socialLinkTypes.url
    },
    'flickr': {
        icon: <IconSocialsFlickr className="socials__icon"/>,
        link: 'https://flickr.com/',
        type: socialLinkTypes.url
    },
    'linkedin': {
        icon: <IconSocialsLinkedIn className="socials__icon"/>,
        link: 'https://linkedin.com/',
        type: socialLinkTypes.url
    },
    'myspace': {
        icon: <IconSocialsMySpace className="socials__icon"/>,
        link: 'https://myspace.com/username',
        type: socialLinkTypes.url
    },
    'sina_weibo': {
        icon: <IconSocialsSinaWeibo className="socials__icon"/>,
        link: 'https://weibo.com/username',
        type: socialLinkTypes.url
    },
    'tumblr': {
        icon: <IconSocialsTumblr className="socials__icon"/>,
        link: 'https://tumblr.com/username',
        type: socialLinkTypes.url
    },
    'ok': {
        icon: <IconSocialsOK className="socials__icon"/>,
        link: 'https://ok.ru/username',
        type: socialLinkTypes.url
    },
    'other': {
        icon: <IconSocialsOtherShare className="socials__icon"/>,
        link: 'https://example.com/',
        type: socialLinkTypes.url
    }
};

export const getIconByName = (name) => {
    return (socialProps[name] && socialProps[name].icon) || null;
};

export const reduceToEditableBlocks = (item) => {
    if (item.unpublished_changes.blocks) {
        if (!item.unpublished_changes.blocks.length)
            return [];

        return item.unpublished_changes.blocks.reduce((a, e) => {
            a.push({...e, data: Array.isArray(e.data) ? JSON.parse(JSON.stringify(e.data)) : {...e.data}});
            return a;
        },[]);
    }

    if (item.blocks && item.blocks.length) {
        return item.blocks.reduce((a, e) => {
            a.push({...e, data: Array.isArray(e.data) ? JSON.parse(JSON.stringify(e.data)) : {...e.data}});
            return a;
        },[]);
    }

    return [];
};

export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export const setSliderSpaceBetween = (pageStyle) => {
    if (
        pageStyle === designTypes.atoms.name ||
        pageStyle === designTypes.tiffany.name ||
        pageStyle === designTypes.eco.name ||
        pageStyle === designTypes.mosaic.name ||
        pageStyle === designTypes.aurora.name
    )
        return 16;

    if (
        pageStyle === designTypes.clouds.name ||
        pageStyle === designTypes.minimal.name ||
        pageStyle === designTypes.pastel.name
    )
        return 12;

    if (
        pageStyle === designTypes.graphite.name ||
        pageStyle === designTypes.autumn.name ||
        pageStyle === designTypes.pink.name
    )
        return 8;

    return 2;
};

export const getInvalidBlockProps = (type, props, defaults) => {
    if (!blockTypes[type]) {
        return {
            unknownType: true,
            unknownProps: [],
            missedProps: [],
            defaultProps: null
        };
    }

    const propsArr = Object.keys(props);
    const defaultsArr = Object.keys(defaults || []);
    const propsSet = new Set(propsArr);
    const defaultsSet = new Set(defaultsArr);

    return {
        unknownType: false,
        unknownProps: propsArr.filter(item => !defaultsSet.has(item)),
        missedProps: defaultsArr.filter(item => !propsSet.has(item)),
        defaultProps: defaults
    };
};

const defaultRule = ({type, data, blockFields, prefix}) => {
    return blockFields[type].reduce((a, item) => {
        a.push({
            ...item,
            name: `${prefix}.${item.name}`,
            value: data[item.name]
        });
        return a;
    }, []);
};

const blockFieldsRules = {
    [blockTypes.header]: defaultRule,
    [blockTypes.text]: defaultRule,
    [blockTypes.button]: defaultRule,
    [blockTypes.separator]: ({type, data, blockFields, prefix}) => {
        const fields = blockFields[type];

        return fields.reduce((a, item) => {
            if (item.type === fieldTypes.radio) {
                a.push({
                    ...item,
                    name: `${prefix}.${item.name}`,
                    value: data[item.name],
                    options: [
                        {
                            ...fields[0].options[0],
                            text:
                                <div className="divider-radios__label__wrapper">
                                    <div className="divider divider--sm divider--solid"/>
                                </div>
                        }, {
                            ...fields[0].options[1],
                            text:
                                <div className="divider-radios__label__wrapper">
                                    <div className="divider divider--sm divider--dashed"/>
                                </div>
                        }, {
                            ...fields[0].options[2],
                            text:
                                <div className="divider-radios__label__wrapper">
                                    <div className="divider divider--sm divider--dotted"/>
                                </div>
                        }, {
                            ...fields[0].options[3],
                            text:
                                <div className="divider-radios__label__wrapper">
                                    <div className="divider divider--zigzag"/>
                                </div>
                        }, {
                            ...fields[0].options[4],
                            text:
                                <div className="divider-radios__label__wrapper">
                                    <div className="divider divider--wave"/>
                                </div>
                        }
                    ]
                });
            } else {
                a.push({
                    ...item,
                    name: `${prefix}.${item.name}`,
                    value: data[item.name],
                });
            }
            return a;
        }, []);
    },
    [blockTypes.image]: defaultRule,
    [blockTypes.social]: ({type, data, blockFields, prefix}) => {
        const fields = blockFields[type];
        const currentSocials = Object.keys(socialProps);

        const availableOptions = fields[0].data[0].options.filter((option) => {
            if (option.value === currentSocials.find((social) => social === option.value)) {
                return true;
            }
        });

        return data.reduce((a, item, index) => {
            a.push(
                {
                    ...fields[0].data[0],
                    options: availableOptions.map((item) => {
                        return {
                            ...item,
                            label: !item.value.includes('other') ?
                                <span
                                    className="select__option-label">{socialProps[item.value].icon}<span>{item.label}</span></span>
                                :
                                <span
                                    className="select__option-label select__option-label--no-icon"><span>{item.label}</span></span>
                        };
                    }),
                    name: `${prefix}[${index}].${fields[0].data[0].name}`,
                    value: data[index].service
                }, {
                    ...fields[0].data[1],
                    type: socialProps[data[index].service].type,
                    label: fields[0].data[1].label[socialProps[data[index].service].type],
                    name: `${prefix}[${index}].${fields[0].data[1].name}`,
                    value: data[index].link
                }, {
                    ...fields[0].data[2],
                    name: `${prefix}[${index}].${fields[0].data[2].name}`,
                    value: data[index].icon,
                    options: [
                        {
                            value: 'other',
                            label: <span className="select__option-label">{socialProps.other.icon}</span>
                        }
                    ]
                }
            );
            return a;
        }, []);
    },
    [blockTypes.commercial]: ({type, data, blockFields, prefix}) => {
        return blockFields[type].reduce((a, item) => {
            if (item.name === 'blocks') {
                const blocks = data.blocks.reduce((a, item, index) => {
                    const itemFields = blockFields[item.type].reduce((a, innerItem) => {
                        a.push({
                            ...innerItem,
                            name: `${prefix}.blocks[${index}].data.${innerItem.name}`,
                            value: item.data[innerItem.name],
                        });
                        return a;
                    }, []);
                    a.push(...itemFields);
                    return a;
                }, []);
                a.push(...blocks);
            } else {
                a.push({
                    ...item,
                    name: `${prefix}.${item.name}`,
                    value: data[item.name]
                });
            }
            return a;
        }, []);
    },
    [blockTypes.gallery]: ({type, data, blockFields, prefix}) => {
        return blockFields[type].reduce((a, item) => {
            if (data.type === 'carousel' && item.name === 'grid_columns') {
                a.push({
                    ...item,
                    name: `${prefix}.${item.name}`,
                    value: data[item.name],
                    hidden: true
                });
            } else {
                a.push({
                    ...item,
                    name: `${prefix}.${item.name}`,
                    value: data[item.name]
                });
            }
            return a;
        }, []);
    },
    [blockTypes.timer]: defaultRule,
    [blockTypes.video]: defaultRule,
    [blockTypes.spoiler]: ({type, data, blockFields, prefix}) => {
        return blockFields[type].reduce((a, item) => {
            a.push({
                ...item,
                type: item.name === 'hidden' ? fieldTypes["rich-text"] : item.type,
                name: `${prefix}.${item.name}`,
                value: data[item.name]
            });
            return a;
        }, []);
    },
    [blockTypes.perfluence]: defaultRule,
    [blockTypes.html]: ({data, prefix}) => {
        return [{
            type: 'code',
            required: true,
            name: `${prefix}.code`,
            value: data.code
        }];
    },
};

const prepareBlockFields = ({type, data, blockFields, prefix}) => {
    if (blockFieldsRules[type])
        return blockFieldsRules[type]({type, data, blockFields, prefix});

    return [];
};

export const createEditorFormData = ({type, data, blockFields, prefix}) => {
    const state = {};
    const fields = prepareBlockFields({type, data, blockFields, prefix});

    /* Socials block rule */
    if (Array.isArray(data)) {
        const len = data.length;
        let i = 0;
        while (i < len) {
            for (const key in data[i]) {
                if (data[i].hasOwnProperty(key)) {
                    state[`${prefix}[${i}].${key}`] = data[i][key];
                }
            }
            i++;
        }
        return {
            state,
            fields
        };
    }

    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            /*Commercial block rule (exclude Gallery block 'images' prop) */
            if (Array.isArray(data[key]) && key !== 'images') {
                const len = data[key].length;
                let i = 0;
                while (i < len) {
                    for (const innerKey in data[key][i].data) {
                        if (data[key][i].data.hasOwnProperty(innerKey)) {
                            state[`${prefix}.${key}[${i}].data.${innerKey}`] = data[key][i].data[innerKey];
                        }
                    }
                    i++;
                }
            } else {
                state[`${prefix}.${key}`] = data[key];
            }
        }
    }

    return {
        state,
        fields
    };
};

export const createBlockBackground = (colors) => {
    if (!colors)
        return {};

    if (Array.isArray(colors) && colors.length) {
        if (colors.length > 1) {
            const str = colors.join(', ');
            return {
                background: `linear-gradient(to right, ${str})`
            };
        }
        return {
            background: colors[0]
        };
    }

    if (colors.startsWith(defaultImagePaths.commonPath)) {
        return {
            backgroundImage: `url(${prepareImageURL(colors)})`
        };
    }

    return {
        background: colors
    };
};

const exceptions = {
    background: 'background',
    backgroundImage: 'backgroundImage'
};

export const makeStyle = (configs, fallback = {}) => {
    if (!configs)
        return fallback;

    let tmp = {};

    for (const key in configs) {
        if (configs.hasOwnProperty(key)) {
            const keyCC = camelCase(key);

            switch (keyCC) {
                case exceptions.background:
                    tmp = {...tmp, ...createBlockBackground(configs[key])};
                    break;
                case exceptions.backgroundImage:
                    tmp[keyCC] = configs[key] ? `url(${prepareImageURL(configs[key])})` : null;
                    break;
                default:
                    tmp[keyCC] = configs[key] || null;
            }
        }
    }

    return tmp;
};