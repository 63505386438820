import {useLayoutEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {clearEditMode} from "../../redux/editor/actions";
import {clearCheckPagePath} from "../../redux/requestTracker/actions";
import {editorSelector, userSelector} from "../../redux/selectors";

import {getPagePublicationState} from "../../helpers/pagePublicationStateUtils";
import {deviceTypes, tabsSettings} from "../../helpers/defaults";

import {withCropperPopup, withPromptPopup} from "../../hoc/withPopup";

import AppLayout from "../../layouts/AppLayout";

import NotFound from "../NotFound";

import EditorTools from "../../components/EditorTools";
import EditorTabs from "../../components/EditorTabs";
import EditorWorkspace from "../../components/EditorWorkspace";
import EditorSidebar from "../../components/EditorSidebar";
import EditorPublication from "../../components/EditorPublication";

const Editor = ({cropperPopupState, cropperPopupStateSetter, promptPopupStateSetter}) => {
    const dispatch = useDispatch();
    const editor = useSelector(editorSelector);
    const user = useSelector(userSelector);
    const {pageId} = useParams();

    const [tab, setTab] = useState(tabsSettings[0]);
    const [device, setDevice] = useState(deviceTypes.mobile);
    const [isBlockModified, setBlockModified] = useState(false);
    const [submitTrigger, setSubmitTrigger] = useState(false);

    const page = useMemo(() => editor.pages.find(item => item.id === +pageId), [pageId, editor.pages]);
    const publicationState = useMemo(() => getPagePublicationState(page), [page]);

    useLayoutEffect(() => {
        setTab(tabsSettings[0]);
        setDevice(deviceTypes.mobile);

        return () => {
            dispatch(clearEditMode());
            dispatch(clearCheckPagePath());
        }
    }, [dispatch, pageId]);

    if (editor.pages.length && !page)
        return <NotFound/>;

    return (
        <AppLayout editorLayout={true}>
            <div className={`editor${editor.block ? ' editor--edit-mode' : ''}`}>
                <div className={`tools-panel${tab.index === 2 ? ' tools-panel--no-border' : ''}`}>
                    <EditorTabs
                        tabsSettings={tabsSettings}
                        currentTabIndex={tab.index}
                        setTab={tab => setTab(tabsSettings[tab])}
                        publicationState={publicationState}
                    />
                    <EditorTools
                        user={user}
                        currentTabIndex={tab.index}
                        page={page}
                        block={editor.block}
                    />
                </div>
                <div className={`editor__main${tab.index === 2 ? ' editor__main--height-fix' : ''}`}>
                    {tab.index === 2 ?
                        <EditorPublication
                            page={page}
                            publicationState={publicationState}
                            setPromptPopupState={promptPopupStateSetter}
                        />
                        :
                        <EditorWorkspace
                            user={user}
                            tab={tab}
                            page={page}
                            device={device}
                            setDevice={setDevice}
                            block={editor.block}
                            isBlockModified={isBlockModified}
                            setSubmitTrigger={setSubmitTrigger}
                            publicationState={publicationState}
                            setPromptPopupState={promptPopupStateSetter}
                        />
                    }
                    {(tab.index === 0 || tab.index === 1) &&
                    <EditorSidebar
                        tab={tab}
                        page={page}
                        block={editor.block}
                        blockFields={editor.blockTypes}
                        onClose={() => {
                            dispatch(clearEditMode())
                            setBlockModified(false);
                            setSubmitTrigger(false);
                        }}
                        setBlockModified={setBlockModified}
                        submitTrigger={submitTrigger}
                        setSubmitTrigger={setSubmitTrigger}
                        cropperState={cropperPopupState}
                        setCropperState={cropperPopupStateSetter}
                        setPromptPopupState={promptPopupStateSetter}
                    />

                    }
                </div>
            </div>
        </AppLayout>
    );
};

export default withPromptPopup(withCropperPopup(Editor));