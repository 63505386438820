import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useHistory, useParams} from "react-router-dom";

import {socialAuth} from "../../redux/user/actions";

import {useQuery} from "../../hooks";

import Loader from "../../components/Loader";

const SocialAuth = () => {
    const dispatch = useDispatch();
    const query = useQuery();
    const {provider} = useParams();
    const code = query.get('code');
    const history = useHistory();

    useEffect(() => {
        dispatch(socialAuth(provider, code, history));
    }, [dispatch, provider, code, history]);

    return <Loader/>;
};

export default SocialAuth;