import {savePage} from "../editor/actions";
import {
    USER_SETTINGS,
    LOAD_EDITABLE_STYLE,
    DESIGN_MODE_CLEAR
} from "../types";


export const userSettings = (key, value) => {
    return dispatch => {
        dispatch({type: USER_SETTINGS, payload: {key, value}})
    }
};

export const loadEditableStyle = (page) => {
    return dispatch => {
        dispatch({type: LOAD_EDITABLE_STYLE, payload: page})
    }

};

export const clearDesignMode = () => {
    return dispatch => {
        dispatch({type: DESIGN_MODE_CLEAR});
    }
}

const sortConfig = (data, page) => {
    const newData = [...page.style_config];

    let itemIndex = newData.find((item, i) => {
        if(item && item.editableTheme === data.editableTheme) {
            newData.splice(i, 1)
            return item
        } else {
            return false
        }
    })

    if(itemIndex) {
        itemIndex.configTheme = {...itemIndex.configTheme,...data.configTheme}
    } else {
        itemIndex = data
    }

    newData.push(itemIndex)

    return newData
}

export const saveStyleBlocks = ({pageId, data, page}) => {
    return async dispatch => {

        // const newData = [...page.style_config];
        //
        // let itemIndex = newData.find((item, i) => {
        //     if(item && item.editableTheme === data.editableTheme) {
        //         newData.splice(i, 1)
        //         return item
        //     } else {
        //         return false
        //     }
        // })
        //
        // if(itemIndex) {
        //     itemIndex.configTheme = {...itemIndex.configTheme,...data.configTheme}
        // } else {
        //     itemIndex = data
        // }
        //
        // newData.push(itemIndex)

        const newData = await sortConfig(data,page)

        dispatch(savePage({
            id: pageId,
            settings: {
                style_config: newData
            }
        }))

    }
};

