import {forwardRef, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Draggable} from "react-beautiful-dnd";
import {Accordion, Button as BootstrapButton, Spinner} from "react-bootstrap";
import parser, {attributesToProps, domToReact} from "html-react-parser";
import ReactPlayer from "react-player";
import SwiperCore, {Mousewheel, Pagination} from "swiper";
import {Swiper, SwiperSlide} from 'swiper/react';
import {useTranslation} from "react-i18next";

import {useCountdownTimer, useInvalidBlockProps} from "../../hooks";

import {
    clearEditMode,
    deleteBlock,
    setBlock,
    updateStoreBeforeBlockSave,
    updateStoreBeforeDelete
} from "../../redux/editor/actions";
import {editorSelector, requestTrackerSelector, userSelector} from "../../redux/selectors";

import {STATIC_URL} from "../../helpers/services";
import {getBlockConfigs, blockTypes, defaultImagePaths} from "../../helpers/defaults";
import {prepareImageURL} from "../../helpers/imageUtils";

import {createBlockBackground, makeStyle, getIconByName, socialProps} from "../../helpers/editorUtils";
import {getFormattedDateString} from "../../helpers/dateFormattingUtils";
import {setSliderSpaceBetween} from "../../helpers/editorUtils";
import {scrollToEditorComponent} from "../../helpers/scrollUtils";

import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

import iconDelete from "./media/icon-del.svg";
import iconPerfluence from "../../assets/icon-perfluence.svg";
// import iconHTML from "../../assets/blockTypeIcons/html.svg";
import iconHTML from "../../assets/icon-perfluence.svg";
import {copyToClipboard} from "../../helpers/clipboardUtils";
import iconCopyiconCopy from "../EditorPublication/media/icon-copy.svg";
import iconCopyNew from "../EditorPublication/media/copylink.svg";
import iconCopyDone from "../EditorPublication/media/done.svg";


const DeleteButton = ({blockIndex, pageId, disabled, setPromptPopupState}) => {
    const dispatch = useDispatch();
    const requestTracker = useSelector(requestTrackerSelector);
    const {t} = useTranslation();

    return (
        <BootstrapButton
            disabled={
                disabled ||
                requestTracker.upload ||
                requestTracker.editor.savePage ||
                requestTracker.editor.publishPage
            }
            variant="danger"
            className="editor__component__delete"
            onClick={(e) => {
                e.stopPropagation();
                setPromptPopupState({
                    opened: true,
                    message: t('popup.prompt.deleteBlock'),
                    onConfirm: () => {
                        dispatch(deleteBlock({
                            pageId,
                            index: blockIndex
                        }));
                        dispatch(clearEditMode());
                    }
                })
            }}
        >
            <img src={iconDelete} alt={t('button.delete')}/>
            <span>{t('button.delete')}</span>
        </BootstrapButton>
    );
};

const withEC = (Component) => {
    return forwardRef((props, ref) => {
        const dispatch = useDispatch();

        return (
            <div className={`editor__component${props.clicked ? ' editor__component--clicked' : ''}`}
                 onClick={() => {
                     if (props.clicked || props.currentTabIndex > 1) return;
                     dispatch(setBlock({index: props.index, type: props.type, data: props.data}));
                     scrollToEditorComponent(props.index, -100);
                 }}
                 ref={props.clicked ? ref : null}
                 id={props.id}
            >
                <Component data={props.data} pageStyle={props.style} clicked={props.clicked}
                           currentTabIndex={props.currentTabIndex}/>
                {props.currentTabIndex === 0 &&
                <DeleteButton blockIndex={props.index} pageId={props.pageId} disabled={props.isDragging}
                              setPromptPopupState={props.setPromptPopupState}/>
                }
            </div>
        );
    });
};

const withDraggableEC = (Component) => {
    return forwardRef((props, ref) => {
        const dispatch = useDispatch();
        const requestTracker = useSelector(requestTrackerSelector);

        if (props.clicked || props.currentTabIndex || requestTracker.upload) {
            return (
                <div
                    className={`editor__component${props.clicked ? ' editor__component--clicked' : ''}`}
                    ref={props.clicked ? ref : null}
                    id={props.id}
                    onClick={()=> {
                        if (props.clicked || props.currentTabIndex > 1) return;
                        dispatch(setBlock({index: props.index, type: props.type, data: props.data}));
                        scrollToEditorComponent(props.index, -100);
                    }}
                >
                    <Component data={props.data} pageStyle={props.style} clicked={props.clicked}
                               currentTabIndex={props.currentTabIndex}/>
                    {props.currentTabIndex === 0 &&
                    <DeleteButton blockIndex={props.index} pageId={props.pageId} onClick={props.onClick}
                                  disabled={props.isDragging} setPromptPopupState={props.setPromptPopupState}/>
                    }
                </div>
            );
        }

        return (
            <Draggable draggableId={`ECDraggable-${props.index}`} index={props.index}>
                {(provided, snapshot) => (
                    <div className="editor__component"
                         onClick={() => {
                             if (props.clicked || props.currentTabIndex) return;
                             dispatch(setBlock({index: props.index, type: props.type, data: props.data}));
                             scrollToEditorComponent(props.index, -100);
                         }}
                         ref={provided.innerRef}
                         {...provided.draggableProps}
                         {...provided.dragHandleProps}
                         style={{
                             ...provided.draggableProps.style,
                             boxShadow: snapshot.isDragging ? (
                                 props.style === 'graphite' ?
                                     '0 0 24px rgba(255,255,255,0.3)' : '0 0 24px rgba(0,0,0,0.2)'
                             ) : '',
                             background: snapshot.isDragging ? 'var(--page-bg-color)' : ' '
                         }}
                         id={props.id}
                    >
                        <Component data={props.data} pageStyle={props.style} clicked={props.clicked}
                                   currentTabIndex={props.currentTabIndex}/>
                        {props.currentTabIndex === 0 &&
                        <DeleteButton blockIndex={props.index} pageId={props.pageId} disabled={props.isDragging}
                                      setPromptPopupState={props.setPromptPopupState}/>
                        }
                    </div>
                )}
            </Draggable>
        );
    });
};

const withCommercialSubcomponent = (wrapperClassName) => (Component) => {
    return props => {
        return (
            <div className={wrapperClassName}>
                <Component data={props.data} parentConfigs={props.parentConfigs}/>
            </div>
        );
    };
};

const TextSubcomponent = ({data, parentConfigs}) => {
    const {text} = data;

    return (
        <>
            {text && parser(
                text,
                {
                    replace: ({attribs, children, name}) => {
                        if (parentConfigs && parentConfigs.link && attribs && name === 'a') {
                            const props = attributesToProps(attribs);
                            const style = makeStyle(parentConfigs.link);

                            return <a {...props} style={style}>{domToReact(children)}</a>;
                        }
                    }
                }
            )}
        </>
    );
};

const ButtonSubcomponent = ({data, parentConfigs}) => {
    const {url, title, desc, style} = data;
    const buttonStyle = makeStyle(parentConfigs ? parentConfigs.button : null);
    const descStyle = makeStyle(parentConfigs && parentConfigs.button ? parentConfigs.button.desc : null);

    return (
        <>
            <a
                href={url}
                className={`button${style ? ` button--${style}` : ''} rew`}
                onClick={(e) => {
                    e.preventDefault();
                }}
                style={buttonStyle}
            >
                {title}
            </a>
            {desc &&
            <small className="button__label" style={descStyle}>
                {desc}
            </small>
            }
        </>
    );
};

const TimerSubcomponent = ({data, parentConfigs}) => {
    const {date, size, format} = data;
    const {days, hours, minutes, seconds} = useCountdownTimer(date);
    const style = makeStyle(parentConfigs ? parentConfigs.timer : null);
    const {t} = useTranslation();

    return (
        <span className={`timer${size ? ` timer--${size}` : ''}`} style={style}>
            {getFormattedDateString({format, days, hours, minutes, seconds, t})}
        </span>
    );
};

const ImageContainer = ({clickable, alignment, url, children}) => {
    if (clickable)
        return (
            <a
                href={url || '#'}
                className={`image-container image-container--clickable${alignment ? ` image-container--${alignment}` : ''}`}
                onClick={e => {
                    e.preventDefault();
                }}
            >
                {children}
            </a>
        );

    return (
        <div className={`image-container${alignment ? ` image-container--${alignment}` : ''}`}>
            {children}
        </div>
    );
};

const ImageSubcomponent = ({data}) => {
    const {image, align, click_action, url} = data;
    const imageStr = useMemo(() => prepareImageURL(image), [image]);
    const {t} = useTranslation();

    const loading = useSelector(requestTrackerSelector).upload;
    const block = useSelector(editorSelector).block;

    const [imageBlock, setImageBlock] = useState(null);

    useEffect(()=>{
        if(block)
            setImageBlock(block.type)
    },[block])

    return (
        <ImageContainer clickable={click_action !== 'none'} alignment={align || 'center'} url={url || ''}>
            {image ?
                (loading && imageBlock === 'image'?
                    <div className={'d-flex justify-content-center align-items-center'} style={{minHeight: '178px', width: '100%'}}>
                        <Spinner animation={'border'}/>
                    </div>
                    :
                    <img className="image" src={imageStr} alt={imageStr}/>
                )
                :
                <span className="image__placeholder">{t('common.image')}</span>
            }
        </ImageContainer>
    );
};

const Header = ({data}) => {
    const {
        cover_enable,
        cover,
        cover_size,
        photo_enable,
        photo,
        photo_size,
        photo_align,
        name_enable,
        name,
        name_url
    } = data;
    const coverStr = useMemo(() => prepareImageURL(cover), [cover]);
    const photoStr = useMemo(() => prepareImageURL(photo), [photo]);
    const {t} = useTranslation();

    const loading = useSelector(requestTrackerSelector).upload;
    const block = useSelector(editorSelector).block;

    const [headerBlock, setHeaderBlock] = useState(null);

    useEffect(()=>{
        if(block) {
            setHeaderBlock(block.type)
        }
    },[block])

    return (
        loading && headerBlock === 'header' ?
            <div className='d-flex justify-content-center align-items-center' style={{minHeight: '226px'}}>
                <Spinner animation={'border'}/>
            </div>
            :
            <div className="pfm-page__header">
                {cover_enable &&
                <div className={`background-wrapper${cover_size ? ` background-wrapper--${cover_size}` : ''}`}
                     style={cover ? {backgroundImage: `url(${coverStr})`} : null}/>
                }
                <div className={
                    `avatar-wrapper${photo_size && photo_enable ? ` avatar-wrapper--${photo_size}` : ''}${photo_align && photo_enable ? ` avatar-wrapper--${photo_align}` : ''}${!photo_enable ? ` avatar-wrapper--photo-disabled` : ''}${!cover_enable ? ` avatar-wrapper--cover-disabled` : ''}`
                }>
                    {photo_enable &&
                    <div className="avatar__image-wrapper">
                        <img className="avatar"
                             src={photo ? photoStr : STATIC_URL + defaultImagePaths.avatarPlaceholderPath}
                             alt={t('common.avatar')}
                        />
                    </div>
                    }
                    {name_enable && name &&
                    <div className="avatar__username-wrapper">
                        <a href={name_url} className="avatar__username" onClick={e => {
                            e.preventDefault()
                        }}>{`@${name}`}</a>
                    </div>
                    }
                </div>
            </div>
    );
};

const Text = ({data}) => {
    return (
        <div className="pfm-page__component text-wrapper">
            <TextSubcomponent data={data}/>
        </div>
    );
};

const Button = ({data}) => {
    return (
        <div className="pfm-page__component button-wrapper">
            <ButtonSubcomponent data={data}/>
        </div>
    );
};

const Divider = ({data}) => {
    const {style, size} = data;
    return (
        <div className="pfm-page__component divider-wrapper">
            <div className={`divider${size ? ` divider--${size}` : ''}${style ? ` divider--${style}` : ''}`}/>
        </div>
    );
};

const Video = ({data}) => {
    const {video} = data;
    const [error, setError] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        setError(false);
    }, [video]);

    return (
        <div className="pfm-page__component video-wrapper">
            {video && !error ?
                <ReactPlayer
                    url={video}
                    width="100%"
                    height="100%"
                    controls={true}
                    onError={() => {
                        setError(true);
                    }}
                />
                :
                <div className="video-placeholder">
                    {error ? t('error.videoLoading') : t('error.videoLink')}
                </div>
            }
        </div>
    );
};

const Socials = ({data}) => {
    const availableSocials = Object.keys(socialProps);

    data.forEach((social) => {
        if (!availableSocials.find((item) => item === social.service)) {
            social.service = 'other';
            social.icon = 'other';
        }
    });

    return (
        <div className="pfm-page__component socials-wrapper">
            {data.map &&
            data.map((item, index) => (
                <a
                    className="socials"
                    href={item.link}
                    key={index + item.service}
                    onClick={e => {
                        e.preventDefault()
                    }}
                >
                    {getIconByName(item.icon)}
                </a>
            ))
            }
        </div>
    );
};

const Timer = ({data}) => {
    return (
        <div className="pfm-page__component timer-wrapper">
            <TimerSubcomponent data={data}/>
        </div>
    );
};


const Image = ({data}) => {
    return (
        <div className="pfm-page__component image-wrapper">
            <ImageSubcomponent data={data}/>
        </div>
    );
};

SwiperCore.use([Pagination, Mousewheel]);
const GalleryContainer = ({type, imagesReduced, columns, pageStyle, clicked, currentTabIndex}) => {
    const {t} = useTranslation();

    const loading = useSelector(requestTrackerSelector).upload;


    if (type === 'grid') {
        return (
            imagesReduced.length ?
                imagesReduced.map((item, index) => (
                    <div className={`gallery__col gallery__col--${columns}`} key={index}>
                        <div className="gallery__image-wrapper">
                            <img className="gallery__image" src={item} alt={item}/>
                        </div>
                    </div>
                ))
                :
                <>
                    <div className={`gallery__col gallery__col--${columns}`}>
                        <span className="gallery__image-placeholder">{t('common.image')}</span>
                    </div>
                    <div className={`gallery__col gallery__col--${columns}`}>
                        <span className="gallery__image-placeholder">{t('common.image')}</span>
                    </div>
                    <div className={`gallery__col gallery__col--${columns}`}>
                        <span className="gallery__image-placeholder">{t('common.image')}</span>
                    </div>
                    <div className={`gallery__col gallery__col--${columns}`}>
                        <span className="gallery__image-placeholder">{t('common.image')}</span>
                    </div>
                </>
        );
    }
    return (
        <Swiper
            spaceBetween={setSliderSpaceBetween(pageStyle)}
            slidesPerView="auto"
            centeredSlides={true}
            pagination={{
                clickable: true,
                bulletClass: "gallery__pagination__bullet",
                bulletActiveClass: "gallery__pagination__bullet--active"
            }}
            observer={true}
            observeParents={true}
            loop={imagesReduced.length > 1}
            allowTouchMove={clicked || currentTabIndex}
            mousewheel={{
                forceToAxis: true
            }}
        >
            {imagesReduced.length ?
                imagesReduced.map((item, index) => (
                    <SwiperSlide className="gallery__slide" key={index}>
                        <div className="gallery__image-wrapper">
                            {loading ?
                                <Spinner animation={"border"}/>
                                :
                                <img className="gallery__image" src={item} alt={item}/>
                            }
                        </div>
                    </SwiperSlide>
                ))
                :
                <>
                    <SwiperSlide className="gallery__slide">
                        <span className="gallery__image-placeholder">{t('common.image')}</span>
                    </SwiperSlide>
                    <SwiperSlide className="gallery__slide">
                        <span className="gallery__image-placeholder">{t('common.image')}</span>
                    </SwiperSlide>
                    <SwiperSlide className="gallery__slide">
                        <span className="gallery__image-placeholder">{t('common.image')}</span>
                    </SwiperSlide>
                </>
            }
        </Swiper>
    );
};

const Gallery = (props) => {
    const {type, images, grid_columns} = props.data;

    const imagesReduced = useMemo(() => {
        if (images) {
            return images.reduce((a, i) => {
                if (i)
                    a.push(prepareImageURL(i));
                return a;
            }, []);
        } else
            return [];
    }, [images]);

    const loading = useSelector(requestTrackerSelector).upload;
    const block = useSelector(editorSelector).block;

    const [galleryBlock, setGalleryBlock] = useState();

    useEffect(()=>{
        if(block) {
            setGalleryBlock(block.type)
        }
    },[block,galleryBlock])

    return (
        <div className="pfm-page__component gallery-wrapper">
            <div className={`gallery${type ? ` gallery--${type}` : ''}`}>
                {loading && galleryBlock === 'gallery' ?
                     <div className={`d-flex justify-content-center align-items-center gallery-wrapper`}>
                         <Spinner animation={"border"}/>
                     </div>
                     :
                    <GalleryContainer
                        type={type}
                        columns={grid_columns}
                        imagesReduced={imagesReduced}
                        pageStyle={props.pageStyle}
                        clicked={props.clicked}
                        currentTabIndex={props.currentTabIndex}
                    />}
            </div>
        </div>
    );
};

const Spoiler = ({data, clicked}) => {
    const {title, hidden} = data;
    const [expanded, setExpanded] = useState(clicked || null);

    return (
        <div className="pfm-page__component spoiler-wrapper">
            <Accordion className="spoiler" defaultActiveKey={clicked ? '0' : expanded}>
                <Accordion.Toggle as="div" className="spoiler__title" eventKey="0" onClick={() => {
                    setExpanded(expanded ? null : '0');
                }}>
                    <span>{title}</span>
                    <svg
                        className="spoiler__title__arrow"
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="7"
                        viewBox="0 0 12 7"
                        fill="none"
                        style={expanded ? {transform: 'rotate(180deg)'} : null}
                    >
                        <path d="M6 5L1.5 0.5L0.5 1.5L6 7L11.5 1.5L10.5 0.5L6 5Z"/>
                    </svg>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <div className="spoiler__hidden">
                        {parser(hidden || '')}
                    </div>
                </Accordion.Collapse>
            </Accordion>
        </div>
    );
};

const CommercialPromocode = ({data, parentConfigs}) => {
    const {code, text} = data;
    const style = makeStyle(parentConfigs ? parentConfigs['commercial.promocode'] : null);
    const linkRef = useRef(null);
    const [copied, setCopied] = useState(false);

//тут копировать
    return (
        <div className="commercial__promocode">
            <div className="commercial__promocode__code" style={style}><span ref={linkRef}>{code}</span>
                    <button type="button" className={copied ? 'copied copy-btn copy-btn-absolute' : 'copy copy-btn copy-btn-absolute'} onClick={(event) => {
                        copyToClipboard(linkRef.current, true,event);
                        setCopied(true)
                    }}>
                        <span className="copy-link">
                            <img src={iconCopyNew} alt="copy"/>
                        </span>
                        <span className="copied-icon">
                             <img src={iconCopyDone} alt="copy"/>
                        </span>
                    </button>
            </div>
            {text &&
            <div className="commercial__promocode__text">{text}</div>
            }
        </div>
    );
};

const specificCommercialSubcomponent = {
    [blockTypes.text]: withCommercialSubcomponent('commercial__text-wrapper')(TextSubcomponent),
    [blockTypes.button]: withCommercialSubcomponent('commercial__button-wrapper')(ButtonSubcomponent),
    [blockTypes.timer]: withCommercialSubcomponent('commercial__timer-wrapper')(TimerSubcomponent),
    [blockTypes.image]: withCommercialSubcomponent('commercial__image-wrapper')(ImageSubcomponent)
};

const specificCommercialHeaderSubcomponent = {
    [blockTypes.image]: withCommercialSubcomponent('commercial__header-wrapper__component')(ImageSubcomponent),
    [blockTypes.text]: withCommercialSubcomponent('commercial__header-wrapper__component')(TextSubcomponent)
};

const specificCommercialContentSubcomponent = {
    [blockTypes['commercial.promocode']]: withCommercialSubcomponent('commercial__promocode-wrapper')(CommercialPromocode),
    [blockTypes.button]: withCommercialSubcomponent('commercial__button-wrapper')(ButtonSubcomponent),
    [blockTypes.text]: withCommercialSubcomponent('commercial__text-wrapper')(TextSubcomponent),
    [blockTypes.timer]: withCommercialSubcomponent('commercial__timer-wrapper')(TimerSubcomponent)
};

const withSpecific = (specific) => ({block, parentConfigs}) => {
    if (!specific[block.type])
        return null;

    const Specific = specific[block.type];

    return <Specific data={block.data} parentConfigs={parentConfigs}/>;
};

export const CommercialComponent = ({blocks}) => {
    const Component = withSpecific(specificCommercialSubcomponent);

    return (
        <div className="commercial">
            {blocks &&
            blocks.map((item, index) => (
                <Component block={item} key={index}/>
            ))
            }
        </div>
    );
};

const Commercial = ({data}) => {
    const {blocks} = data;

    return (
        <div className="pfm-page__component commercial-wrapper">
            <CommercialComponent blocks={blocks}/>
        </div>
    );
};

const CommercialHeader = ({data, parentConfigs}) => {
    const Component = withSpecific(specificCommercialHeaderSubcomponent);

    return data.map((block, index) => (
        <Component block={block} parentConfigs={parentConfigs} key={index}/>
    ));
};

const CommercialContent = ({data, parentConfigs}) => {
    const Component = withSpecific(specificCommercialContentSubcomponent);

    return data.map((block, index) => (
        <Component block={block} parentConfigs={parentConfigs} key={index}/>
    ));
};

const specificPerfluenceBrandSubcomponent = {
    [blockTypes["commercial.header"]]: withCommercialSubcomponent('commercial__header-wrapper')(CommercialHeader),
    [blockTypes["commercial.content"]]: withCommercialSubcomponent('commercial__content-wrapper')(CommercialContent),
    [blockTypes.text]: withCommercialSubcomponent('commercial__text-wrapper')(TextSubcomponent)
};

const PerfluenceSubcomponent = ({block}) => {
    const {layout, configs, blocks} = block.data;

    if (block.type !== blockTypes.commercial || layout !== 'perfluence')
        return null;

    const Component = withSpecific(specificPerfluenceBrandSubcomponent);

    return (
        <div className="commercial-wrapper">
            <div className="commercial" style={{
                ...createBlockBackground(configs.background),
                color: configs.color
            }}>
                {blocks.map((block, index) => (
                    <Component block={block} parentConfigs={configs} key={index}/>
                ))}
            </div>
        </div>
    );
};

const PerfluenceLayout = ({configs, blocks,pageStyle, type}) => {
    const {background} = configs;

    const updateSize = useCallback((swiper) => {
        setTimeout(() => {
            swiper.updateAutoHeight(300);
        }, 10);
    },[]);

    if(type === 'horizontal' || !type) {
        return (
            <div className={`perfluence-block perfluence-block--${type}`} style={{...createBlockBackground(background)}}>
                <Swiper
                    spaceBetween={pageStyle ? setSliderSpaceBetween(pageStyle): 10}
                    autoHeight={true}
                    slidesPerView="auto"
                    centeredSlides={true}
                    pagination={{
                        clickable: true,
                        bulletClass: "gallery__pagination__bullet",
                        bulletActiveClass: "gallery__pagination__bullet--active"
                    }}
                    observer={true}
                    observeParents={true}
                    loop={blocks.length > 1}
                    allowTouchMove={true}
                    mousewheel={{
                        forceToAxis: true
                    }}
                    onSwiper={updateSize}
                >
                    {blocks &&
                    blocks.map((block, index) => (
                        <SwiperSlide key={index}>
                            <PerfluenceSubcomponent  block={block}/>
                        </SwiperSlide>
                    ))
                    }
                </Swiper>
            </div>
            )
    } else if (type === 'vertical') {
        return (
            <div className="perfluence-block" style={{...createBlockBackground(background)}}>
                {blocks &&
                blocks.map((block, index) => (
                    <PerfluenceSubcomponent key={index} block={block}/>
                ))
                }
            </div>
        )
    }
    // return (
    //     // <div className="perfluence-block" style={{...createBlockBackground(background)}}>
    //     //     {blocks &&
    //     //     blocks.map((block, index) => (
    //     //         <PerfluenceSubcomponent key={index} block={block}/>
    //     //     ))
    //     //     }
    //     // </div>
    //     <div className="perfluence-block" style={{...createBlockBackground(background)}}>
    //         <Swiper
    //             spaceBetween={pageStyle ? setSliderSpaceBetween(pageStyle): 10}
    //             autoHeight={true}
    //             slidesPerView="auto"
    //             centeredSlides={true}
    //             pagination={{
    //                 clickable: true,
    //                 bulletClass: "gallery__pagination__bullet",
    //                 bulletActiveClass: "gallery__pagination__bullet--active"
    //             }}
    //             observer={true}
    //             observeParents={true}
    //             loop={blocks.length > 1}
    //             allowTouchMove={true}
    //             mousewheel={{
    //                 forceToAxis: true
    //             }}
    //         >
    //             {blocks &&
    //             blocks.map((block, index) => (
    //                 <SwiperSlide key={index}>
    //                     <PerfluenceSubcomponent  block={block}/>
    //                 </SwiperSlide>
    //             ))
    //             }
    //         </Swiper>
    //     </div>
    // );
};

const PerfluencePlaceholder = () => {
    return (
        <div className="perfluence-block-placeholder">
            <span className="perfluence-block-placeholder__text">Perfluence</span>
            <img src={iconPerfluence} alt="Perfluence" className="perfluence-block-placeholder__image"/>
        </div>
    );
};

const Perfluence = ({data, pageStyle}) => {
    // return (
    //     <div className="pfm-page__component perfluence-block-wrapper">
    //         {data && data.configs && data.blocks ?
    //             <PerfluenceLayout configs={data.configs} blocks={data.blocks} pageStyle={pageStyle} isSwiper={data.type}/>
    //             :
    //             <PerfluencePlaceholder/>
    //         }
    //     </div>
    // );

    return (
        <div className="pfm-page__component perfluence-block-wrapper">
            {data && data.configs && data.blocks ?
                <PerfluenceLayout configs={data.configs} blocks={data.blocks} pageStyle={pageStyle} type={data.type && data.type}/>
                :
                <PerfluencePlaceholder/>
            }
        </div>
    );
};

const HTMLBlock = () => {
    return (
        <div className="pfm-page__component html-block-wrapper">
            <div className="html-block-placeholder">
                {/* <span className="html-block-placeholder__text">HTML</span> */}
                <span className="html-block-placeholder__text">Perfluence</span>
                <img src={iconHTML} alt="HTML" className="html-block-placeholder__image"/>
            </div>
        </div>
    );
};

const specificPageComponent = {
    [blockTypes.header]: Header,
    [blockTypes.text]: Text,
    [blockTypes.button]: Button,
    [blockTypes.separator]: Divider,
    [blockTypes.video]: Video,
    [blockTypes.social]: Socials,
    [blockTypes.timer]: Timer,
    [blockTypes.image]: Image,
    [blockTypes.gallery]: Gallery,
    [blockTypes.commercial]: Commercial,
    [blockTypes.spoiler]: Spoiler,
    [blockTypes.perfluence]: Perfluence,
    [blockTypes.html]: HTMLBlock
};

export const PageComponent = (props) => {
    const user = useSelector(userSelector);
    const invalidBlockProps = useInvalidBlockProps(props.type, props.data);
    const blockConfigs = useMemo(() => getBlockConfigs(), []);

    if (
        !specificPageComponent[props.type] ||
        invalidBlockProps.unknownType ||
        (props.type === blockTypes.perfluence && user && !user.settings.perfluence)
    )
        return null;

    const SpecificPageComponent = specificPageComponent[props.type];

    return <SpecificPageComponent
        {...props}
        data={invalidBlockProps.unknownProps.length || invalidBlockProps.missedProps.length ?
            blockConfigs[props.type].data : props.data}
    />
};

export const EditorComponent = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const invalidBlockProps = useInvalidBlockProps(props.type, props.data);
    const blockConfigs = useMemo(() => getBlockConfigs(), []);
    const SpecificEditorComponent = useMemo(() => {
        if (
            !specificPageComponent[props.type] ||
            (props.type === blockTypes.perfluence && !props.hasIntegration)
        ) {
            return null;
        }

        // if (props.type === blockTypes.perfluence) {
        //     return null;
        // }

        return props.type === blockTypes.header ?
            withEC(specificPageComponent[props.type])
            :
            withDraggableEC(specificPageComponent[props.type])
    }, [props.type]);

    useEffect(() => {
        if (invalidBlockProps.unknownType) {
            dispatch(updateStoreBeforeDelete({
                pageId: props.pageId,
                index: props.index
            }));
        } else if (
            invalidBlockProps.unknownProps.length ||
            invalidBlockProps.missedProps.length
        ) {
            dispatch(updateStoreBeforeBlockSave({
                pageId: props.pageId,
                index: props.index,
                data: blockConfigs[props.type].data
            }));
        }
    }, [invalidBlockProps, props.pageId, props.index, props.type, dispatch]);

    if (!SpecificEditorComponent || invalidBlockProps.unknownType)
        return null;

    return <SpecificEditorComponent
        {...props}
        ref={ref}
        data={invalidBlockProps.unknownProps.length || invalidBlockProps.missedProps.length ?
            blockConfigs[props.type].data : props.data}
    />;
});
