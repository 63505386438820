import {useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Form, FormControl, FormGroup, FormLabel, Spinner} from "react-bootstrap";
import parser from "html-react-parser";
import {useTranslation} from "react-i18next";

import {APICheckPagePath} from "../../api";

import {requestTrackerSelector, userSelector} from "../../redux/selectors";
import {publishPage, savePageStyle, setPageProperties, undoPageChanges} from "../../redux/editor/actions";
import {onRequestAction} from "../../redux/requestTracker/actions";
import {alertSuccess} from "../../redux/alert/actions";
import {REQUESTED_PAGE_CHECK} from "../../redux/types";

import {useForm} from "../../hooks";

import {getPublicationStateIcon, publicationState as publicationDefaultState} from "../../helpers/pagePublicationStateUtils";
import {copyToClipboard} from "../../helpers/clipboardUtils";
import {regExp} from "../../helpers/defaults";
import {PAGES_URL} from "../../helpers/services";

import Tooltip from "../Tooltip";
import {FieldWrapper} from "../FieldWrappers";
import Validation from "../Validation";

import iconCopy from "./media/icon-copy.svg";
import iconDropdown from "../../assets/icon-dropdown.svg";
import {sendAnalytics} from "../../Analytics";

const EditorPublication = ({page, publicationState, setPromptPopupState}) => {
    const dispatch = useDispatch();
    const user = useSelector(userSelector);
    const requestTracker = useSelector(requestTrackerSelector);
    const linkRef = useRef(null);
    const [copied, setCopied] = useState(false);
    const [advancedVisible, setAdvancedVisible] = useState(false);
    const {t} = useTranslation();

    const form = useForm({
        values: useMemo(() => ({
            title: page ? page.title : '',
            path: page ? page.path : '',
            google: page && page.metrics && page.metrics.google ? page.metrics.google : '',
            yandex: page && page.metrics && page.metrics.yandex ? page.metrics.yandex : ''
        }), [page]),
        validation: {
            title: {
                required: true,
                error: t('validation.default')
            },
            path: {
                regExp: regExp.pageUrl,
                error: t('validation.pageURL'),
                async: {
                    callback: async () => {
                        dispatch(onRequestAction(REQUESTED_PAGE_CHECK, true));
                        const response = await APICheckPagePath(page.id, form.state.path)
                            .then(() => true)
                            .catch(() => false);
                        dispatch(onRequestAction(REQUESTED_PAGE_CHECK, false));
                        return response;
                    },
                    error: t('validation.URLTaken')
                }
            },
            google: {
                regExp: regExp.googleAnalytics,
                error: t('validation.ga')
            },
            yandex: {
                regExp: regExp.yandexMetrika,
                error: t('validation.ym')
            }
        },
        onSubmit: async () => {
            if (page) {
                await dispatch(setPageProperties({
                    id: page.id,
                    settings: {
                        title: form.state.title,
                        path: form.state.path,
                        metrics: {
                            google: form.state.google || null,
                            yandex: form.state.yandex || null
                        }
                    }
                }));
                dispatch(alertSuccess(t('alert.saved')));
            }
        }
    });

    return (
        <div className="editor__publication">
            <div className="editor__publication__publish">
                <div className="editor__publication__publish--wrapper">
                    <div className="editor__publication__text">
                        {parser(t(`editor.publication.text.${publicationState}`))}
                    </div>
                    <div className="editor__publication__buttons-wrapper">
                        <Button
                            size="sm"
                            type="button"
                            className="secondary-btn blue-btn--published editor__publication__button"
                            disabled={
                                publicationState === publicationDefaultState.published.name ||
                                (page && !page.editable_blocks.length) ||
                                requestTracker.editor.savePage ||
                                requestTracker.editor.publishPage
                            }
                            onClick={async () => {
                                if (page && page.editable_style && !page.unpublished_changes.style)
                                    await dispatch(savePageStyle({
                                        id: page.id,
                                        style: page.editable_style
                                    }));
                                dispatch(publishPage(page.id));
                                sendAnalytics('published');
                            }}
                        >
                            {requestTracker.editor.publishPage || requestTracker.editor.savePage ?
                                <Spinner animation="border" size="sm"/>
                                :
                                t('button.publish')
                            }
                        </Button>
                        <Button
                            size="sm"
                            type="button"
                            variant="light"
                            className="secondary-btn light-btn--published editor__publication__button"
                            disabled={
                                publicationState === publicationDefaultState.unpublished.name ||
                                publicationState === publicationDefaultState.published.name ||
                                requestTracker.editor.savePage ||
                                requestTracker.editor.publishPage
                            }
                            onClick={ () => {
                                setPromptPopupState({
                                    opened: true,
                                    message: t('popup.prompt.discardChanges'),
                                    onConfirm: () => {
                                        dispatch(undoPageChanges(page.id));
                                    }
                                });
                            }}
                        >
                            {t('button.discardChanges')}
                        </Button>
                    </div>
                </div>
                <div className="editor__publication__url">
                    <div className="editor__publication__url__text">
                        {t('editor.publication.labelPageURL')}
                    </div>
                    <div className="editor__publication__url__link">
                        <div className="editor__publication__url__link__status">
                            <img src={getPublicationStateIcon(publicationState)} alt={t('common.status')}/>
                        </div>
                        <div className="editor__publication__url__link__text">
                            <span ref={linkRef}>
                                <a
                                    href={page ? `${PAGES_URL.split('pfm')[0]}${page.path}.${PAGES_URL.split('//')[1]}` : 'https://pfmlink.com'}
                                    target="_blank"
                                    rel="noreferrer nofollow"
                                >
                                    {page ? `${PAGES_URL.split('pfm')[0]}${page.path}.${PAGES_URL.split('//')[1]}` : 'https://pfmlink.com'}
                                </a>
                            </span>
                            <Tooltip
                                id="copy-tooltip"
                                title={copied ? t('tooltip.copied') : t('tooltip.copy')}
                                placement="bottom"
                            >
                                <button type="button" className="copy-btn" onClick={() => {
                                    copyToClipboard(linkRef.current);
                                    setCopied(true)
                                }}>
                                    <img src={iconCopy} alt={t('tooltip.copy')}/>
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <div className="editor__publication__divider"/>
            <div className="editor__publication__settings">
                <div className="editor__publication__title">
                    {t('title.pageSettings')}
                </div>
                <Form
                    noValidate
                    className="editor__publication__form"
                    onSubmit={form.handleSubmit}
                    onReset={form.handleReset}
                >
                    <FieldWrapper
                        type="text"
                        name="title"
                        label={t('label.pageTitle')}
                        value={form.state.title}
                        placeholder={`${t('placeholder.pageTitle')}${user ? user.name : 'username'}`}
                        desc={t('desc.pageTitle')}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        validation={form.errors.title}
                    />
                    <FormGroup controlId="path">
                        <FormLabel className="label">{t('label.pageURL')}</FormLabel>
                        <div className="editor__publication__form__field-with-domain">
                            <span>
                                {PAGES_URL.split('pfm')[0]}
                            </span>
                            <FormControl
                                className="field"
                                type="text"
                                name="path"
                                value={form.state.path}
                                isInvalid={form.errors.path}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                            />
                            <span>
                                .{PAGES_URL.split('//')[1]}
                            </span>
                        </div>
                        {form.errors.path &&
                        <Validation>
                            {form.errors.path}
                        </Validation>
                        }
                    </FormGroup>
                    <div className="editor__publication__divider"/>
                    <div className="editor__publication__title editor__publication__title--with-pointer" onClick={() => {setAdvancedVisible(!advancedVisible)}}>
                        <span>{t('title.advancedSettings')}</span>
                        <img
                            src={iconDropdown}
                            className={advancedVisible ?
                                "editor__publication__title__image" :
                                "editor__publication__title__image editor__publication__title__image--closed"}
                            alt={t('common.show')}
                        />
                    </div>
                    {advancedVisible &&
                        <>
                            <FieldWrapper
                                type="text"
                                name="yandex"
                                label={t('label.ym')}
                                value={form.state.yandex}
                                placeholder={t('placeholder.ym')}
                                desc={t('desc.ym')}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                validation={form.errors.yandex}
                            />
                            <FieldWrapper
                                type="text"
                                name="google"
                                label={t('label.ga')}
                                value={form.state.google}
                                placeholder={t('placeholder.ga')}
                                desc={t('desc.ga')}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                validation={form.errors.google}
                            />
                        </>
                    }
                    <div className="editor__publication__form__buttons-wrapper">
                        <Button
                            type="submit"
                            size="sm"
                            className="secondary-btn blue-btn--published editor__publication__form__button"
                            disabled={!form.modified || requestTracker.editor.checkPagePath}
                        >
                            {t('button.save')}
                        </Button>
                        <Button
                            type="reset"
                            variant="outline-light"
                            size="sm"
                            className="secondary-btn outline-light-btn editor__publication__form__button"
                            disabled={!form.modified || requestTracker.editor.checkPagePath}
                        >
                            {t('button.cancel')}
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default EditorPublication;