import {useDispatch, useSelector} from "react-redux";
import {Button} from "react-bootstrap";
import {Trans, useTranslation} from "react-i18next";

import {clearEditMode, deleteBlock} from "../../redux/editor/actions";

import EditorForm from "../EditorForm";
import CloseButton from "../CloseButton";

import arrow from "./media/arrow.svg";
import cursor from "./media/cursor.svg";
import iconHeader from "../../assets/blockTypeIcons/header.svg";
import iconText from "../../assets/blockTypeIcons/text.svg";
import iconButton from "../../assets/blockTypeIcons/button.svg";
import iconSeparator from "../../assets/blockTypeIcons/separator.svg";
import EditorDesignForm from "../EditorDesignForm";

const EditorSidebar = ({tab,page, block, blockFields, onClose, setBlockModified, submitTrigger, setSubmitTrigger, cropperState, setCropperState, setPromptPopupState}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    return (
        <div
            className="editor__sidebar">
            <div className="editor__sidebar__title">
                <span>{block ? t(`title.blocks.${block.type}`) : page && tab.name === 'design' ? `Схема "${page.editable_style}" ` : t('editor.sidebar.title')}</span>
                {block &&
                <div className="editor__sidebar__title__controls">
                    <Button
                        size="sm"
                        variant="light"
                        className="secondary-btn light-btn editor__sidebar__title__controls__btn"
                        onClick={() => {
                            setPromptPopupState({
                                opened: true,
                                message: t('popup.prompt.deleteBlock'),
                                onConfirm: () => {
                                    dispatch(deleteBlock({
                                        pageId: page.id,
                                        index: block.index
                                    }));
                                    dispatch(clearEditMode());
                                }
                            });
                        }}
                    >
                        {t('button.delete')}
                    </Button>
                    <CloseButton onClick={onClose}/>
                </div>
                }
            </div>
            <div className="editor__sidebar__content">
                {block && tab.index === 0?
                    <EditorForm
                        page={page}
                        block={block}
                        blockFields={blockFields}
                        setBlockModified={setBlockModified}
                        submitTrigger={submitTrigger}
                        setSubmitTrigger={setSubmitTrigger}
                        cropperState={cropperState}
                        setCropperState={setCropperState}
                    />
                    :
                    tab.index === 1 ?
                      <EditorDesignForm
                          page={page}
                          block={block}
                          blockFields={blockFields}
                          submitTrigger={submitTrigger}
                          setSubmitTrigger={setSubmitTrigger}
                          setBlockModified={setBlockModified}
                      />
                        :
                        <>
                            <div className="editor__sidebar__content-section">
                                <div className="img-helper img-helper--tabs">
                                    <div className="img-helper__bar">
                                        <span>{t('editor.tabs.content')}</span>
                                    </div>
                                    <div className="img-helper__item">
                                        <img src={arrow} alt={t('common.icon')} className="img-helper__arrow"/>
                                    </div>
                                    <div className="img-helper__bar">
                                        <span>{t('editor.tabs.design')}</span>
                                    </div>
                                    <div className="img-helper__item">
                                        <img src={arrow} alt={t('common.icon')} className="img-helper__arrow"/>
                                    </div>
                                    <div className="img-helper__bar">
                                        <span>{t('editor.tabs.publish')}</span>
                                    </div>
                                </div>
                                <Trans i18nKey="editor.sidebar.help.p1">
                                    <p className="mb-0">Создание странички проходит в три этапа.</p>
                                    <ol>
                                        <li>На вкладке “Контент” добавьте и настройте блоки из которых будет состоять ваша страница.</li>
                                        <li>На вкладке “Дизайн” можно применить понравившуюся схему оформления.</li>
                                        <li>Опубликуйте сайт в сети в один клик на вкладке “Публикация”.</li>
                                    </ol>
                                </Trans>
                            </div>
                            <div className="editor__sidebar__content-section">
                                <div className="img-helper img-helper--tools">
                                    <div className="img-helper__bar">
                                        <img src={iconHeader} alt={t('button.blocks.header')} className="img-helper__icon"/>
                                        <span>{t('button.blocks.header')}</span>
                                    </div>
                                    <div className="img-helper__bar">
                                        <img src={iconText} alt={t('button.blocks.text')} className="img-helper__icon"/>
                                        <span>{t('button.blocks.text')}</span>
                                    </div>
                                    <div className="img-helper__bar">
                                        <img src={iconButton} alt={t('button.blocks.button')} className="img-helper__icon"/>
                                        <span>{t('button.blocks.button')}</span>
                                    </div>
                                    <div className="img-helper__bar">
                                        <img src={iconSeparator} alt={t('button.blocks.separator')} className="img-helper__icon"/>
                                        <span>{t('button.blocks.separator')}</span>
                                    </div>
                                </div>
                                <p>{t('editor.sidebar.help.p2')}</p>
                            </div>
                            <div className="editor__sidebar__content-section">
                                <div className="img-helper img-helper--button">
                                    <div className="img-helper__item">
                                        <img src={cursor} alt={t('common.icon')} className="img-helper__icon"/>
                                        <div className="img-helper__button">
                                            {t('common.button')}
                                        </div>
                                    </div>
                                </div>
                                <p>{t('editor.sidebar.help.p3')}</p>
                            </div>
                        </>
                }
            </div>
        </div>
    );
};

export default EditorSidebar;