import {RECEIVED_CHECK_EMAIL, RECEIVED_PAGE_CHECK, RECEIVED_SEND_RESET} from "../types";

export const onRequestAction = (type, payload) => ({
    type,
    payload
});

export const clearCheckFieldsState = () => {
    return dispatch => {
        dispatch(onRequestAction(RECEIVED_CHECK_EMAIL, false));
    };
};

export const clearSendReset = () => ({
    type: RECEIVED_SEND_RESET,
    payload: false
});

export const clearCheckPagePath = () => ({
    type: RECEIVED_PAGE_CHECK,
    payload: false
});