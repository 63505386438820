import {LOGOUT, STATS_LOAD, STATS_SET_PAGE} from "../types";

const initialState = {
    data: {
        total_view: 0,
        total_visitors: 0,
        device_types: {},
        country: {},
        date: {},
        city: {},
        pages: [],
        pagesDetailed: {}
    },
    loaded: false
};

const stats = (state = initialState, action) => {
    switch (action.type) {
        case STATS_LOAD:
            return {
                data: {
                    ...state.data,
                    ...action.payload
                },
                loaded: true
            };
        case STATS_SET_PAGE:
            return {
                ...state,
                data: {
                    ...state.data,
                    pagesDetailed: {
                        ...state.data.pagesDetailed,
                        [action.payload.id]: {
                            ...state.data.pagesDetailed[action.payload.id],
                            [action.payload.period]: action.payload.data
                        }
                    }
                }
            };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default stats;